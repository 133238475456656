<template>
    <div class="container">
        <div class="info-content">
            <h2>TODOS PODEMOS SER VIGILANTES DE LA administración PÚBLICA</h2>
            <p>Una aplicación para saber de manera sencilla en que el estado gasta su presupuesto.</p>
            <div class="descargar">
                <button @click.prevent="gotonav('https://play.google.com/store/apps/details?id=com.gestion_publica.app')" class="btn-descargar">Descargar App</button>
            <div class="btn-contents">
                <button @click.prevent="gotonav('https://apps.apple.com/ve/app/reacciona/id6517347675')">
                    <div class="ico">
                        <img src="@/assets/img/apple.svg" alt="apple-ico">
                    </div>
                    <div class="info">
                        <span>Download on the</span>
                        <strong>App Store</strong>
                    </div>
                </button>
                <button @click.prevent="gotonav('https://play.google.com/store/apps/details?id=com.gestion_publica.app')">
                    <div class="ico">
                        <img src="@/assets/img/play.svg" alt="play-store-ico">
                    </div>
                    <div class="info">
                        <span>GET IT ON</span>
                        <strong>Google Play</strong>
                    </div>
                </button>
            </div>
            </div>
          
        </div>
        <div class="img-content">
            <img class="escritorio" src="@/assets/img/vigilante.png" alt="vigilante-section">
            <img class="app" src="@/assets/img/vigilante-app.png" alt="vigilante-section">
        </div>
    </div>
</template>

<script>
import store from "@/store/index"
    export default {
        name:"vigilante-section",
        methods: {
            gotonav(value) {
                window.location=value;
            },
            muypronto(){
                store.dispatch("showToast", {
                message: "Muy Pronto",
                type: "warning",
            });
            }
        },
    }
</script>

<style lang="scss" scoped>
@media only screen and (min-width: 601px) {
    .container{
          display: flex;
          align-items: center;
          justify-content: space-between;
          width: 100%;
          max-width: 1150px;
          margin: 0px auto 100px auto;
            .info-content{
                flex-basis: 45%;
                h2{
                font-size: 45px;
                font-weight: 700;
                font-family: "Bebas Neue";
                line-height: 45px;
                text-transform: uppercase;
                text-align: left;
                color: #000000;
                width: 446px;
                }
                p{
                    font-family: "Roboto";
                    font-size: 14px;
                    font-weight: 400;
                    font-family: "Bebas Neue";
                    line-height: 16.41px;
                    text-align: left;
                    color: #000000;
                    width: 291px;
                }
                .descargar{
                    display: flex;
                    justify-content: flex-start;
                    flex-direction: column;
                    align-items: flex-start;
                    .btn-descargar{
                    width: 215px;
                    background-color: #1C18F2;
                    border-radius: 16px;
                    padding: 16px, 44px, 16px, 44px;
                    color: #ffffff;
                    font-size: 16px;
                    font-weight: 500;
                    border: none;
                    height: 51px;
                    margin-top: 20px;
                    cursor: pointer;
                    }
                    .btn-contents{
                        display: flex;
                        justify-content: space-between;
                        margin-top: 45px;
                        button{
                            display: flex;
                            width: 127.1px;
                            background-color: #2C3444;
                            border-radius: 5px;
                            height: 46px;
                            padding: 11px, 16px, 11px, 16px;
                            border: none;
                            margin-right: 20px;
                            .ico{
                                flex-basis: 20%;
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                height: 100%;
                            }
                            .info{
                                flex-basis: 80%;
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                flex-direction: column;
                                height: 100%;
                                span{
                                    text-align: left;
                                    font-size: 12px;
                                    width: 100%;
                                    color: #F9F9F9;
                                }
                                strong{
                                    text-align: left;
                                    font-size: 14px;
                                    width: 100%;
                                    color: #F9F9F9;
                                }
                            }

                        }
                    }
                }
               
          }
          .img-content{
            flex-basis: 55%;
            .escritorio{
                padding-top: 150px;
                margin-left: -120px;
            }
            .app{
                display: none;
            }
          }
          
          
    }
}
@media only screen and (max-width: 600px) {
    .container{
          display: flex;
          align-items: center;
          justify-content: space-between;
          width: 100%;
          max-width: 600px;
          flex-direction: column;
          margin: 0px auto 100px auto;
            .info-content{
                flex-basis: 100%;
                z-index: 1;
                h2{
                font-size: 45px;
                font-weight: 700;
                font-family: "Bebas Neue";
                line-height: 45px;
                text-transform: uppercase;
                text-align: center;
                color: #000000;
             
                }
                p{
                    font-family: "Roboto";
                    font-size: 14px;
                    font-weight: 400;
                    font-family: "Bebas Neue";
                    line-height: 16.41px;
                    text-align: center;
                    color: #000000;
                }
                .descargar{
                    z-index: 1;
                    display: flex;
                    justify-content: flex-start;
                    flex-direction: column;
                    align-items: center;
                    .btn-descargar{
                    width: 215px;
                    background-color: #1C18F2;
                    border-radius: 16px;
                    padding: 16px, 44px, 16px, 44px;
                    color: #ffffff;
                    font-size: 16px;
                    font-weight: 500;
                    border: none;
                    height: 51px;
                    margin-top: 20px;
                    cursor: pointer;
                    }
                    .btn-contents{
                        display: flex;
                        justify-content: center;
                        width: 100%;
                        margin-top: 45px;
                      
                        button{
                            display: flex;
                            width: 127.1px;
                            background-color: #2C3444;
                            border-radius: 5px;
                            height: 46px;
                            padding: 11px, 16px, 11px, 16px;
                            border: none;
                            margin-right: 20px;
                            .ico{
                                flex-basis: 20%;
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                height: 100%;
                            }
                            .info{
                                flex-basis: 80%;
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                flex-direction: column;
                                height: 100%;
                                span{
                                    text-align: left;
                                    font-size: 12px;
                                    width: 100%;
                                    color: #F9F9F9;
                                }
                                strong{
                                    text-align: left;
                                    font-size: 14px;
                                    width: 100%;
                                    color: #F9F9F9;
                                }
                            }

                        }
                    }
                }
               
          }
          .img-content{
            
           .escritorio{
            display: none;
           }
           .app{
            margin-top: -160px;
            margin-left: -140px;
           }
        
          }
          
          
    }
}
</style>