<template>
    <div class="carusel">
        <Carousel>
                <Slide :autoplay="2000" v-for="(slidem, index) in caruselElements" :key="slidem">
              
                        <carreteraCard v-if="index===0" :slidem="slidem"></carreteraCard>
                        <dineroCard  v-if="index===1" :slidem="slidem"></dineroCard>
                        <lupaCard  v-if="index===2" :slidem="slidem"></lupaCard>
                        <chicaCard  v-if="index===3" :slidem="slidem"></chicaCard>
                  
                </Slide>
                <template #addons>
                        <Pagination />
                </template>
            </Carousel>
    </div>
</template>

<script>
import { Carousel, Slide, Pagination, Navigation } from 'vue3-carousel'
import carreteraCard from "@/components/cards/carreteraCard.vue"
import dineroCard from "@/components/cards/dineroCard.vue"
import lupaCard from "@/components/cards/lupaCard.vue"
import chicaCard from "@/components/cards/chicaCard.vue"

    export default {
        name:"carusel-section",
        data() {
            return {
                caruselElements: [
                {
                    index:0,
                    title:"EN PROMEDIO LOS ÚLTIMOS 5 AÑOS.",
                    title_2:"ESTO EQUIVALE A:",
                    title_3:null,
                    title_4:null,
                    strong_1:"17 MIL MILLONES DE SOLES",
                    strong_2:" DEJARON DE EJECUTARSE",
                    description:null,
                    strong_3:"25 HOSPITALES ESPECIALIZADOS EN PIURA",
                    strong_4:"173 NUEVOS CENTROS EDUCATIVOS EN LAMBAYEQUE",
                    strong_5:"6 AEROPUERTOS TIPO CHINCHEROS EN CUSCO",
                    footer:null
                },
                {
                    index:1,
                    title:"LOS GOBIERNOS LOCALES SON LOS QUE RECIBEN MÁS DINERO ",
                    title_2:"POR EJEMPLO,",
                    title_3:null,
                    title_4:null,
                    strong_1:" PERO SON LOS QUE MENOS INVERTEN.",
                    strong_2:"DE CADA 100 SOLES DE PRESUPUESTO SÓLO INVIERTEN 60",
                    description:null,
                    footer:null
                },
                {
                    index:2,
                    title:"SI NO CONSEGUIMOS QUE LOS GOBIERNOS LOCALES EJECUTEN EL 100% DEL PRESUPUESTO",
                    title_2:"ESTO SE TRADUCE A MENOR CRECIMIENTO Y DESARROLLO LOCAL QUE TIENE UN",
                    title_3:null,
                    title_4:null,
                    strong_1:"SEGUIRÁN EXISTIENDO BRECHAS.",
                    strong_2:" IMPACTO A NIVEL NACIONAL.",
                    description:null,
                    footer:null
                },
                {
                    index:3,
                    title:"¿Esto se considera una gestión eficiente?",
                    title_2:"¿Todo es culpa de las autoridades?",
                    title_3:"¿Se puede mejorar?",
                    title_4:"¿Yo como persona de a pie puedo hacer algo?",
                    title_5:'¿"F" cómo dirían los jóvenes?',
                    strong_1:null,
                    strong_2:null,
                    strong_3:null,
                    strong_4:null,
                    description:null,
                    footer:null
                }
            ]
            }
        },
        components: {
            Carousel,
            Slide,
            Pagination,
            Navigation,
            carreteraCard,
            dineroCard,
            lupaCard,
            chicaCard
        },
    }
</script>

<style lang="scss" scoped>
  @import '../../../../../../assets/css/carusel.css';

</style>