<template>
      <div class="carusel__lupa">
                            <div class="info">
                                <h2>
                                    {{ slidem.title }}

                                    <strong>{{ slidem.strong_1 }}</strong>
                                </h2>
                                <h2 class="title_2">
                                    {{ slidem.title_2 }}
                                    <strong>{{ slidem.strong_2 }}</strong>
                                </h2>
                            
                                <div class="img-movil">
                                    <img src="@/assets/img/lupa.png" alt="slider-reacciona-img">
                                </div>
                            </div>
                            <div class="img">
                                <img src="@/assets/img/lupa.png" alt="slider-reacciona-img">
                            </div>
                        </div>
</template>

<script>
    export default {
        name:"lupa-card",
        props:['slidem']
    }
</script>

<style lang="scss" scoped>
@media only screen and (min-width: 601px) {
    .carusel__lupa{
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: 100%;
    .info{
        flex-basis: 50%;
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        flex-direction: column;
        h2{
            font-size: 45px;
            line-height: 45px;
            color: #313131;
            text-transform: uppercase;
            text-align: left;
            font-weight: 700;
            width: 664px;
            font-family: "Bebas Neue";
            margin:150px 0px 0px 0px;
            
            strong{
                font-size: 45px;
                line-height: 45px;
                color: #FF0069;
            }
        }
        .img-movil{
            display: none;
        }
        .title_2{
            margin: 0px;
            font-size: 45px;
            line-height: 45px;
            color: #313131;
            text-transform: uppercase;
            text-align: left;
            margin-top: 40px;
            font-weight: 700;
            width: 664px;
            font-family: "Bebas Neue";
            strong{
                font-size: 45px;
                line-height: 45px;
                color: #481EFD;
            }
        }
    
    }
    .img{
        flex-basis: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
        margin-top: 20px;
        margin-left: 30px;
        img{
            width: 275.99px;
            height: 288.01px;
        }
        
    }
} 

}
@media only screen and (max-width: 600px) {
    .carusel__lupa{
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    width: 100%;
    height: 100%;
    .info{
        flex-basis: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        h2{
            font-size: 24px;
            line-height: 28.13px;
            color: #313131;
            text-transform: uppercase;
            text-align: center;
            font-weight: 700;
            width: 323px;
            font-family: "Roboto";
            margin:70px 0px 0px 0px;
            
            strong{
                font-size: 24px;
                line-height: 28.13px;
                color: #481EFD;
            }
        }
        .title_2{
            margin: 0px;
            font-size: 24px;
            line-height: 28.13px;
            color: #313131;
            text-transform: uppercase;
            text-align: center;
            font-weight: 700;
            width: 323px;
            font-family: "Roboto";
            strong{
                font-size: 24px;
                line-height: 28.13px;
                color: #481EFD;
            }
        }
  
        .img-movil{
            margin-top: 40px;
            img{
            width: 219px;
            height: 193px;
             }   
        }
    }
    .img{
        display: none;
        
    }
} 
}

</style>