<template>
    <div class="container">
        <div class="aporte-img-sec">
            <div class="card-i-1">
                <h2>TE LA HACEMOS FÁCIL</h2>
                <p>En nuestra <strong>#ReAccionaApp podrás:</strong></p>
                <ul>
                    <li>Encontrar proyectos cerca a tu ubicación y en todo el país.</li>
                    <li>Hacer seguimiento a los proyectos que elijas.</li>
                    <li>Ver el estado de las obras y porcentaje de avance.</li>
                    <li>Y mucho mas...</li>
                </ul>
            </div>
            <div class="card-i-2">
                <img src="@/assets/img/app.png" alt="reacciona-app-imagen-proyectos">
            </div>
            <div class="card-i-3">
                <div class="img-group">
                    <img src="@/assets/img/chico2.png" alt="chico2-app-imagen-proyectos">
                    <div class="text-content-2">
                        <p>Si sabemos que hay presupuesto </p>
                        <h6>¿Cómo no querer saber en qué se está invirtiendo y por qué?</h6>
                        <p>¡Hagamos que cada sol valga!</p>
                        <p class="movile">En ReAcciona creemos en una ciudadanía
activa, vigilante y comprometida, capaz de
influir positivamente en la gestión de nuestras autoridades locales y regionales.</p>
                    </div>
                </div>
                <p class="escritorio">En ReAcciona creemos en una ciudadanía
activa, vigilante y comprometida, capaz de
influir positivamente en la gestión de nuestras autoridades locales y regionales.</p>
            </div>
        </div>
        <div class="btn-content">
            <button @click.prevent="gotonav('https://play.google.com/store/apps/details?id=com.gestion_publica.app')">#ReAcciona App</button>
            <!-- <button>Red Vigilante</button> -->
        </div>
    </div>
</template>

<script>
    export default {
        name:"aporte-section",
        methods: {
            gotonav(value) {
                window.location=value;
            },
        },
    }
</script>

<style lang="scss" scoped>
@media only screen and (min-width: 601px) {
  
  .container{
          display: flex;
          align-items: center;
          justify-content: center;
          width: 100%;
          max-width: 1150px;
          margin: 0px auto 0px auto;
          flex-direction: column;
          .aporte-img-sec{
            display: flex;
            justify-content: center;
            margin-top: 90px;
            .card-i-1{
                flex-basis: 40%;
                background-color: #481EFD;
                border-top-left-radius: 30px;
                border-bottom-left-radius: 30px;
                width: 581px;
                h2{
                    font-size: 45px;
                    font-weight: 700;
                    font-family: "Bebas Neue";
                    line-break: 45px;
                    text-transform: uppercase;
                    text-align: left;
                    width: 300px;
                    margin-left: 35px;
                    color: #ffffff;
                  
                }
                p{
                    text-align: left;
                    font-size: 22px;
                    font-weight: 700;
                    color: #ffffff;
                    font-family: "Roboto";
                    width: 323px;
                    margin-left: 35px;
                    line-height: 23.44px;
                    margin-bottom: 0px;
                    strong{
                        color: #00FF98;
                    }
                }
                ul{
                    color: #ffffff;
                    margin-left: 0px;
                    li{
                        font-family: "Roboto";
                        font-size: 22px;
                        font-weight: 700;
                        line-height: 25.78px;
                        width: 327px;
                        text-align: left;
                        margin-bottom: 10px;
                        margin-left: 15px;
                    }
                }
              
            }
            .card-i-2{
                flex-basis: 30;
                background-color: #FF0069;
                border-top-left-radius: 30px;
                border-bottom-left-radius: 30px;
                width: 359px;
                display: flex;
                justify-content: center;
                align-items: center;
                margin-left: -85px;
                img{
                    margin-left: -30px;
                }
            }
            .card-i-3{
                flex-basis: 30%;
                background-color: #FFD700;
                border-top-left-radius: 30px;
                border-bottom-left-radius: 30px;
                padding-right: 60px;
                padding-bottom: 40px;
                margin-left: -39px;
                width: 100%;
                .img-group{
                    display: flex;
                    justify-content: space-between;
                    margin-top: 20px;
                    
                    img{
                        margin-left: -39px;
                    }
                    .text-content-2{
                        width: 332px;
                        margin-top: 10px;
                        margin-left: 20px;
                        p{
                            font-family: "Roboto";
                            font-size: 20px;
                            font-weight: bold;
                            line-height: 23.44px;
                            width: 301px;
                            margin: 0px;
                        }
                        h6{
                            text-transform: uppercase;
                            font-size: 45px;
                            font-family: "Bebas Neue";
                            line-height: 45px;
                            color: #FF0069;
                            font-weight: 700;
                            margin: 0px;
                            text-align: left;
                            margin-top: 10px;
                            margin-bottom: 10px;
                        }
                        .movile{
                            display: none;
                        }
                    }
                }
                p{
                    text-align: center;
                    font-size: 20px;
                    font-weight: bold;
                    color: #313131;
                    font-family: "Roboto";
                    width: 389px;
                    margin-left: 45px;
                    margin-top: 40px;
                    line-height: 23.44px;
                   
                }
               
            }
          }
          .btn-content{
            margin-top: 60px;
            display: flex;
            justify-content: space-evenly;
            width: 100%;

            button{
                width: 271px;
                height: 51px;
                border-radius: 16px;
                color: #481EFD;
                font-family: "Roboto";
                font-size: 16px;
                font-weight: 500;
                background-color: #F1F1F1;
                border: 1px solid #1C18F2;
            }
            button:hover{
                color: #F1F1F1;
                background-color: #481EFD;
                border: 1px solid #1C18F2;
            }
          }
    }
}
@media only screen and (max-width: 600px) {
    .container{
          display: flex;
          align-items: center;
          justify-content: center;
          width: 100%;
          max-width: 600px;
          margin: 0px auto 0px auto;
          flex-direction: column;
          .aporte-img-sec{
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            margin-top: 90px;
            .card-i-1{
                flex-basis: 100%;
                background-color: #481EFD;
                border-top-left-radius: 30px;
                border-top-right-radius: 30px;
                padding-bottom: 40px;
                width:95%;
                height: 280.53px;
                h2{
                    font-size: 45px;
                    font-weight: 700;
                    font-family: "Bebas Neue";
                    line-break: 45px;
                    text-transform: uppercase;
                    text-align: left;
                 
                    margin-left: 15px;
                    color: #ffffff;
                  
                }
                p:first-of-type{
                    text-align: left;
                    font-size: 20px;
                    font-weight: 700;
                    color: #00FF98;
                    font-family: "Roboto";
                    width: 100%;
                    max-width: 265px;
                    margin-left: 15px;
                    line-height: 23.44px;
                    margin-bottom: 0px;
                }
                p{
                    text-align: left;
                    font-size: 20px;
                    font-weight: 700;
                    color: #ffffff;
                    font-family: "Roboto";
                    margin-top: 0px;
                    width: 100%;
                    max-width: 265px;
                    margin-left: 15px;
                    line-height: 23.44px;
                }
                ul{
                    color: #ffffff;
                    margin-left: 0px;
                    li{
                        font-family: "Roboto";
                        font-size: 18px;
                        font-weight: 700;
                        line-height: 25.78px;
                        width: 90%;
                        text-align: left;
                        margin-bottom: 10px;
                        margin-left: 0px;
                    }
                }
            }
            .card-i-2{
                flex-basis: 100%;
                background-color: #FF0069;
                border-top-left-radius: 30px;
                border-top-right-radius: 30px;
                width:95%;
                padding: 40px 0px 60px 0px;
                display: flex;
                justify-content: center;
                align-items: center;
                margin-top: -30px;
                img{
                    
                }
            }
            .card-i-3{
                flex-basis: 100%;
                background-color: #FFD700;
                border-radius: 30px;
                width:95%;
                padding: 60px 0px;
                margin-top: -25px;
                .img-group{
                    display: flex;
                    justify-content: space-between;
                    img{
                        margin-left: -25px;
                        height: 154px;
                        width: 89px;
                    }
                    .text-content-2{
                        width: 100%;
                        margin-top: 10px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        flex-direction: column;
                   
                        p{
                            font-family: "Roboto";
                            font-size: 14px;
                            font-weight: bold;
                            line-height: 23.44px;
                            
                            margin: 0px;
                        }
                        h6{
                            text-transform: uppercase;
                            font-size: 25px;
                            font-family: "Bebas Neue";
                            line-height: 23px;
                            color: #FF0069;
                            font-weight: 700;
                            margin: 0px;
                            text-align: center;
                            margin-top: 10px;
                            margin-bottom: 10px;
                            width: 80%;
                        }
                    }
                }
                .escritorio{
                    display: none;
                }
                p:first-of-type{
                    text-align: center;
                    font-size: 14px;
                    font-weight: 700;
                    color: #313131;
                    font-family: "Roboto";
                
                    max-width: 194px;
                    line-height: 16.41px;
                    margin: 0px auto 0px auto;
                    strong{
                        color: #481EFD;
                    }
                }
      
            }
          }
          .btn-content{
            margin-top: 60px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            width: 100%;

            button{
                
                width: 271px;
                margin-bottom: 20px;
                height: 51px;
                border-radius: 16px;
                color: #481EFD;
                font-family: "Roboto";
                font-size: 16px;
                font-weight: 500;
                background-color: #F1F1F1;
                border: 1px solid #1C18F2;
            }
            button:hover{
                color: #F1F1F1;
                background-color: #481EFD;
                border: 1px solid #1C18F2;
            }
          }
    }
}
</style>