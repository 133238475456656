<template>
    <div>
        <div class="problema-planing-content">
        <div class="superior-data">
            <div class="opc1">
                <h6>1</h6>
                <p>Inadecuada planificación y gestión financiera</p>
            </div>
            <div class="principal">
                <h3>7 PRINCIPALES CAUSAS DE UNA GESTIÓN INEFICIENTE</h3>
           
            </div>
            <div class="opc7">
                <h6>7 <svg width="21" height="22" viewBox="0 0 21 22" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_2835_2318)">
<path d="M9.99594 17.5183C14.2119 17.5183 17.6296 14.1153 17.6296 9.91737C17.6296 5.71947 14.2119 2.31641 9.99594 2.31641C5.78 2.31641 2.3623 5.71947 2.3623 9.91737C2.3623 14.1153 5.78 17.5183 9.99594 17.5183Z" fill="white"/>
<path d="M20.6356 19.615L19.6658 18.5369L17.7444 16.402L17.6757 16.3249C20.8544 12.5441 20.8046 6.89173 17.3187 3.15887C13.6764 -0.739112 7.51644 -1.07489 3.46822 2.40397C-0.826387 6.09514 -1.18414 12.5913 2.68006 16.7275C6.0333 20.3173 11.5204 20.8834 15.5284 18.2358L15.5994 18.3152L17.5209 20.4501L18.4907 21.5282C19.0159 22.1125 19.9177 22.1613 20.5037 21.6383L20.5243 21.6203C21.1102 21.0973 21.16 20.1993 20.6348 19.6158L20.6356 19.615ZM4.68916 14.6673C2.14778 11.8348 2.31836 7.44532 5.07614 4.82124C8.00055 2.0391 12.6347 2.23883 15.3096 5.22071C17.851 8.05318 17.6804 12.4426 14.9218 15.0667C11.9974 17.8489 7.36402 17.6491 4.68916 14.6673Z" fill="#491EFD"/>
<path d="M13.9028 9.63322C13.8626 9.13388 13.7283 8.66443 13.5206 8.23979C13.5261 8.28068 13.5324 8.32079 13.5356 8.36246C13.5356 8.37111 13.5356 8.37976 13.5356 8.3892C13.6059 9.43899 12.8161 10.3535 11.7595 10.4377C10.6941 10.5226 9.76064 9.73152 9.67535 8.66993C9.59006 7.60913 10.3845 6.67965 11.4507 6.59472C11.6031 6.58293 11.7516 6.59001 11.8961 6.61202C11.9656 6.62225 12.0335 6.63797 12.0999 6.65527C11.4065 6.21491 10.5693 5.98687 9.68561 6.05764C7.53041 6.22907 5.9225 8.10847 6.09466 10.2552C6.26762 12.4012 8.15431 14.0022 10.3103 13.8308C12.4663 13.6594 14.0734 11.78 13.9013 9.63322H13.9028Z" fill="#491EFD"/>
</g>
<defs>
<clipPath id="clip0_2835_2318">
<rect width="21" height="22" fill="white"/>
</clipPath>
</defs>
</svg></h6>
                <p>Ineficiente rendición de cuentas </p>
            </div>
        </div>
        <div class="mid-data">
            <div class="opc2">
                <h6>2</h6>
                <p>Barreras burocráticas, administrativas o legales</p>
            </div>
            <div class="opc6">
                <h6>6 <svg width="21" height="22" viewBox="0 0 21 22" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_2835_2318)">
<path d="M9.99594 17.5183C14.2119 17.5183 17.6296 14.1153 17.6296 9.91737C17.6296 5.71947 14.2119 2.31641 9.99594 2.31641C5.78 2.31641 2.3623 5.71947 2.3623 9.91737C2.3623 14.1153 5.78 17.5183 9.99594 17.5183Z" fill="white"/>
<path d="M20.6356 19.615L19.6658 18.5369L17.7444 16.402L17.6757 16.3249C20.8544 12.5441 20.8046 6.89173 17.3187 3.15887C13.6764 -0.739112 7.51644 -1.07489 3.46822 2.40397C-0.826387 6.09514 -1.18414 12.5913 2.68006 16.7275C6.0333 20.3173 11.5204 20.8834 15.5284 18.2358L15.5994 18.3152L17.5209 20.4501L18.4907 21.5282C19.0159 22.1125 19.9177 22.1613 20.5037 21.6383L20.5243 21.6203C21.1102 21.0973 21.16 20.1993 20.6348 19.6158L20.6356 19.615ZM4.68916 14.6673C2.14778 11.8348 2.31836 7.44532 5.07614 4.82124C8.00055 2.0391 12.6347 2.23883 15.3096 5.22071C17.851 8.05318 17.6804 12.4426 14.9218 15.0667C11.9974 17.8489 7.36402 17.6491 4.68916 14.6673Z" fill="#491EFD"/>
<path d="M13.9028 9.63322C13.8626 9.13388 13.7283 8.66443 13.5206 8.23979C13.5261 8.28068 13.5324 8.32079 13.5356 8.36246C13.5356 8.37111 13.5356 8.37976 13.5356 8.3892C13.6059 9.43899 12.8161 10.3535 11.7595 10.4377C10.6941 10.5226 9.76064 9.73152 9.67535 8.66993C9.59006 7.60913 10.3845 6.67965 11.4507 6.59472C11.6031 6.58293 11.7516 6.59001 11.8961 6.61202C11.9656 6.62225 12.0335 6.63797 12.0999 6.65527C11.4065 6.21491 10.5693 5.98687 9.68561 6.05764C7.53041 6.22907 5.9225 8.10847 6.09466 10.2552C6.26762 12.4012 8.15431 14.0022 10.3103 13.8308C12.4663 13.6594 14.0734 11.78 13.9013 9.63322H13.9028Z" fill="#491EFD"/>
</g>
<defs>
<clipPath id="clip0_2835_2318">
<rect width="21" height="22" fill="white"/>
</clipPath>
</defs>
</svg></h6>
                <p>Ineficiente evaluación de resultados</p>
            </div>
        </div>
        <div class="low-data">
            <div class="opc3">
                <h6>3</h6>
                <p>Corrupción y malversación de fondos</p>
            </div>
            <div class="opc4">
                <h6>4</h6>
                <p>Falta de capacidades técnicas y recurso humano especializado </p>
            </div>
            <div class="opc5">
                <h6>5 <svg width="21" height="22" viewBox="0 0 21 22" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_2835_2318)">
<path d="M9.99594 17.5183C14.2119 17.5183 17.6296 14.1153 17.6296 9.91737C17.6296 5.71947 14.2119 2.31641 9.99594 2.31641C5.78 2.31641 2.3623 5.71947 2.3623 9.91737C2.3623 14.1153 5.78 17.5183 9.99594 17.5183Z" fill="white"/>
<path d="M20.6356 19.615L19.6658 18.5369L17.7444 16.402L17.6757 16.3249C20.8544 12.5441 20.8046 6.89173 17.3187 3.15887C13.6764 -0.739112 7.51644 -1.07489 3.46822 2.40397C-0.826387 6.09514 -1.18414 12.5913 2.68006 16.7275C6.0333 20.3173 11.5204 20.8834 15.5284 18.2358L15.5994 18.3152L17.5209 20.4501L18.4907 21.5282C19.0159 22.1125 19.9177 22.1613 20.5037 21.6383L20.5243 21.6203C21.1102 21.0973 21.16 20.1993 20.6348 19.6158L20.6356 19.615ZM4.68916 14.6673C2.14778 11.8348 2.31836 7.44532 5.07614 4.82124C8.00055 2.0391 12.6347 2.23883 15.3096 5.22071C17.851 8.05318 17.6804 12.4426 14.9218 15.0667C11.9974 17.8489 7.36402 17.6491 4.68916 14.6673Z" fill="#491EFD"/>
<path d="M13.9028 9.63322C13.8626 9.13388 13.7283 8.66443 13.5206 8.23979C13.5261 8.28068 13.5324 8.32079 13.5356 8.36246C13.5356 8.37111 13.5356 8.37976 13.5356 8.3892C13.6059 9.43899 12.8161 10.3535 11.7595 10.4377C10.6941 10.5226 9.76064 9.73152 9.67535 8.66993C9.59006 7.60913 10.3845 6.67965 11.4507 6.59472C11.6031 6.58293 11.7516 6.59001 11.8961 6.61202C11.9656 6.62225 12.0335 6.63797 12.0999 6.65527C11.4065 6.21491 10.5693 5.98687 9.68561 6.05764C7.53041 6.22907 5.9225 8.10847 6.09466 10.2552C6.26762 12.4012 8.15431 14.0022 10.3103 13.8308C12.4663 13.6594 14.0734 11.78 13.9013 9.63322H13.9028Z" fill="#491EFD"/>
</g>
<defs>
<clipPath id="clip0_2835_2318">
<rect width="21" height="22" fill="white"/>
</clipPath>
</defs>
</svg></h6>
                <p>Falta de coordinación, participación y vigilancia ciudadana </p>
            </div>
        </div>
        <div class="footer">
            <div class="fuente">
                    <h6>Fuente:</h6>
                    <p>“Fortaleciendo la gestión de las inversiones en América Latina y el  Caribe” (2022)</p>
            </div>
            <div class="grupo">
                <img src="@/assets/img/lupa.png" alt="reacciona-busca">
                 <h6>
                    De estas, 3 están relacionadas a la participación activa de una ciudadanía vigilante
                 </h6>
            </div>
        </div>
        </div>
        <div class="problema-planing-content-movile">
        <div class="superior-data">
            <div class="principal">
                <h3>7 PRINCIPALES CAUSAS DE UNA GESTIÓN INEFICIENTE</h3>
       
            </div>
            <div class="group-1">
                <div class="opc1">
                    <h6>1</h6>
                    <p>Inadecuada planificación y gestión financiera</p>
                </div>
                <div class="opc2">
                    <h6>2</h6>
                    <p>Barreras burocráticas, administrativas o legales</p>
                </div>
                <div class="opc3">
                    <h6>3</h6>
                    <p>Corrupción y malversación de fondos</p>
                </div>
                <div class="opc4">
                    <h6>4</h6>
                    <p>Falta de capacidades técnicas y recurso humano especializado </p>
                </div>
            </div>
            <div class="group-2">
                <div class="opc5">
                    <h6>5 <svg width="21" height="22" viewBox="0 0 21 22" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_2835_2318)">
<path d="M9.99594 17.5183C14.2119 17.5183 17.6296 14.1153 17.6296 9.91737C17.6296 5.71947 14.2119 2.31641 9.99594 2.31641C5.78 2.31641 2.3623 5.71947 2.3623 9.91737C2.3623 14.1153 5.78 17.5183 9.99594 17.5183Z" fill="white"/>
<path d="M20.6356 19.615L19.6658 18.5369L17.7444 16.402L17.6757 16.3249C20.8544 12.5441 20.8046 6.89173 17.3187 3.15887C13.6764 -0.739112 7.51644 -1.07489 3.46822 2.40397C-0.826387 6.09514 -1.18414 12.5913 2.68006 16.7275C6.0333 20.3173 11.5204 20.8834 15.5284 18.2358L15.5994 18.3152L17.5209 20.4501L18.4907 21.5282C19.0159 22.1125 19.9177 22.1613 20.5037 21.6383L20.5243 21.6203C21.1102 21.0973 21.16 20.1993 20.6348 19.6158L20.6356 19.615ZM4.68916 14.6673C2.14778 11.8348 2.31836 7.44532 5.07614 4.82124C8.00055 2.0391 12.6347 2.23883 15.3096 5.22071C17.851 8.05318 17.6804 12.4426 14.9218 15.0667C11.9974 17.8489 7.36402 17.6491 4.68916 14.6673Z" fill="#491EFD"/>
<path d="M13.9028 9.63322C13.8626 9.13388 13.7283 8.66443 13.5206 8.23979C13.5261 8.28068 13.5324 8.32079 13.5356 8.36246C13.5356 8.37111 13.5356 8.37976 13.5356 8.3892C13.6059 9.43899 12.8161 10.3535 11.7595 10.4377C10.6941 10.5226 9.76064 9.73152 9.67535 8.66993C9.59006 7.60913 10.3845 6.67965 11.4507 6.59472C11.6031 6.58293 11.7516 6.59001 11.8961 6.61202C11.9656 6.62225 12.0335 6.63797 12.0999 6.65527C11.4065 6.21491 10.5693 5.98687 9.68561 6.05764C7.53041 6.22907 5.9225 8.10847 6.09466 10.2552C6.26762 12.4012 8.15431 14.0022 10.3103 13.8308C12.4663 13.6594 14.0734 11.78 13.9013 9.63322H13.9028Z" fill="#491EFD"/>
</g>
<defs>
<clipPath id="clip0_2835_2318">
<rect width="21" height="22" fill="white"/>
</clipPath>
</defs>
</svg></h6>
                    <p>Falta de coordinación, participación y vigilancia ciudadana </p>
                </div>
                <div class="opc6">
                    <h6>6<svg width="21" height="22" viewBox="0 0 21 22" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_2835_2318)">
<path d="M9.99594 17.5183C14.2119 17.5183 17.6296 14.1153 17.6296 9.91737C17.6296 5.71947 14.2119 2.31641 9.99594 2.31641C5.78 2.31641 2.3623 5.71947 2.3623 9.91737C2.3623 14.1153 5.78 17.5183 9.99594 17.5183Z" fill="white"/>
<path d="M20.6356 19.615L19.6658 18.5369L17.7444 16.402L17.6757 16.3249C20.8544 12.5441 20.8046 6.89173 17.3187 3.15887C13.6764 -0.739112 7.51644 -1.07489 3.46822 2.40397C-0.826387 6.09514 -1.18414 12.5913 2.68006 16.7275C6.0333 20.3173 11.5204 20.8834 15.5284 18.2358L15.5994 18.3152L17.5209 20.4501L18.4907 21.5282C19.0159 22.1125 19.9177 22.1613 20.5037 21.6383L20.5243 21.6203C21.1102 21.0973 21.16 20.1993 20.6348 19.6158L20.6356 19.615ZM4.68916 14.6673C2.14778 11.8348 2.31836 7.44532 5.07614 4.82124C8.00055 2.0391 12.6347 2.23883 15.3096 5.22071C17.851 8.05318 17.6804 12.4426 14.9218 15.0667C11.9974 17.8489 7.36402 17.6491 4.68916 14.6673Z" fill="#491EFD"/>
<path d="M13.9028 9.63322C13.8626 9.13388 13.7283 8.66443 13.5206 8.23979C13.5261 8.28068 13.5324 8.32079 13.5356 8.36246C13.5356 8.37111 13.5356 8.37976 13.5356 8.3892C13.6059 9.43899 12.8161 10.3535 11.7595 10.4377C10.6941 10.5226 9.76064 9.73152 9.67535 8.66993C9.59006 7.60913 10.3845 6.67965 11.4507 6.59472C11.6031 6.58293 11.7516 6.59001 11.8961 6.61202C11.9656 6.62225 12.0335 6.63797 12.0999 6.65527C11.4065 6.21491 10.5693 5.98687 9.68561 6.05764C7.53041 6.22907 5.9225 8.10847 6.09466 10.2552C6.26762 12.4012 8.15431 14.0022 10.3103 13.8308C12.4663 13.6594 14.0734 11.78 13.9013 9.63322H13.9028Z" fill="#491EFD"/>
</g>
<defs>
<clipPath id="clip0_2835_2318">
<rect width="21" height="22" fill="white"/>
</clipPath>
</defs>
</svg></h6>
                    <p>Ineficiente evaluación de resultados</p>
                </div>
                <div class="opc7">
                    <h6>7<svg width="21" height="22" viewBox="0 0 21 22" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_2835_2318)">
<path d="M9.99594 17.5183C14.2119 17.5183 17.6296 14.1153 17.6296 9.91737C17.6296 5.71947 14.2119 2.31641 9.99594 2.31641C5.78 2.31641 2.3623 5.71947 2.3623 9.91737C2.3623 14.1153 5.78 17.5183 9.99594 17.5183Z" fill="white"/>
<path d="M20.6356 19.615L19.6658 18.5369L17.7444 16.402L17.6757 16.3249C20.8544 12.5441 20.8046 6.89173 17.3187 3.15887C13.6764 -0.739112 7.51644 -1.07489 3.46822 2.40397C-0.826387 6.09514 -1.18414 12.5913 2.68006 16.7275C6.0333 20.3173 11.5204 20.8834 15.5284 18.2358L15.5994 18.3152L17.5209 20.4501L18.4907 21.5282C19.0159 22.1125 19.9177 22.1613 20.5037 21.6383L20.5243 21.6203C21.1102 21.0973 21.16 20.1993 20.6348 19.6158L20.6356 19.615ZM4.68916 14.6673C2.14778 11.8348 2.31836 7.44532 5.07614 4.82124C8.00055 2.0391 12.6347 2.23883 15.3096 5.22071C17.851 8.05318 17.6804 12.4426 14.9218 15.0667C11.9974 17.8489 7.36402 17.6491 4.68916 14.6673Z" fill="#491EFD"/>
<path d="M13.9028 9.63322C13.8626 9.13388 13.7283 8.66443 13.5206 8.23979C13.5261 8.28068 13.5324 8.32079 13.5356 8.36246C13.5356 8.37111 13.5356 8.37976 13.5356 8.3892C13.6059 9.43899 12.8161 10.3535 11.7595 10.4377C10.6941 10.5226 9.76064 9.73152 9.67535 8.66993C9.59006 7.60913 10.3845 6.67965 11.4507 6.59472C11.6031 6.58293 11.7516 6.59001 11.8961 6.61202C11.9656 6.62225 12.0335 6.63797 12.0999 6.65527C11.4065 6.21491 10.5693 5.98687 9.68561 6.05764C7.53041 6.22907 5.9225 8.10847 6.09466 10.2552C6.26762 12.4012 8.15431 14.0022 10.3103 13.8308C12.4663 13.6594 14.0734 11.78 13.9013 9.63322H13.9028Z" fill="#491EFD"/>
</g>
<defs>
<clipPath id="clip0_2835_2318">
<rect width="21" height="22" fill="white"/>
</clipPath>
</defs>
</svg></h6>
                    <p>Ineficiente rendición de cuentas </p>
                </div>
            </div>
          
        </div>
        <div class="footer">
            <div class="fuente">
                    <h6>Fuente:</h6>
                    <p>“Fortaleciendo la gestión de las inversiones en América Latina y el  Caribe” (2022)</p>
            </div>
            <div class="grupo">
               
                 <h6>
                    De estas, 3 están relacionadas a la participación activa de una ciudadanía vigilante
                 </h6>
            </div>
        </div>
        </div>
    </div>
   
</template>

<script>
    export default {
        name:"problema-section"
    }
</script>

<style lang="scss" scoped>
@media only screen and (min-width: 601px) {
.problema-planing-content{
    display: flex;
    flex-direction: column;
    width: 100%;
}
.superior-data{
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding-top: 140px;
    .opc1{
        margin-left: 30px;
        h6{
            font-size: 24px;
            font-weight: 500;
            font-family: "Roboto";
            line-break: 28.13px;
            text-transform: uppercase;
            text-align: left;
            margin: 0px;
        }
        p{
            font-size: 22px;
            font-weight: 400;
            line-height: 25.78px;
            font-family: "Roboto";
            text-align: left;
            width: 257px;
            margin:15px 0px 0px 0px;
        }
    }
    .principal{
        h3{
            width: 410px;
            font-size: 45px;
            font-weight: 700;
            font-family: "Bebas Neue";
            line-break: 28.13px;
            text-transform: uppercase;
            text-align: center;
            margin: 0px;
        }
        p{
            font-size: 24px;
            font-weight: 500;
            width: 346px;
            line-height: 28.13px;
            font-family: "Roboto";
            text-align: center;
        }
    }
    .opc7{
        margin-right: 30px;
        h6{
            font-size: 24px;
            font-weight: 500;
            font-family: "Roboto";
            line-break: 28.13px;
            text-transform: uppercase;
            text-align: left;
            margin: 0px;
            color: #481EFD;
        }
        p{
            font-size: 22px;
            font-weight: 700;
            line-height: 25.78px;
            font-family: "Roboto";
            text-align: left;
            width: 257px;
            margin:15px 0px 0px 0px;
            color: #481EFD;
        }
    }
}
.mid-data{
    display: flex;
    justify-content: space-around;
    width: 100%;
    
    .opc2{
        margin-right: 300px;
        margin-top: 30px;
        h6{
            font-size: 24px;
            font-weight: 500;
            font-family: "Roboto";
            line-break: 28.13px;
            text-transform: uppercase;
            text-align: left;
            margin: 0px;
        }
        p{
            font-size: 22px;
            font-weight: 400;
            line-height: 25.78px;
            font-family: "Roboto";
            text-align: left;
            width: 257px;
            margin:15px 0px 0px 0px;
        }
    }
    .opc6{
        margin-left: 140px;
        margin-top: 30px;
        h6{
            font-size: 24px;
            font-weight: 500;
            font-family: "Roboto";
            line-break: 28.13px;
            text-transform: uppercase;
            text-align: left;
            margin: 0px;
            color: #481EFD;
        }
        p{
            font-size: 22px;
            font-weight: 700;
            line-height: 25.78px;
            font-family: "Roboto";
            text-align: left;
            width: 257px;
            margin:15px 0px 0px 0px;
            color: #481EFD;
        }
    }
}
.low-data{
    display: flex;
    justify-content: space-evenly;
    width: 100%;
    margin-top: 50px;
    .opc3{
        margin-left: 110px;
        h6{
            
            font-size: 24px;
            font-weight: 500;
            font-family: "Roboto";
            line-break: 28.13px;
            text-transform: uppercase;
            text-align: left;
            margin: 0px;
        }
        p{
            font-size: 22px;
            font-weight: 400;
            line-height: 25.78px;
            font-family: "Roboto";
            text-align: left;
            width: 257px;
            margin:15px 0px 0px 0px;
        }
    }
    .opc4{
        margin-top: 50px;
        h6{
            font-size: 24px;
            font-weight: 500;
            font-family: "Roboto";
            line-break: 28.13px;
            text-transform: uppercase;
            text-align: left;
            margin: 0px;
        }
        p{
            font-size: 22px;
            font-weight: 400;
            line-height: 25.78px;
            font-family: "Roboto";
            text-align: left;
            width: 257px;
            margin:15px 0px 0px 0px;
        }
    }
    .opc5{
        margin-right: 110px;
        h6{
            font-size: 24px;
            font-weight: 500;
            font-family: "Roboto";
            line-break: 28.13px;
            text-transform: uppercase;
            text-align: left;
            margin: 0px;
            color: #481EFD;
        }
        p{
            font-size: 22px;
            font-weight: 700;
            line-height: 25.78px;
            font-family: "Roboto";
            text-align: left;
            width: 257px;
            margin:15px 0px 0px 0px;
            color: #481EFD;
        }
    }
}
.footer{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  
    .fuente{
        width: 232px;
        h6{
            font-family: "Roboto";
            font-size: 12px;
            line-height: 14.06px;
            font-weight: 400;
            text-align: left;
            margin: 0px;
        }
       p{
        text-align: left;
        margin: 0px;
            font-family: "Roboto";
            font-size: 12px;
            line-height: 14.06px;
            font-weight: 400;
       }
    }
    .grupo{
        width: 363px;
        display: flex;
        align-items: center;
        img{
            height: 55px;
            width: 53px;
            margin-right: 10px;
        }
        h6{
            font-family: "Roboto";
            font-size: 20px;
            line-height: 23.44px;
            font-weight: 700;
            text-align: left;
            color: #481EFD;
        }
    }
}
.problema-planing-content-movile{
    display: none;
}
}
@media only screen and (max-width: 600px) {
    .problema-planing-content{
    display: none;
}
.problema-planing-content-movile{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
}
.superior-data{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    padding-top: 40px;
    .group-1{
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        width: 100%;
        .opc1{
            flex-basis: 50%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            
        h6{
            font-size: 24px;
            font-weight: 500;
            font-family: "Roboto";
            line-break: 28.13px;
            text-transform: uppercase;
            text-align: left;
            margin: 0px;
            width: 121px;
    
        }
        p{
            font-size: 14px;
            font-weight: 400;
            line-height: 16.41px;
            font-family: "Roboto";
            text-align: left;
            width: 121px;
            margin:15px 0px 0px 0px;
        }
        }
        .opc2{
            flex-basis: 50%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
                    h6{
                        font-size: 24px;
                        font-weight: 500;
                        font-family: "Roboto";
                        line-break: 28.13px;
                        text-transform: uppercase;
                        text-align: left;
                        margin: 0px;
                        width: 143px;
                    }
                    p{
                        font-size: 14px;
                        font-weight: 400;
                        line-height: 16.41px;
                        font-family: "Roboto";
                        text-align: left;
                        width: 143px;
                        margin:15px 0px 0px 0px;
                    }
    }  .opc3{
        flex-basis: 50%;
        display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
   
            margin-top: 30px;
        h6{
            
            font-size: 24px;
            font-weight: 500;
            font-family: "Roboto";
            line-break: 28.13px;
            text-transform: uppercase;
            text-align: left;
            margin: 0px;
            width: 121px;
        }
        p{
            font-size: 14px;
            font-weight: 400;
            line-height: 16.41px;
            font-family: "Roboto";
            text-align: left;
            width: 121px;
            margin:15px 0px 0px 0px;
        }
    }
    .opc4{
        flex-basis: 50%;
        display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
  
            margin-top: 30px;
      
        h6{
            font-size: 24px;
            font-weight: 500;
            font-family: "Roboto";
            line-break: 28.13px;
            text-transform: uppercase;
            text-align: left;
            margin: 0px;
            width: 143px;
        }
        p{
            font-size: 14px;
            font-weight: 400;
            line-height: 16.41px;
            font-family: "Roboto";
            text-align: left;
            width: 143px;
            margin:15px 0px 0px 0px;
        }
    }
    }
    .group-2{
        margin-top: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        flex-direction: column;
        width: 100%;
        .opc5{
        flex-basis: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        width: 100%;
        margin-bottom: 20px;
        h6{
            font-size: 24px;
            font-weight: 500;
            font-family: "Roboto";
            line-break: 28.13px;
            text-transform: uppercase;
            text-align: left;
            margin: 0px;
            color: #481EFD;
            width: 205px;
        }
        p{
            font-size: 14px;
            font-weight: 700;
            line-height: 16.41px;
            font-family: "Roboto";
            text-align: left;
            width: 205px;
            margin:15px 0px 0px 0px;
            color: #481EFD;
        }
        }
        .opc6{
            flex-basis: 100%;
            display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        width: 100%;
        margin-bottom: 20px;
        h6{
            font-size: 24px;
            font-weight: 500;
            font-family: "Roboto";
            line-break: 28.13px;
            text-transform: uppercase;
            text-align: left;
            margin: 0px;
            color: #481EFD;
            width: 205px;
        }
        p{
            font-size: 14px;
            font-weight: 700;
            line-height: 16.41px;
            font-family: "Roboto";
            text-align: left;
            width: 205px;
            margin:15px 0px 0px 0px;
            color: #481EFD;
        }
         }
        .opc7{
            display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        width: 100%;
            flex-basis: 100%;
            margin-bottom: 20px;
            h6{
                font-size: 24px;
                font-weight: 500;
                font-family: "Roboto";
                line-break: 28.13px;
                text-transform: uppercase;
                text-align: left;
                margin: 0px;
                color: #481EFD;
                width: 205px;
            }
            p{
                font-size: 14px;
                font-weight: 700;
                line-height: 16.41px;
                font-family: "Roboto";
                text-align: left;
                width: 205px;
                margin:15px 0px 0px 0px;
                color: #481EFD;
            }
        }
    }
    
    .principal{
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        h3{
            width: 310px;
            font-size: 45px;
            font-weight: 700;
            font-family: "Bebas Neue";
            line-break: 28.13px;
            text-transform: uppercase;
            text-align: center;
        
        }
        p{
            font-size: 24px;
            font-weight: 500;
            width: 310px;
            line-height: 28.13px;
            font-family: "Roboto";
            text-align: center;
        }
    }
   
}

.footer{
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column-reverse;
    width: 100%;
  
    .fuente{
        width: 283px;
        h6{
            font-family: "Roboto";
            font-size: 12px;
            line-height: 14.06px;
            font-weight: 400;
            text-align: left;
            margin: 0px;
        }
       p{
        text-align: left;
        margin: 0px;
        font-family: "Roboto";
        font-size: 12px;
        line-height: 14.06px;
        font-weight: 400;
       }
    }
    .grupo{
        width: 283px;
        h6{
            font-family: "Roboto";
            font-size: 20px;
            line-height: 23.44px;
            font-weight: 700;
            text-align: left;
            color: #481EFD;
        }
    }
}
}

</style>