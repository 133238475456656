<template>
    <div class="container">
        <h2>CONSEJO CONSULTIVO</h2>
        <div class="equipo-content">
            <div v-for="(consultor, index) in consultores" :key="consultor.name+'-'+index" class="equipo">
                <equipoCard :consultor="consultor"></equipoCard>
            </div>
        </div>
    </div>
</template>

<script>
import equipoCard from '@/components/cards/consultorCard.vue'
    export default {
        name:"consultivo-component",
        data() {
            return {
                consultores: [
                    {
                        name:"David Tuesta",
                        occupation:"Presidente Ejecutivo del Consejo Privado de Competitividad",
                        image:"/img/consultor-1.png"
                    },
                    {
                        name:"Marisol Guiulfo",
                        occupation:"Gerenta General del Consejo Privado de Competitividad ",
                        image:"/img/consultor-2.png"
                    },
                    {
                        name:"Gabriel Ortiz de Zevallos",
                        occupation:"Presidente Ejecutivo de Instituto APOYO",
                        image:"/img/consultor-3.png"
                    },
                    {
                        name:"Mayte Morales",
                        occupation:"Directora General de Instituto APOYO",
                        image:"/img/consultor-4.png"
                    },
                    {
                        name:"Iván Portocarrero",
                        occupation:"Director de Análisis Sectorial de APOYO Consultoría",
                        image:"/img/consultor-5.png"
                    }
                ]
            }
        },
        components: {
            equipoCard,
        },
    }
</script>

<style lang="scss" scoped>
@media only screen and (min-width: 601px) {
    .container{
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
          width: 100%;
          max-width: 1150px;
          margin: 0px auto 0px auto;
          h2{
            font-size: 45px;
            font-weight: 700;
            font-family: "Bebas Neue";
            line-height: 45px;
            text-transform: uppercase;
            text-align: center;
            margin: 0px;
            z-index: 1;
            margin-top: -200px;
          }
          .equipo-content{
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 100%;
            margin-top: 50px;
            .equipo{
                flex-basis: 20%;
                display: flex;
                justify-content: center;
                margin-right: 10px;
              
            }
          }
          .elipsi-2{
            background: #ffffff;
            width: 1250px;
            height: 179px;
            border-radius: 50%;
            z-index: 1;
            margin-top: 80px;
          }
   
    }
}
@media only screen and (max-width: 600px) {
    .container{
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
          width: 100%;
          max-width: 600px;
          margin: 0px auto 0px auto;
          h2{
            font-size: 45px;
            font-weight: 700;
            font-family: "Bebas Neue";
            line-height: 45px;
            text-transform: uppercase;
            text-align: center;
            margin: 0px;
            z-index: 1;
            margin-bottom: 40px;
          }
          .equipo-content{
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            flex-wrap: wrap;
            .equipo{
                flex-basis: 50%;
                display: flex;
                justify-content: center;
            }
          }
          .elipsi-2{
            display: none;
          }
   
    }
}
</style>