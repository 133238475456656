<template>
    <div class="footer-background">
        <div class="container"> 
            <img src="@/assets/img/reacciona-footer.svg" alt="reaciona-logo-footer">
            <div class="ft-c">
                <div class="listas-content">
               
                <nav>
                    Menú
                    <a @click.prevent="gotoScroll('inicio')">Inicio</a>
                    <a @click.prevent="gotoScroll('problema')">Problema</a>
                    <a @click.prevent="gotoScroll('como')">ReAcciona</a>
                    <a @click.prevent="gotoNav('/participa')">Participa</a>
                    <!-- <a @click.prevent="gotoScroll('publicaciones')">Publicaciones</a> -->
                    <a @click.prevent="gotoScroll('vigilante')">#ReAccionaApp</a>
                </nav>
                <nav>
                    Legales
                    <!-- <a href="">Misión  y Visión</a> -->
                    <router-link to="/cierre-de-cuenta">Eliminar Cuenta</router-link>
                    <router-link to="/terminos-condiciones">Términos y condiciones</router-link>
                </nav>
            </div>
            <div class="suscribe">
                <h5>Suscribete y siguenos para estar informado</h5>

                <!-- <form>
                    <input required type="email" placeholder="Tu Email...">
                    <button type="submit">Suscribete</button>
                </form> -->

                <div class="redes-content">
                    <a href="https://www.linkedin.com/company/reacciona-pe/" target="_blank" rel="noopener noreferrer"><img src="@/assets/img/linkedin-footer.svg" alt=""></a>
                    <a href="hhttps://twitter.com/ReAcciona_pe" target="_blank" rel="noopener noreferrer"><img src="@/assets/img/twitter-footer.svg" alt=""></a>
                    <a href="https://www.facebook.com/share/W2EgWnjwhWtn9NNV/?mibextid=WC7FNe" target="_blank" rel="noopener noreferrer"><img src="@/assets/img/facebook-footer.svg" alt=""></a>
                    <a href="https://www.instagram.com/re.accionape?igsh=eGppamhyeGxqa2Z1" target="_blank" rel="noopener noreferrer"><img src="@/assets/img/instagram-footer.svg" alt=""></a>
                </div>
            </div>
            </div>
            <div class="divider">

            </div>
            <div class="copyContent">
                <p>Todos los derechos reservados a ReAcciona App e Instituto APOYO</p>
            </div>
      
        </div>
    </div>
</template>

<script>
    export default {
        name:"footer-component",
        methods: {
            gotoScroll(value){
                const el = document.getElementById(value);
                el.scrollIntoView({behavior: "smooth"});
            },
            gotoNav(value){
                this.$router.push(value);
            }
        },
    }
</script>

<style lang="scss" scoped>
@media only screen and (min-width: 601px) {
    .footer-background{
        background-color: #481EFD;
        width: 100%;
    }
    .container{
          display: flex;
          align-items: flex-start;
          justify-content: center;
          flex-direction: column;
          width: 100%;
          max-width: 1150px;
          margin: 0px auto 0px auto;
          img{
            margin-top: 100px;
          }
          .ft-c{
            display: flex;
            width: 100%;
            .listas-content{
            flex-basis: 60%;
            display: flex;
            justify-content: space-between;
            nav{
                margin-top: 80px;
                font-family: "Roboto";
                flex-basis: 50%;
                color: #00FF98;
                font-size: 20px;
                font-weight: 700;
                line-height: 23.44px;
                text-align: left;
                display: flex;
                flex-direction: column;

                a:first-child{
                    margin-top: 20px;
                    color: #ffffff;
                    font-size: 14px;
                    font-weight: 400;
                    line-height: 16.41px;
                    margin-bottom: 10px;
                    text-decoration: none;
                }
                a{
                    text-decoration: none;
                    color: #ffffff;
                    font-size: 14px;
                    font-weight: 400;
                    line-height: 16.41px;
                    margin-bottom: 10px;
                }
            }
          }
          .suscribe{
            flex-basis: 40%;
            h5{
                margin-top: 80px;
                font-family: "Roboto";
                flex-basis: 50%;
                color: #00FF98;
                font-size: 20px;
                font-weight: 700;
                line-height: 23.44px;
                text-align: left;
                width: 223px;
            }
            form{
                display: flex;
                justify-content: flex-start;
                input{
                    width: 272px;
                    height: 40px;
                    border-radius: 5px;
                    color: #481EFD;
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 16.41px;
                    border: none;
                    padding-left: 10px;
                }
                input::placeholder{
                    color: #481EFD;
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 16.41px;
                    padding-left: 10px;
                }
                button{
                    width: 120px;
                    height: 40px;
                    border: none;
                    border-radius: 9px;
                    color: #481EFD;
                    background-color: #00FF98;
                    font-weight: bold;
                    margin-left: 8px;
                }
            }
            .redes-content{
                display: flex;
                justify-content: flex-start;
                a{
                    margin-right: 15px;
                }
            }
          }
          }
          .divider{
            margin-top: 60px;
            border: 1px solid #FFFFFF;
            width: 100%;
          }
          .copyContent{
            display: flex;
            justify-content: center;
            width: 100%;
            p{
          
            margin-top: 40px;
            color: #FFFFFF;
            font-size: 14px;
            line-height: 16.41px;
            font-weight: 400;
            text-align: center;
            padding-bottom: 60px;
          }
          }
         
         
          
    }
}
@media only screen and (max-width: 600px) {
    .footer-background{
        background-color: #481EFD;
        width: 100%;
    }
    .container{
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
          width: 100%;
          max-width: 600px;
          margin: 0px auto 0px auto;
          img{
            margin-top: 100px;
          }
          .ft-c{
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            width: 100%;
            .listas-content{
            flex-basis: 100%;
            display: flex;
            justify-content: center;
            width: 100%;
            nav{
                margin-top: 80px;
                font-family: "Roboto";
                flex-basis: 50%;
                color: #00FF98;
                font-size: 20px;
                font-weight: 700;
                line-height: 23.44px;
                text-align: left;
                display: flex;
                align-items: center;
                flex-direction: column;
                a:first-child{
                    margin-top: 20px;
                    color: #ffffff;
                    font-size: 14px;
                    font-weight: 400;
                    line-height: 16.41px;
                    text-align: left;
                    margin-bottom: 10px;
                    text-decoration: none;
                }
                a{
                    text-decoration: none;
                    color: #ffffff;
                    font-size: 14px;
                    text-align: left;
                    font-weight: 400;
                    line-height: 16.41px;
                    margin-bottom: 10px;
                }
            }
            }
            .suscribe{
                flex-basis: 100%;
                width: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;
                h5{
                    margin-top: 80px;
                    font-family: "Roboto";
                    flex-basis: 50%;
                    color: #00FF98;
                    font-size: 20px;
                    font-weight: 700;
                    line-height: 23.44px;
                    text-align: center;
                   
                }
                form{
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    flex-direction: column;
                    width: 100%;
                    input{
                        width: 90%;
                        height: 40px;
                        border-radius: 5px;
                        color: #481EFD;
                        font-weight: 400;
                        font-size: 14px;
                        line-height: 16.41px;
                        border: none;
                        padding-left: 10px;
                    }
                    input::placeholder{
                        color: #481EFD;
                        font-weight: 400;
                        font-size: 14px;
                        line-height: 16.41px;
                        padding-left: 10px;
                    }
                    button{
                        width: 90%;
                        height: 40px;
                        border: none;
                        border-radius: 9px;
                        color: #481EFD;
                        background-color: #00FF98;
                        font-weight: bold;
                        margin-top: 10px;
                    }
                }
                .redes-content{
                    display: flex;
                    justify-content: flex-start;
                    a{
                        margin-right: 15px;
                    }
                }
            }
          }
          .divider{
            margin-top: 60px;
            border: 1px solid #FFFFFF;
            width: 100%;
          }
          .copyContent{
            display: flex;
            justify-content: center;
            width: 100%;
            p{
          
            margin-top: 40px;
            color: #FFFFFF;
            font-size: 14px;
            line-height: 16.41px;
            font-weight: 400;
            text-align: center;
            padding-bottom: 60px;
          }
          }
         
         
          
    }
}
</style>