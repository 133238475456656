<template>
    <div class="hero-section">
        <div class="hero-head">
            <div class="logo-content">
                <img src="@/assets/img/participa-reacciona-logo.svg" alt="participa-logo-reacciona">
            </div>
            <div class="title-content">
                <h2>Se parte de la Red vigilante de la Inversión Pública</h2>
            </div>
        </div>
        <div class="divider">

        </div>
        <div class="body-content">
            <div class="img-content">
                <img src="@/assets/img/participa-chica-reacciona.svg" alt="participa-chica-reacciona">
            </div>
            <div class="info-content">
                <ul>
                    BECAS:
                    <li>Para jóvenes de Arequipa y Cusco entre 18 y 35 años.</li>
                    <li>Postula entre el 21 de Junio hasta el 15 de Julio del 2024.</li>
                    <li>Inicio del Programa: Lunes 05 de agosto, 2024.</li>
                </ul>
                <div class="btn-content">
                    <p>Becas para región Arequipa y Cusco</p>
                    <button @click.prevent="gotonav('https://bit.ly/quiero-beca')">¡Quiero una Beca!</button>
                </div>
            </div>

        </div>
    </div>
</template>

<script>
import store from "@/store/index"
    export default {
        name:"hero-participa",
        methods: {
            muypronto(){
                store.dispatch("showToast", {
                message: "Muy Pronto",
                type: "warning",
            });
            },
            gotonav(value) {
                window.location=value;
            },
        },
    }
</script>

<style lang="scss" scoped>
@media only screen and (min-width: 601px) {
    .hero-section{
        width: 100%;
        margin-top: 60px;
        .hero-head{
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 100%;
            .logo-content{
                flex-basis: 50%;
                display: flex;
                justify-content: flex-start;
                width: 100%;
            }
            .title-content{
                flex-basis: 50%;
                display: flex;
                justify-content: flex-start;
                width: 100%;
                h2{
                    font-size: 45px;
                    font-weight: 700;
                    font-family: "Bebas Neue";
                    line-height: 45px;
                    text-transform: uppercase;
                    text-align: left;
                    margin: 0px;
                    width: 351px;
                    margin-left: 30px;
                    color: #ffffff;
                }
            }
        }
        .divider{
            margin-top: 2px;
            border: 3px solid #FFFFFF;
            width: 100%;
          }
        .body-content{
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 100%;
            .img-content{
                flex-basis: 50%;
                display: flex;
                justify-content: flex-end;
                align-items: end;
                margin-bottom: -130px;
            }
            .info-content{
                flex-basis: 50%;
                display: flex;
                justify-content: flex-start;
                align-items: flex-start;
                flex-direction: column;
                width: 100%;
                ul{
                    text-align: left;
                    font-size: 24px;
                    font-weight: 500;
                    color: #ffffff;
                    font-family: "Roboto";
                    margin-left: 10px;
                    line-height: 28.13px;
                }
                .btn-content{
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    width: 100%;
                    p{
                        text-align: left;
                        font-size: 18px;
                        font-weight: 700;
                        color: #481EFD;
                        font-family: "Roboto";
                        margin-left: 25px;
                        line-height: 21.09px;
                    }
                    button{
                        width: 215px;
                        background-color: #1C18F2;
                        border-radius: 16px;
                        padding: 16px, 44px, 16px, 44px;
                        color: #ffffff;
                        font-size: 16px;
                        font-weight: 500;
                        border: none;
                        height: 51px;
                  
                        cursor: pointer;
                    }
                }
            }
        }
    }
}
@media only screen and (max-width: 600px) {
    .hero-section{
        width: 100%;
        margin-top: 60px;
        .hero-head{
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            width: 95%;
            .logo-content{
                flex-basis: 100%;
                display: flex;
                justify-content: center;
                width: 100%;
            }
            .title-content{
                flex-basis: 100%;
                margin-top: 20px;
                display: flex;
                justify-content: center;
                width: 100%;
                h2{
                    font-size: 45px;
                    font-weight: 700;
                    font-family: "Bebas Neue";
                    line-height: 45px;
                    text-transform: uppercase;
                    text-align: center;
                    margin: 0px;
                    width: 90%;
                    color: #ffffff;
                }
            }
        }
        .divider{
            margin-top: 2px;
            border: 3px solid #FFFFFF;
            width: 100%;
          }
        .body-content{
            display: flex;
            justify-content: space-between;
            align-items: center;
            flex-direction: column;
            width: 100%;
            .img-content{
               display: none;
            }
            .info-content{
                flex-basis: 50%;
                display: flex;
                justify-content: flex-start;
                align-items: flex-start;
                flex-direction: column;
                width: 90%;
                ul{
                    text-align: left;
                    font-size: 24px;
                    font-weight: 500;
                    color: #ffffff;
                    font-family: "Roboto";
                    margin-left: 10px;
                    line-height: 28.13px;
                }
                .btn-content{
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    flex-direction: column;
                    width: 100%;
                    p{
                        text-align: center;
                        font-size: 18px;
                        font-weight: 700;
                        color: #481EFD;
                        font-family: "Roboto";
                        line-height: 21.09px;
                    }
                    button{
                        width: 215px;
                        background-color: #1C18F2;
                        border-radius: 16px;
                        padding: 16px, 44px, 16px, 44px;
                        color: #ffffff;
                        font-size: 16px;
                        font-weight: 500;
                        border: none;
                        height: 51px;
                        cursor: pointer;
                    }
                }
            }
        }
    }
}
</style>