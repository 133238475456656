import { createStore } from "vuex";
import AUTH from './Modules/auth/auth.js'
import toastr from "toastr";

export default createStore({
  state: {
    sideBarStatus:false,
  },
  getters: {},
  mutations: {
    SET_SIDEBAR_STATUS: (state, data) => {
      state.sideBarStatus = data;
    },
  },
  actions: {
    SIDEBAR_STATUS: (rootstate, value) => {
      return rootstate.commit("SET_SIDEBAR_STATUS", value); 
    },
    showToast(state, toast) {
			const message = toast.message?toast.message:(typeof toast === 'string' ? toast:'' )
			const title = toast.title?toast.title:'Reacciona:';
			const overrides = toast.overrides?toast.overrides:{};
			
			switch (toast.type) {
				case 'warning': 
					toastr.warning(message, title, overrides)
					break;
				case 'success': 
					toastr.success(message, title, overrides)
					break;
				case 'info': 
					toastr.info(message, title, overrides)
					break;
				case 'error':
				default:
					toastr.error(message, title, overrides)
					break;
			}
		}
  },
  modules: {
    AUTH,
  },
});
