<template>
             <div class="dinero_card">
                            <div class="info">
                                <h2>
                                    {{ slidem.title }}
                                    <strong>{{ slidem.strong_1 }}</strong>
                                </h2>
                                <div class="img-movil">
                                    <img src="@/assets/img/dinero.png" alt="slider-reacciona-img">
                                </div>
                                <p class="footer" v-if="slidem.footer">{{ slidem.footer }}</p>
                                <h3 v-else>
                                    {{ slidem.title_2 }}
                                    <strong>{{ slidem.strong_2 }}</strong>
                                </h3>
                            </div>
                            <div class="img">
                                <img src="@/assets/img/dinero.png" alt="slider-reacciona-img">
                            </div>
                        </div>
</template>

<script>
    export default {
        name:"dinero-card",
        props:['slidem']
    }
</script>

<style lang="scss" scoped>
@media only screen and (min-width: 601px) {
    .dinero_card{
    display: flex;
    justify-content: space-between;
    width: 100%;
        height: 100%;
    .info{
        flex-basis: 65%;
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        flex-direction: column;
        img{
            margin-top: 50px;
        }
        h2{
            font-size: 45px;
            line-height: 45px;
            margin-top: 120px;
            color: #313131;
            text-transform: uppercase;
            text-align: left;
            font-weight: 700;
      
            width: 587px;
            font-family: "Bebas Neue";
            strong{
                font-size: 45px;
                line-height: 45px;
                color: #481EFD;
            }
        }
        h3{
            font-size: 45px;
            line-height: 45px;
            color: #313131;
            text-transform: uppercase;
            text-align: left;
            font-weight: 700;
            width: 587px;
            margin-top: 0px;
            font-family: "Bebas Neue";
            strong{
                font-size: 45px;
                line-height: 45px;
                color: #FF0069;

            }
        }
        .img-movil{
            display: none;
        }
        p{
            font-family: "Roboto";
            margin-top: 40px;
            color: #313131;
            font-size: 24px;
            font-weight: 500;
            text-align: left;
            width: 646px;
        }
        .footer{
            font-family: "Roboto";
            margin-top: 70px;
            color: #481EFD;
            font-size: 24px;
            font-weight: 500;
            text-align: left;
            width: 646px;
        }
    }   
    .img{
        flex-basis: 35%;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
        margin-top: 20px;
        margin-left: 30px;
    }
  }
}
@media only screen and (max-width: 600px) {
    .dinero_card{
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    width: 100%;
    height: 100%;
    .info{
        flex-basis: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        .img-movil{
            margin-top: 20px;
            img{
            width: 219px;
            height: 193px;
        }   
        }
        h2{
            font-size: 24px;
            line-height: 28.13px;
            margin-top: 40px;
            color: #313131;
            text-transform: uppercase;
            text-align: center;
            font-weight: 700;
      
            width: 323px;
            font-family: "Roboto";
            strong{
                font-size: 24px;
                line-height: 28.13px;
                color: #481EFD;
            }
        }
        h3{
            font-size: 24px;
            line-height: 28.13px;
       
            color: #313131;
            text-transform: uppercase;
            text-align: center;
            font-weight: 700;
      
            width: 323px;
            font-family: "Roboto";
            strong{
                font-size: 24px;
                line-height: 28.13px;
                color: #FF0069;
            }
        }

        
        .footer{
            font-family: "Roboto";
            margin-top: 30px;
            color: #313131;
            font-size: 14px;
            font-weight: 700;
            text-align: left;
            width: 314px;
        }
    }   
    .img{
     display: none;
    }
  }
}
  
</style>