<template>
    <div class="publicacion-card">
        <div class="header">

        </div>
        <div class="body">
            <h3>Prensa</h3>
            <p>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Neque aliquid voluptas maxime nam enim quos aliquam perspic!</p>
        </div>
    </div>
</template>

<script>
    export default {
        name:"publicacion-card"
    }
</script>

<style lang="scss" scoped>
    .publicacion-card{
        height: 291px;
        width: 261.61px;
        display: flex;
        flex-direction: column;
        .header{
            flex-basis: 60%;
            background-color: grey;
            width: 100%;
        }
        .body{
            flex-basis: 40%;
            height: 100%;
            background-color:#00FF98;
            max-height: 124px;
            h3{
                font-size: 20px;
                font-weight: 700;
                line-height: 23.44px;
                text-align: left;
                font-family: "Roboto";
                margin-left: 15px;
                margin-bottom: 0px;
                color: #313131;
            }
            p{
                margin-top: 0px;
                font-family: "Roboto";
                font-size: 14px;
                font-weight: 400;
                line-height: 16.41px;
                text-align: left;
                margin-left: 15px;
                color: #313131;
            }
        }
    }
</style>