<template>
    <div class="container">
        <h2>NOSOTROS</h2>
        <h4>¿Qué nos mueve?</h4>
        <div class="nosotros-content">
            <div class="nosotros">
                <img src="@/assets/img/check-rosa.svg" alt="check-rosa">
                <p>Queremos que la información sea  transparente, amigable, confiable y de fácil acceso.</p>
            </div>
            <div class="nosotros">
                <img src="@/assets/img/cohete.svg" alt="check-rosa">
                <p>Queremos el cierre de brechas y potenciar el desarrollo del país.</p>
            </div>
            <div class="nosotros">
                <img src="@/assets/img/user-p.svg" alt="check-rosa">
                <p>El fortalecimiento de la institucionalidad en beneficio de la ciudadanía.</p>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name:"nosotros-section"
    }
</script>

<style lang="scss" scoped>
@media only screen and (min-width: 601px) {
    .container{
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
          width: 100%;
          max-width: 1150px;
          margin: 0px auto 0px auto;
          h2{
            font-size: 45px;
            font-weight: 700;
            font-family: "Bebas Neue";
            line-height: 45px;
            text-transform: uppercase;
            text-align: center;
            margin: 0px;
          }
          h4{
            text-align: center;
            font-size: 20px;
            line-height: 23.44px;
            font-weight: 700;
            font-family: "Roboto";
            margin-top: 30px;
          }
          .nosotros-content{
            display: flex;
            justify-content: space-between;
            align-items: center;
            .nosotros{
                flex-basis: calc(33.3% - 2rem);
                display: flex;
                justify-content: space-between;
                p{
                    text-align: left;
                    font-size: 14px;
                    line-height: 16.41px;
                    font-weight: 400;
                    font-family: "Roboto";
                    margin-left: 10px;
                }
            }
          }
        }
}
@media only screen and (max-width: 600px) {
    .container{
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
          width: 100%;
          max-width: 600px;
          margin: 0px auto 0px auto;
          h2{
            font-size: 45px;
            font-weight: 700;
            font-family: "Bebas Neue";
            line-height: 45px;
            text-transform: uppercase;
            text-align: center;
            margin: 0px;
          }
          h4{
            text-align: center;
            font-size: 20px;
            line-height: 23.44px;
            font-weight: 700;
            font-family: "Roboto";
            margin-top: 30px;
          }
          .nosotros-content{
            display: flex;
            justify-content: space-between;
            align-items: center;
            flex-basis: 100%;
            flex-direction: column;
            .nosotros{
                flex-basis:100%;
                display: flex;
                justify-content: space-between;
                p{
                    text-align: left;
                    font-size: 14px;
                    line-height: 16.41px;
                    width: 190px;
                    font-weight: 400;
                    font-family: "Roboto";
                    margin-left: 20px;
                   
                }
                margin-bottom: 30px;
            }
          }
        }
}
</style>