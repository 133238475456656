<template>
    <div class="aprender-programa-reacciona">
        <img src="@/assets/img/lupamin.png" alt="reacciona-participa-lupa-min">
        <h2>¿Qué podrás aprender en este programa?</h2>
        <div class="btn-content">
                    <button @click.prevent="gotonav('https://bit.ly/quiero-beca')">¡Quiero una Beca!</button>
                </div>
        <p>El programa ofrece herramientas y conceptos fundamentales para ejercer una vigilancia ciudadana efectiva. Aborda aspectos como la participación ciudadana, la transparencia, la gestión de riesgos y mecanismos prácticos para contribuir al cierre de brechas. Los participantes desarrollarán habilidades para promover de manera activa una gestión pública más transparente y eficiente.</p>
        <p>¿Qué contiene el programa?</p>

        <div class="grupo-content">
                <div class="grupo">
                    <h3>Participación Ciudadana y Transparencia</h3>
                    <p>Explora la importancia de la participación ciudadana  y la transparencia en la gestión pública.</p>
                </div>
                <div class="grupo m8">
                    <h3>Planificación Estratégica y Presupuesto Público</h3>
                    <p>Examina los Objetivos de Desarrollo Sostenible (ODS),  el sistema de planeamiento estratégico, el presupuesto del sector público y su cómo se vinculan.</p>
                </div>
                <div class="grupo">
                    <h3>Ciclo de la Inversión y Gestión de Riesgos</h3>
                    <p>Aprenderás sobre el ciclo de la inversión pública explorando los riesgos en la gestión de inversiones y la vigilancia de contrataciones.</p>
                </div>

        </div>
    </div>
</template>

<script>
    export default {
        name:"aprender-reacciona",
        methods: {
            gotonav(value) {
                window.location=value;
            },
        },
    }
</script>

<style lang="scss" scoped>
 .btn-content{
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 100%;
                    p{
                        text-align: left;
                        font-size: 18px;
                        font-weight: 700;
                        color: #481EFD;
                        font-family: "Roboto";
                        margin-left: 25px;
                        line-height: 21.09px;
                    }
                    button{
                        width: 215px;
                        background-color: #1C18F2;
                        border-radius: 16px;
                        padding: 16px, 44px, 16px, 44px;
                        color: #ffffff;
                        font-size: 16px;
                        font-weight: 500;
                        border: none;
                        height: 51px;
                  
                        cursor: pointer;
                    }
                }
@media only screen and (min-width: 601px) {
    .aprender-programa-reacciona{
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        margin-top: 80px;
        h2{
            font-size: 45px;
            font-weight: 700;
            font-family: "Bebas Neue";
            line-height: 45px;
            text-transform: uppercase;
            text-align: center;
            color: #313131;
            max-width: 379px;
        }
        p{
            text-align: center;
            font-size: 24px;
            line-height: 28.13px;
            font-weight: 500;
            font-family: "Roboto";
            max-width: 691px;
        }
        .grupo-content{
            display: flex;
            justify-content: space-evenly;
            width: 100%;
            .grupo{
                h3{
                    font-size: 20px;
                    color: #481EFD;
                    line-height: 23.44px;
                    font-weight: 700;
                    text-align: left;
                    font-family: "Roboto";
                    max-width: 217px;
                }
                p{
                    font-size: 14px;
                    color: #313131;
                    line-height: 16.41px;
                    font-weight: 400;
                    text-align: left;
                    font-family: "Roboto";
                    max-width: 254px;
                }
            }
            .grupo{
                h3{
                    font-size: 20px;
                    color: #481EFD;
                    line-height: 23.44px;
                    font-weight: 700;
                    text-align: left;
                    font-family: "Roboto";
                    max-width: 217px;
                }
                p{
                    font-size: 14px;
                    color: #313131;
                    line-height: 16.41px;
                    font-weight: 400;
                    text-align: left;
                    font-family: "Roboto";
                    max-width: 254px;
                }
            }
        }
    }
    .m8{margin-top: 80px;}

}
@media only screen and (max-width: 600px) {
    .m8{margin-top: 0px;}
    .aprender-programa-reacciona{
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        margin-top: 40px;
        h2{
            font-size: 45px;
            font-weight: 700;
            font-family: "Bebas Neue";
            line-height: 45px;
            text-transform: uppercase;
            text-align: center;
            color: #313131;
            width: 90%;
        }
        p{
            text-align: center;
            font-size: 24px;
            line-height: 28.13px;
            font-weight: 500;
            font-family: "Roboto";
            width: 90%;
        }
        .grupo-content{
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            width: 100%;
            .grupo{
                width: 90%;
                h3{
                    font-size: 20px;
                    color: #481EFD;
                    line-height: 23.44px;
                    font-weight: 700;
                    text-align: center;
                    font-family: "Roboto";
                    
                }
                p{
                    font-size: 14px;
                    color: #313131;
                    line-height: 16.41px;
                    font-weight: 400;
                    text-align: center;
                    font-family: "Roboto";
                    width: 100%;
                }
            }
         
        }
    }
}
</style>