import { createRouter, createWebHashHistory } from 'vue-router'

import Landing from "../views/landing/index.vue"
import Participa from "../views/participa/index.vue"
import Terminos from "../views/terminos-condiciones/index.vue"
import Cierre from "../views/user/index.vue"
import Politicas from "../views/politicas/index.vue";
import Navbar from "@/components/navbar/navbar.vue";
import NavbarInterno from "@/components/navbar/navbarInterno.vue";
import Sidebar from "@/components/Layouts/sidebar/sidebar.vue";

import blog1 from "@/views/blog/iniciativa-peruana.vue"
import blog2 from "@/views/blog/proyectos-descontinuados.vue"
import store from "@/store"
const routes = [
  {
    path: "/",
    name: "reacciona-landing",
    components: { default: Landing, sidebar:Sidebar, header: Navbar },
    meta: { Auth: false, title: 'Reacciona' },
  },
  {
    path: "/participa",
    name: "reacciona-participa",
    components: { default: Participa, header: NavbarInterno },
    meta: { Auth: false, title: 'Reacciona | Terminos y Condiciones' },
  },
  {
    path: "/terminos-condiciones",
    name: "reacciona-terminos",
    components: { default: Terminos, header: NavbarInterno },
    meta: { Auth: false, title: 'Reacciona | Terminos y Condiciones' },
  },
  {
    path: "/politicas-privacidad",
    name: "reacciona-politicas-privacidad",
    components: { default: Politicas, header: NavbarInterno },
    meta: { Auth: false, title: 'Reacciona | Politicas de Privacidad' },
  },
  {
    path: "/cierre-de-cuenta",
    name: "reacciona-cierre-de-cuenta",
    components: { default: Cierre, header: NavbarInterno },
    meta: { Auth: false, title: 'Reacciona | Cerrar Cuenta' },
  },
  {
    path: "/iniciativa-peruana",
    name: "iniciativa-peruana",
    components: { default: blog1, header: NavbarInterno },
    meta: { Auth: false, title: 'Reacciona | Blog' },
  },
  {
    path: "/proyectos-descontinuados",
    name: "proyectos-descontinuados",
    components: { default: blog2, header: NavbarInterno },
    meta: { Auth: false, title: 'Reacciona | Blog' },
  },
]

const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    return { top: 0 }
  },
})
export default router
