<template>
    <div class="container">   
        <Carousel :autoplay="4000">
                <Slide  v-for="sliden in Carusels" :key="sliden.index">
    
                    <div v-if="sliden.index===0" class="hero-content">
                        <h1>
                            ¡FORMA PARTE DEL <strong>CAMBIO!</strong>
                        </h1>
                        <div class="sub-text">
                            <span>REACCIONA: Transformando personas en ciudadanos vigilantes</span>
                            <p>Por que una ciudadanía activa es capaz de impulsar cambios y cerrar brechas</p>
                          
                        </div>
                        <div class="unete-content">
                            <button @click.prevent="participa()">Unirme al cambio</button>
                            <div class="redes-content">
                                <a href="https://www.linkedin.com/company/reacciona-pe/" target="_blank" rel="noopener noreferrer"><img src="@/assets/img/linkedin.svg" alt="twiter-rede"></a>
                                        <a href="https://twitter.com/ReAcciona_pe" target="_blank" rel="noopener noreferrer"><img src="@/assets/img/twiter.png" alt="twiter-rede"></a>
                                        <a href="https://www.facebook.com/share/W2EgWnjwhWtn9NNV/?mibextid=WC7FNe" target="_blank" rel="noopener noreferrer"><img src="@/assets/img/facebook.png" alt="twiter-rede"></a>
                                        <a href="https://www.instagram.com/re.accionape?igsh=eGppamhyeGxqa2Z1" target="_blank" rel="noopener noreferrer"><img src="@/assets/img/instagram.png" alt="twiter-rede"></a>
                            </div>
                        </div>
                    </div>
                 
                    <div v-if="sliden.index===1" class="hero-content2">
                        <iframe  src="https://www.youtube.com/embed/jsjrvFbHPT0" title="Somos Reacciona" frameBorder="0"   allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"  allowFullScreen></iframe>
                    </div>
                </Slide>
         
                <template #addons>
                        <Pagination />
                </template>
            </Carousel>
       
     
    </div>
     
 
    
</template>

<script>
import { Carousel, Slide, Pagination, Navigation } from 'vue3-carousel'
import store from "@/store/index"
    export default {
        name:"header-content",
        data() {
            return {
                Carusels: [
                    {
                        index: 0
                    },
                    {
                        index: 1
                    }
                ]
            }
        },
        methods: {
            gotonav(value) {
                window.location=value;
            },
            participa(){
                this.$router.push('/participa');
            },
            muypronto(){
                store.dispatch("showToast", {
                message: "Muy Pronto",
                type: "warning",
            });
            }
        },
        components: {
            Carousel,
            Slide,
            Pagination,
            Navigation
        },
    }
</script>

<style lang="scss" scoped>
 @import '@/assets/css/carusel.css';
@media only screen and (min-width: 601px) {
    
    .container{
          display: flex;
          align-items: center;
          justify-content: center;
          width: 100%;
          max-width: 1150px;
          margin: 0px auto 0px auto;
           .hero-content::before{
            content: "";
            background-image: url('@/assets/img/hero1.png');
            background-repeat: no-repeat;
            height: 393.64px;
            width: 431.11px;
            position: absolute;
            bottom: -16%;
            left: 5%;
           }
            .hero-content{
                height: 70vh;
                max-height: 564px;
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: column;
        
                h1{
                font-size: 45px;
                font-weight: 700;
                line-height: 45px;
                text-align: center;
                color: #313131;
                strong{
                    color: #FF0069;
                    font-size: 45px;
                    font-weight: 700;
                    line-height: 45px;
                }
            }
            .sub-text{
                display: flex;
                justify-content: center;
                flex-direction: column;
                align-items: center;
                p{
                    font-size: 23px;
                    font-weight: 600;
                    color: #313131;
                    width: 440px;
                    text-align: center;
                }
                span{
                    font-size: 14px;
                    color: #481EFD;
                    font-weight: 700;
                }
                strong{
                    color: #481EFD;
                }
            }
            .sub-text2{
                display: flex;
                justify-content: center;
                flex-direction: column;
                align-items: center;
                p{
                    font-size: 23px;
                    font-weight: 600;
                    color: #313131;
                    width: 530px;
                    text-align: center;
                }
                span{
                    font-size: 14px;
                    color: #481EFD;
                    font-weight: 700;
                }
                strong{
                    color: #481EFD;
                }
            }
            
            .unete-content{
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                button{
                    width: 215px;
                    background-color: #1C18F2;
                    border-radius: 16px;
                    padding: 16px, 44px, 16px, 44px;
                    color: #ffffff;
                    font-size: 16px;
                    font-weight: 500;
                    border: none;
                    height: 51px;
                    margin-top: 40px;
                    cursor: pointer;
                }
                .redes-content{
                    margin-top: 40px;
                    display: flex;
                    justify-content: center;
                    a{
                        margin-right: 15px;
                    }
                    a:last-of-type{
                        margin-right: 0px;
                    }
                }
            }
            }
            .hero-content::after{
            content: "";
            // background-image: url('@/assets/img/hero2.png');
            background-repeat: no-repeat;
            height: 393.64px;
            width: 431.11px;
            position: absolute;
            bottom: -17%;
            right: -3%;
           }
           .hero-content2::before{
            content: "";
            // background-image: url('@/assets/img/hero3.png');
            background-repeat: no-repeat;
            height: 393.64px;
            width: 431.11px;
            position: absolute;
            bottom: -16%;
            left: 0%;
           }
            .hero-content2{
                height: 70vh;
                max-height: 564px;
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: column;
        
                h1{
                font-size: 45px;
                font-weight: 700;
                line-height: 45px;
                text-align: center;
                color: #313131;
                strong{
                    color: #FF0069;
                    font-size: 45px;
                    font-weight: 700;
                    line-height: 45px;
                }
            }
            .sub-text{
                display: flex;
                justify-content: center;
                flex-direction: column;
                align-items: center;
                p{
                    font-size: 23px;
                    font-weight: 600;
                    color: #313131;
                    width: 440px;
                    text-align: center;
                }
                span{
                    font-size: 14px;
                    color: #481EFD;
                    font-weight: 700;
                }
                strong{
                    color: #481EFD;
                }
            }
            .sub-text2{
                display: flex;
                justify-content: center;
                flex-direction: column;
                align-items: center;
                p{
                    font-size: 23px;
                    font-weight: 600;
                    color: #313131;
                    width: 530px;
                    text-align: center;
                }
                span{
                    font-size: 14px;
                    color: #481EFD;
                    font-weight: 700;
                }
                strong{
                    color: #481EFD;
                }
            }
            
            .unete-content{
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                button{
                    width: 215px;
                    background-color: #1C18F2;
                    border-radius: 16px;
                    padding: 16px, 44px, 16px, 44px;
                    color: #ffffff;
                    font-size: 16px;
                    font-weight: 500;
                    border: none;
                    height: 51px;
                    margin-top: 40px;
                    cursor: pointer;
                }
                .redes-content{
                    margin-top: 40px;
                    display: flex;
                    justify-content: center;
                    a{
                        margin-right: 15px;
                    }
                    a:last-of-type{
                        margin-right: 0px;
                    }
                }
            }
            }
            .hero-content2::after{
            content: "";
            // background-image: url('@/assets/img/hero4.png');
            background-repeat: no-repeat;
            height: 393.64px;
            width: 431.11px;
            position: absolute;
            bottom: -17%;
            right: -7%;
           }
        
        }
       
    
    
 
        iframe{
    width: 100%;
    height: 100%;
}

}
@media only screen and (max-width: 600px) {
    .container{
          display: flex;
          align-items: center;
          justify-content: center;
          width: 100%;
          max-width: 1150px;
          margin: 0px auto 0px auto;
          .hero-content{
        height: 100vh;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
  
        h1{
        width: 225px;
        font-size: 40px;
        font-weight: 700;
        line-height: 45px;
        text-align: center;
        color: #313131;
        strong{
            color: #FF0069;
            font-size: 40px;
            font-weight: 700;
            line-height: 45px;
        }
    }
    .sub-text{
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        
        p{
            
            font-size: 20px;
            font-weight: 600;
            color: #313131;
            width: 308px;
            text-align: center;
        }
        span{
            font-size: 14px;
            color: #481EFD;
            font-weight: 700;
            width: 308px;
        }
        strong{
            color: #481EFD;
        }
    }
    .sub-text2{
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        
        p{
            
            font-size: 20px;
            font-weight: 600;
            color: #313131;
            width: 308px;
            text-align: center;
        }
        span{
            font-size: 14px;
            color: #481EFD;
            font-weight: 700;
            width: 308px;
        }
        strong{
            color: #481EFD;
        }
    }
    .unete-content{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        button{
            width: 215px;
            background-color: #1C18F2;
            border-radius: 16px;
            padding: 16px, 44px, 16px, 44px;
            color: #ffffff;
            font-size: 16px;
            font-weight: 500;
            border: none;
            height: 51px;
            margin-top: 40px;
            cursor: pointer;
        }
        .redes-content{
            margin-top: 40px;
            display: flex;
            justify-content: center;
            a{
                margin-right: 15px;
            }
            a:last-of-type{
                margin-right: 0px;
            }
        }
    }
            }
            .hero-content2{
        height: 100vh;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
  
        h1{
        width: 225px;
        font-size: 40px;
        font-weight: 700;
        line-height: 45px;
        text-align: center;
        color: #313131;
        strong{
            color: #FF0069;
            font-size: 40px;
            font-weight: 700;
            line-height: 45px;
        }
    }
    .sub-text{
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        
        p{
            
            font-size: 20px;
            font-weight: 600;
            color: #313131;
            width: 308px;
            text-align: center;
        }
        span{
            font-size: 14px;
            color: #481EFD;
            font-weight: 700;
            width: 308px;
        }
        strong{
            color: #481EFD;
        }
    }
    .sub-text2{
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        
        p{
            
            font-size: 20px;
            font-weight: 600;
            color: #313131;
            width: 308px;
            text-align: center;
        }
        span{
            font-size: 14px;
            color: #481EFD;
            font-weight: 700;
            width: 308px;
        }
        strong{
            color: #481EFD;
        }
    }
    .unete-content{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        button{
            width: 215px;
            background-color: #1C18F2;
            border-radius: 16px;
            padding: 16px, 44px, 16px, 44px;
            color: #ffffff;
            font-size: 16px;
            font-weight: 500;
            border: none;
            height: 51px;
            margin-top: 40px;
            cursor: pointer;
        }
        .redes-content{
            margin-top: 40px;
            display: flex;
            justify-content: center;
            a{
                margin-right: 15px;
            }
            a:last-of-type{
                margin-right: 0px;
            }
        }
    }
    }
        }
        iframe{
    width: 100%;
    height: 65%;
}
  
}

</style>