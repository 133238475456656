import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import PrimeVue from 'primevue/config';
import store from './store'
import ConfirmationService from 'primevue/confirmationservice';
import 'primevue/resources/themes/lara-light-green/theme.css'
import 'primeicons/primeicons.css'
import 'toastr/build/toastr.css';
createApp(App).use(store).use(router).use(PrimeVue).use(ConfirmationService).mount('#app')
