<template>
    <div class="container">
            <h2>tableros de seguimiento</h2>
            <div class="tableros-content">
                    <div class="tablero">
                        <p>¿De dónde proviene el presupuesto y cómo se está utilizando?</p>
                        <button @click.prevent="muypronto()">Sigue el Presupuesto</button>
                    </div>
                    <div class="tablero">
                        <p>¿Cuál es el estado los proyectos de inversión?</p>
                        <button @click.prevent="muypronto()">Sigue el Proyecto</button>
                    </div>
            </div>
    </div>
</template>

<script>
import store from "@/store/index"
    export default {
        name:"tableros-section",
        methods: {
            muypronto(){
                store.dispatch("showToast", {
                message: "Muy Pronto",
                type: "warning",
            });
            }
        },
    }
</script>

<style lang="scss" scoped>
@media only screen and (min-width: 601px) {
  
  .container{
          display: flex;
          align-items: center;
          justify-content: center;
          width: 100%;
          max-width: 1150px;
          margin: 0px auto 0px auto;
          flex-direction: column;
         
          h2{
                font-size: 45px;
                font-weight: 700;
                font-family: "Bebas Neue";
                text-transform: uppercase;
                text-align: center;
                width: 382px;
                color: #F1F1F1;
                margin-top: 80px;
          }
          .tableros-content{
            display: flex;
            justify-content: space-between;
            margin-top: 30px;
            .tablero:first-child{
                flex-basis: 50%;
                background-image: url(@/assets/img/financiero.png);
                background-repeat: no-repeat;
                background-position: center center;
                background-size: 100% 100%;
                padding-top: 30px;
                width: 523.41px;
                max-height: 218.79px;
                margin-right: 8px;
                display: flex;
                border-radius: 15px;
                justify-content: center;
                align-items: center;
                flex-direction: column;
                border-bottom: 5px solid #FF0069;
                button{
                    width: 215px;
                    height: 51px;
                    border-radius: 16px;
                    background-color: #FFD700;
                    color: #313131;
                    font-family: "Roboto";
                 
          
                }
                p{
                    font-size: 20px;
                    font-weight: 700;
                    font-family: "Roboto";
                    color: #ffffff;
                    text-align: center;
                    line-height: 23.44px;
                    width: 386px;

                }
            }
            .tablero{
                flex-basis: 50%;
                background-image: url(@/assets/img/fisico.png);
                background-repeat: no-repeat;
                background-position: center center;
                background-size: 100% 100%;
                width: 523.41px;
                height: 218.79px;
                margin-left: 8px;
                display: flex;
                border-radius: 15px;
                justify-content: center;
                align-items: center;
                padding-top: 30px;
                border-bottom: 5px solid #FF0069;
                flex-direction: column;
                button{
                    width: 215px;
                    height: 51px;
                    border-radius: 16px;
                    background-color: #FFD700;
                    color: #313131;
                    font-family: "Roboto";
                    margin-top: 10px;
                }
                p{
                    font-size: 20px;
                    font-weight: 700;
                    font-family: "Roboto";
                    color: #ffffff;
                    text-align: center;
                    line-height: 23.44px;
                    width: 386px;

                }
            }
          }
    }
}
@media only screen and (max-width: 600px) {
    .container{
          display: flex;
          align-items: center;
          justify-content: center;
           height: 100%;
          width: 100%;
          max-width: 600px;
          margin: 0px auto 0px auto;
          flex-direction: column;
          h2{
                font-size: 45px;
                font-weight: 700;
                font-family: "Bebas Neue";
                text-transform: uppercase;
                text-align: center;
                width: 100%;
                max-width: 284px;
                color: #F1F1F1;
                margin-top: 420px;
          }
          .tableros-content{
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            width: 95%;
            margin-top: 30px;
            .tablero:first-child{
                flex-basis: 100%;
                background-image: url(@/assets/img/financiero.png);
                background-repeat: no-repeat;
                background-position: center center;
                background-size: 100% 100%;
                width: 95%;
                border-radius: 15px;
                max-height: 218.79px;
                border-bottom: 5px solid #FF0069;
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;
                button{
                    width: 215px;
                    height: 51px;
                    border-radius: 16px;
                    background-color: #FFD700;
                    color: #313131;
                    font-family: "Roboto";
                    font-weight: 500;
                    line-height: 18.75px;
                    margin-bottom: 30px;
                    margin-top: 40px;
                }
                p{
                    margin-top: 40px;
                    font-size: 18px;
                    font-weight: 400;
                    font-family: "Roboto";
                    color: #D9D9D9;
                    text-align: center;
                    width: 95%;
                  

                }
    
            }
            .tablero{
                flex-basis: 100%;
                background-image: url(@/assets/img/fisico.png);
                background-repeat: no-repeat;
                background-position: 100% 100%;
                background-size: 100% 100%;
                border-bottom: 5px solid #FF0069;
                width: 95%;
                border-radius: 15px;
                margin-top: 40px;
                max-height: 218.79px;
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;
                button{
                    width: 215px;
                    height: 51px;
                    border-radius: 16px;
                    background-color: #FFD700;
                    color: #313131;
                    font-family: "Roboto";
                    font-weight: 500;
                    line-height: 18.75px;
                    margin-bottom: 30px;
                    margin-top: 40px;
                }
                p{
                    margin-top: 40px;
                    font-size: 18px;
                    font-weight: 400;
                    font-family: "Roboto";
                    color: #D9D9D9;
                    text-align: center;
                    width: 95%;
                    max-width: 294px;

                }

            }
          }
    }
}
</style>