<template>
    <div>
        <QueEs></QueEs>
        <Aprender></Aprender>
    </div>
</template>

<script>
import QueEs from './section/queEs.vue'
import Aprender from './section/aprender.vue'
    export default {
        name:"red-section",
        components: {
            QueEs,
            Aprender
        },
    }
</script>

<style lang="scss" scoped>

</style>