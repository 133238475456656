<template>
    <div :style="'background-image: url( '+ consultor.image + ');'" class="equipo-card">
        <div class="fondiado">
           <div class="info">
                <h6>{{consultor.name}}</h6>
                <p>{{ consultor.occupation }}</p>
           </div>
        </div>
    </div>
</template>

<script>
    export default {
        name:"equipo-card",
        props:["consultor"]
    }
</script>

<style lang="scss" scoped>
@media only screen and (min-width: 601px) {
    .equipo-card{
        height: 405px;
        width: 230px;
        background-color: #E9E9E9;
        background-repeat: no-repeat;
        background-size: 274px 100%;
        background-position: center center;
        .fondiado{
            display: none;
        }
      
    }
    .equipo-card:hover{
    
        .fondiado{
            display: block;
            height: 405px;
            width: 230px;
            background-color: #00ff99c8;
            .info{
                height: 100%;
                display: flex;
                justify-content: flex-end;
                align-items: center;
                flex-direction: column;
                width: 100%;
               
                h6{
                    margin: 0px;
                    color: #481EFD;
                    font-weight: bold;
                    font-size: 20px;
                    font-family: "Roboto";
                }
                p{
                    margin: 0px;
                    color: #481EFD;
                    font-weight: 400;
                    font-size: 14px;
                    font-family: "Roboto";
                    width: 95%;
                    margin-bottom: 15px;
                }
        
            }
        }
    }
}
@media only screen and (max-width: 601px) {
    .equipo-card{
        height: 325px;
        width: 95%;
        background-color: #E9E9E9;
        background-image: url(@/assets/img/equipo.png);
        background-repeat: no-repeat;
        background-size: 95% 325px;
        background-position: 0% 100%;
        margin-bottom: 10px;
        .fondiado{
            display: none;
        }
      
    }
    .equipo-card:hover{
    
        .fondiado{
            display: block;
            height: 325px;
            width: 100%;
            background-color: #00ff99c8;
            .info{
                height: 100%;
                display: flex;
                justify-content: flex-end;
                align-items: center;
                flex-direction: column;
                h6{
                    margin: 0px;
                    color: #481EFD;
                    font-weight: bold;
                    font-size: 16px;
                    font-family: "Roboto";
                }
                p{
                    margin: 0px;
                    color: #481EFD;
                    font-weight: 400;
                    font-size: 14px;
                    font-family: "Roboto";
                    width: 95%;
                    margin-bottom: 20px;
                }
        
            }
        }
    }
}
    
</style>