<template>
    <div>
        <div class="carusel-content">
            <carusel class="container"></carusel>
        </div>
        <div>
            <problema  class="container"></problema>
        </div>
    </div>
    
</template>

<script>
import carusel from './seccion/carusel/index.vue'
import problema from './seccion/problema/index.vue'
    export default {
        name:"problema-section",
        components: {
            carusel,
            problema
        },
    }
</script>

<style lang="scss" scoped>
@media only screen and (min-width: 601px) {
    .carusel-content{
        background-color: #00FF98;
        height: 652px;
    }
    .container{
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            max-width: 1150px;
            margin: 0px auto 0px auto;
      }
}
@media only screen and (max-width: 600px) {
    .carusel-content{
        background-color: #00FF98;
        height:100%;
        padding-bottom: 40px;
    }
    .container{
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            max-width: 600px;
            margin: 0px auto 0px auto;
      }
}

</style>