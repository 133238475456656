import authApis from "@/apis/auth/auth";
import { getToken, setToken, removeToken } from "@/utils/auth";
import router from "@/router";
export default {
    state: {
      user:null,
      token:getToken(),
    },
    actions: {
      DELETE_USER: (rootstate,data) => {
        return new Promise((resolve, reject) => {
            authApis
            .deleteUser(data)
            .then((response) => {
                console.log(response);
                if (response.data.success) {
                    rootstate.dispatch("showToast", {
                        message: "proceso realizado correctamente",
                        type: "success",
                    });
                    router.push("/");
                }else{
                    rootstate.dispatch("showToast", {
                        message: response.data.message,
                        type: "error",
                    });
                }
               
              resolve(response);
            })
            .catch((err) => {
              if (err) {
                reject(err);
              }
            });
        });
      },
      
   
     
    },
    mutations: {
      SET_USER: (state, data) => {
          state.user = data;
      },
    },
    getters: {
   
    }
  }
  