<template>
    <div class="participa-content">
        <div class="img-content">
            <div class="heading">
                <h2>¿Qué se espera lograr al término del programa?</h2>
            </div>
            <div class="body">
                <div class="grupo">
                   <ul>
                    <li>
                    Conocer sobre herramientas y mecanismos de participación ciudadana y transparencia.
                    </li>
                    <li>
                    Fortalecer habilidades para analizar y vigilar la inversión pública, identificando riesgos y promoviendo la rendición de cuentas para lograr el cierre de brechas.</li>
                    <li>
                    Capacidad para aplicar los conceptos y herramientas aprendidos en la práctica, contribuyendo al fortalecimiento de la gobernanza democrática y la transparencia en el ámbito público.
                    </li>
                   </ul>
                </div>
            </div>
        </div>
        <div class="info-content">
            <div class="heading">
                <h2>¿Cuáles son los requisitos?</h2>
            </div>
            <div class="body">
                <div class="grupo">
                   <ul>
                    <li>Tener entre 18 a 35 años y vivir en Arequipa/Cusco</li>
                    <li>Al menos 6 meses de experiencia profesional en todas las carreras; incluye prácticas preprofesionales y/o voluntariado.</li>
                    <li>Deseable contar con experiencia en el sector público y/o instituciones del sector privado que se relacionen con la gestión pública y/o participación dentro de la sociedad civil.</li>
                    <li>Contar con la disponibilidad de tiempo semanal (4 horas por semana) requerido para llevar el programa y compromiso de ser parte de la red vigilante de ReAcciona</li>
                   </ul>
                </div>
            </div>
            <div class="footer">
                <h3>¿El programa tiene algún costo?</h3>
                <p>EL programa tiene un <strong>costo de s/. 5000.00 soles</strong>, el mismo que será subvencionado por <strong>ReAcciona</strong> y la <strong>Universidad Continental</strong> otorgando 60 becas a jóvenes de la Región de Cusco y Arequipa</p>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name:"aprenderas-section"
}
</script>

<style lang="scss" scoped>

@media only screen and (min-width: 601px) {

      .participa-content{
        display: flex;
        align-items: center;
        width: 100%;
        margin-top: 70px;
        .img-content{
            flex-basis: 50%;
            height: 556px;
            width: 100%;
            border-top-left-radius: 30px;
            border-bottom-left-radius: 30px;
            background-color:#481EFD;
            .heading{
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin-top: 35px;
                padding-left: 30px;
                padding-right: 30px;
                h2{
                    font-size: 45px;
                    font-weight: 700;
                    font-family: "Bebas Neue";
                    line-height: 45px;
                    text-transform: uppercase;
                    text-align: left;
                    color: #ffffff;
                    max-width: 339px;
                }
             }
             .body{
           
                display: flex;
                justify-content: flex-start;
                align-items: flex-start;
                flex-direction: column;
                margin-top: 20px;
                .grupo{
                    display: flex;
                    ul{
                        li{
                    
                            text-align: left;
                            font-size: 14px;
                            line-height: 16.41px;
                            font-weight: 700;
                            font-family: "Roboto";
                            max-width: 455px;
                            margin-bottom: 20px;
                            color: #00FF98;
                   
                        }
                    }
                }
             }
        }
        .info-content{
            flex-basis: 50%;
            display: flex;
            justify-content: center;
            align-items: flex-start;
            flex-direction: column;
            background-color: #00FF98;
            border-radius: 30px;
            height: 558px;
            margin-left: -50px;
            .heading{
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin-top: 60px;
                padding-left: 30px;
                padding-right: 30px;
                h2{
                    font-size: 45px;
                    font-weight: 700;
                    font-family: "Bebas Neue";
                    line-height: 45px;
                    text-transform: uppercase;
                    text-align: left;
                    color: #481EFD;
                }
             }
             .body{
           
                display: flex;
                justify-content: flex-start;
                align-items: flex-start;
                flex-direction: column;
                .grupo{
                    display: flex;
                    ul{
                        li{
                    
                            text-align: left;
                            font-size: 14px;
                            line-height: 16.41px;
                            font-weight: 700;
                            font-family: "Roboto";
                            max-width: 455px;
                            margin-bottom: 20px;
                   
                        }
                    }
                }
             }
             .footer{
                padding-left: 30px;
                display: flex;
                justify-content: flex-start;
                flex-direction: column;
                padding-bottom: 50px;
                h3{
                    text-align: left;
                    font-size: 14px;
                    line-height: 16.41px;
                    font-weight: bold;
                    font-family: "Roboto";
                    margin-right: 5px;
                    color: #481EFD;
                }
                p{
                    text-align: left;
                    font-size: 14px;
                    line-height: 16.41px;
                    font-weight: 500;
                    font-family: "Roboto";
                    margin-right: 5px;
                    color: #481EFD;
                    max-width: 442px;
                    a{
                        margin-right: 10px;    
                    }                    
                }
             }
        }
      }
    

}
@media only screen and (max-width: 600px) {

    .participa-content{
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        width: 100%;
        margin-top: 20px;
        .img-content{
            flex-basis: 90%;
            height: 556px;
            width: 100%;
            border-top-left-radius: 30px;
            border-bottom-left-radius: 30px;
            background-color:#481EFD;
            padding-bottom: 40px;
            .heading{
                display: flex;
                justify-content:center;
                align-items: center;
                margin-top: 35px;
                h2{
                    font-size: 45px;
                    font-weight: 700;
                    font-family: "Bebas Neue";
                    line-height: 45px;
                    text-transform: uppercase;
                    text-align: center;
                    color: #ffffff;
                    width: 90%;
                }
             }
             .body{
                display: flex;
                justify-content: flex-start;
                align-items: flex-start;
                flex-direction: column;
                .grupo{
                    display: flex;
                    ul{
                        li{
                    
                            text-align: center;
                            font-size: 14px;
                            line-height: 16.41px;
                            font-weight: 700;
                            font-family: "Roboto";
                            max-width: 455px;
                            margin-bottom: 20px;
                            color: #00FF98;
                        }
                    }
                }
             }
        }
        .info-content{
            flex-basis: 100%;
            display: flex;
            justify-content: center;
            align-items: flex-start;
            flex-direction: column;
            background-color: #00FF98;
            border-radius: 30px;
            height: 558px;
            margin-top: -47px;
            .heading{
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin-top: 60px;
                h2{
                    font-size: 45px;
                    font-weight: 700;
                    font-family: "Bebas Neue";
                    line-height: 45px;
                    text-transform: uppercase;
                    text-align: center;
                    color: #481EFD;
                }
             }
             .body{
           
                display: flex;
                justify-content: flex-start;
                align-items: flex-start;
                flex-direction: column;
                .grupo{
                    display: flex;
                    ul{
                        li{
                    
                            text-align: left;
                            font-size: 14px;
                            line-height: 16.41px;
                            font-weight: 700;
                            font-family: "Roboto";
                            max-width: 455px;
                            margin-bottom: 20px;
                   
                        }
                    }
                }
             }
             .footer{
                padding-left: 30px;
                display: flex;
                justify-content: flex-start;
                flex-direction: column;
                padding-bottom: 50px;
                h3{
                    text-align: left;
                    font-size: 14px;
                    line-height: 16.41px;
                    font-weight: bold;
                    font-family: "Roboto";
                    margin-right: 5px;
                    color: #481EFD;
                }
                p{
                    text-align: left;
                    font-size: 14px;
                    line-height: 16.41px;
                    font-weight: 500;
                    font-family: "Roboto";
                    margin-right: 5px;
                    color: #481EFD;
                    max-width: 442px;
                    a{
                        margin-right: 10px;    
                    }                    
                }
             }
        }
      }
    }

</style>