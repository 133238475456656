<template>
    <div class="container">
        <div class="participa-content">
            <div class="img-content">
                <img src="@/assets/img/participa.png" alt="participa-img">
            </div>
            <div class="info-content">
                <div class="heading">
                    <h2>Participa</h2>
                    <img src="@/assets/img/lupa.png" alt="reacciona-app-lupa">
                </div>
                <div class="body">
                    <div class="grupo">
                            <img src="@/assets/img/check.svg" alt="check-1">
                        <p>Aplica a la beca de la Red Vigilante</p>
                    </div>
                    <div  class="grupo">
                        <img src="@/assets/img/check.svg" alt="check-2">
                        <p>ABC del ciudadano vigilante</p>
                    </div>
                    <div  class="grupo">
                        <img src="@/assets/img/check.svg" alt="check-3">
                        <p>Tutoriales: uso de la APP</p>
                    </div>
                </div>
                <div class="footer">
                    <p>Síguenos en nuestras redes: 
                        <a href="https://www.linkedin.com/company/reacciona-pe/" target="_blank" rel="noopener noreferrer"><img src="@/assets/img/linkedin.svg" alt="twiter-rede"></a>
                        <a href="https://twitter.com/ReAcciona_pe" target="_blank" rel="noopener noreferrer"><img src="@/assets/img/twiter.png" alt="twiter-rede"></a>
                        <a href="https://www.facebook.com/share/W2EgWnjwhWtn9NNV/?mibextid=WC7FNe" target="_blank" rel="noopener noreferrer"><img src="@/assets/img/facebook.png" alt="twiter-rede"></a>
                        <a href="https://www.instagram.com/re.accionape?igsh=eGppamhyeGxqa2Z1" target="_blank" rel="noopener noreferrer"><img src="@/assets/img/instagram.png" alt="twiter-rede"></a>
                    </p>
                </div>
            </div>

        </div>
    </div>
</template>

<script>
    export default {
        name:"participa-section"
    }
</script>

<style lang="scss" scoped>
@media only screen and (min-width: 601px) {
    .container{
          display: flex;
          align-items: center;
          justify-content: center;
          width: 100%;
          max-width: 1150px;
          margin: 0px auto 0px auto;
          .participa-content{
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 100%;
            .img-content{
                flex-basis: 35%;
              
                height: 100%;
                width: 100%;
                img{
                    height: 556px;
                    border-top-left-radius: 30px;
                    border-bottom-left-radius: 30px;
                }
            }
            .info-content{
                flex-basis: 65%;
                background-color: #00FF98;
                border-radius: 30px;
                height: 558px;
                margin-left: -50px;
                .heading{
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    margin-top: 60px;
                    padding-left: 30px;
                    padding-right: 30px;
                    h2{
                        font-size: 45px;
                        font-weight: 700;
                        font-family: "Bebas Neue";
                        line-height: 45px;
                        text-transform: uppercase;
                        text-align: left;
                        color: #481EFD;
                    }
                    img{
                        height: 152px;
                        width: 146px;
                    }
                 }
                 .body{
                    padding-left: 30px;
                    display: flex;
                    justify-content: flex-start;
                    align-items: flex-start;
                    flex-direction: column;
                    .grupo{
                        display: flex;
                        p{
                            margin-left: 5px;
                            text-align: left;
                            font-size: 14px;
                            line-height: 16.41px;
                            font-weight: 700;
                            font-family: "Roboto";
                        }
                    }
                 }
                 .footer{
                    padding-left: 30px;
                    display: flex;
                    justify-content: flex-start;
                    margin-top: 70px;
                    padding-bottom: 50px;
                    p{
                        text-align: left;
                        font-size: 14px;
                        line-height: 16.41px;
                        font-weight: 700;
                        font-family: "Roboto";
                        margin-right: 5px;
                        a{
                            margin-right: 10px;    
                        }                    
                    }
                 }
            }
          }
        }
  
}
@media only screen and (max-width: 600px) {
    .container{
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
          width: 100%;
          max-width: 600px;
          margin: 0px auto 0px auto;
          .participa-content{
            display: flex;
            justify-content: space-between;
            align-items: center;
            flex-direction: column;
            width: 100%;
            margin-top: 340px;
            .img-content{
                flex-basis: 100%;
               
                height: 100%;
                width: 100%;
                img{
                    width: 90%;
                    height: 358px;
                    border-top-left-radius: 30px;
                    border-bottom-left-radius: 30px;
                }
            }
            .info-content{
                flex-basis: 100%;
                background-color: #00FF98;
                border-radius: 30px;
                width: 90%;
                margin-top: -170px;
                height: 558px;
                .heading{
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    margin-top: 60px;
                    padding-left: 30px;
                    padding-right: 30px;
                    h2{
                        font-size: 45px;
                        font-weight: 700;
                        font-family: "Bebas Neue";
                        line-height: 45px;
                        text-transform: uppercase;
                        text-align: left;
                        color: #481EFD;
                    }
                    img{
                       display: none;
                    }
                 }
                 .body{
                    padding-left: 30px;
                    display: flex;
                    justify-content: flex-start;
                    align-items: flex-start;
                    flex-direction: column;
                    .grupo{
                        display: flex;
                        p{
                            text-align: left;
                            font-size: 14px;
                            line-height: 16.41px;
                            font-weight: 700;
                            font-family: "Roboto";
                        }
                    }
                 }
                 .footer{
                    padding-left: 30px;
                    display: flex;
                    justify-content: flex-start;
                    margin-top: 70px;
                    padding-bottom: 50px;
                    p{
                        text-align: left;
                            font-size: 14px;
                            line-height: 16.41px;
                            font-weight: 700;
                            font-family: "Roboto";
                    }
                 }
            }
          }
        }
}
</style>