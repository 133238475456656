<template>
  <div class="layout-grid">
      <router-view  :class="sidebar ? 'opened nav' : 'closed nav' " name="header" />
      <router-view name="sidebar" />
      <router-view />
 
  </div>
</template>
<script>
import {
  getToken,removeToken
} from '@/utils/auth'
import { mapState } from "vuex";
import store from './store'
export default {
  name: "Reacciona",
  mounted () {
    if (getToken()) {
      store.dispatch('REFRESH')
            .then(response => {
                console.log(response) 
            })
            .catch(error =>{
              removeToken();
              store.commit("SET_USER",  null);
              this.$router.push('/')
              console.log('error', error)});
    }
  },
  computed: {
          ...mapState({
              sidebar: (state) => state.sideBarStatus,
          }),
      },
}
</script>
<style lang="scss">
@import './assets/css/normaliza.css';
html{

}
#app {
font-family: Avenir, Helvetica, Arial, sans-serif;
-webkit-font-smoothing: antialiased;
-moz-osx-font-smoothing: grayscale;
text-align: center;
color: #2c3e50;
background-color: #F1F1F1;

}


@media only screen and (min-width: 601px) {
  .layout-grid{
width: 1150px;
margin: auto;
.nav{
  position: absolute;
  left: 0%;
  width: 100%;
}
.footer-layout{
  display: block;
}
}
}
@media only screen and (max-width: 600px) {
  .layout-grid{
width: 100%;
max-width: 600px;
margin: auto;
.nav{
  position: absolute;
  left: 0%;
  width: 100%;
}
.footer-layout{
  display: block;
}
}
}


</style>
