<template>
    <div class="container">
        <!-- <h2>EL EQUIPO</h2>
        <div class="elipsi"></div> -->
        <!-- <div class="equipo-content">
            <div class="equipo">
                <equipoCard></equipoCard>
            </div>
            <div class="equipo">
                <equipoCard></equipoCard>
            </div>
            <div class="equipo">
                <equipoCard></equipoCard>
            </div>
            <div class="equipo">
                <equipoCard></equipoCard>
            </div>

        </div> -->
        <!-- <div class="elipsi-2"></div> -->
   
    </div>
</template>

<script>
import equipoCard from '@/components/cards/equipoCard.vue'
    export default {
        name:"equipo-component",
        components: {
            equipoCard,
        },
    }
</script>

<style lang="scss" scoped>
@media only screen and (min-width: 601px) {
    .container{
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
          width: 100%;
          max-width: 1150px;
          margin: 0px auto 0px auto;
          h2{
            font-size: 45px;
            font-weight: 700;
            font-family: "Bebas Neue";
            line-height: 45px;
            text-transform: uppercase;
            text-align: center;
            margin: 0px;
            z-index: 1;
          }
          .elipsi{
            background: #ffffff;
            width: 1250px;
            height: 179px;
            border-radius: 50%;
            z-index: 0;
            margin-top: -80px;
       
          }
          .equipo-content{
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 100%;
            margin-top: -100px;
            .equipo{
                flex-basis: 25%;
                display: flex;
                justify-content: center;
                margin-right: 10px;
              
            }
          }
          .elipsi-2{
            background: #ffffff;
            width: 1350px;
            height: 279px;
            border-radius: 50%;
            z-index: 1;
            margin-top: -80px;
          }
   
    }
}
@media only screen and (max-width: 600px) {
    .container{
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
          width: 100%;
          max-width: 600px;
          margin: 0px auto 0px auto;
          h2{
            font-size: 45px;
            font-weight: 700;
            font-family: "Bebas Neue";
            line-height: 45px;
            text-transform: uppercase;
            text-align: center;
            margin: 0px;
            margin-bottom: 40px;
            z-index: 1;
          }
          .elipsi{
           display: none;
       
          }
          .equipo-content{
            display: flex;
            justify-content: space-between;
            align-items: center;
            flex-wrap:wrap;
            width: 100%;
            .equipo{
                flex-basis: 50%;
                display: flex;
                justify-content: center;
            }
          }
          .elipsi-2{
            display: none;
          }
   
    }
}
</style>