<template>
    <div class="equipo-card">
        <div class="fondiado">
           <div class="info">
                <h6>Gabriela Obregón</h6>
                <p>Jefa de proyecto</p>
           </div>
        </div>
    </div>
</template>

<script>
    export default {
        name:"equipo-card"
    }
</script>

<style lang="scss" scoped>
@media only screen and (min-width: 601px) {
    .equipo-card{
        height: 605px;
        width: 290px;
        background-color: #E9E9E9;
        background-image: url(@/assets/img/equipo.png);
        background-repeat: no-repeat;
        background-size: 290px 575px;
        background-position: 0% 100%;
        .fondiado{
            display: none;
        }
      
    }
    .equipo-card:hover{
    
        .fondiado{
            display: block;
            height: 605px;
            width: 290px;
            background-color: #471efdb5;
            .info{
                height: 100%;
                display: flex;
                justify-content: flex-end;
                align-items: flex-start;
                flex-direction: column;
                margin-left: 30px;
               
                h6{
                    margin: 0px;
                    color: #F1F1F1;
                    font-weight: bold;
                    font-size: 20px;
                    font-family: "Roboto";
                }
                p{
                    margin: 0px;
                    color: #F1F1F1;
                    font-weight: 400;
                    font-size: 14px;
                    font-family: "Roboto";
                    margin-bottom: 100px;
                }
        
            }
        }
    }
}
@media only screen and (max-width: 601px) {
    .equipo-card{
        height: 325px;
        width: 95%;
        background-color: #E9E9E9;
        background-image: url(@/assets/img/equipo.png);
        background-repeat: no-repeat;
        background-size: 95% 325px;
        background-position: 0% 100%;
        margin-bottom: 10px;
        .fondiado{
            display: none;
        }
      
    }
    .equipo-card:hover{
    
        .fondiado{
            display: block;
            height: 325px;
            width: 100%;
            background-color: #471efdb5;
            .info{
                height: 100%;
                display: flex;
                justify-content: flex-end;
                align-items: flex-start;
                flex-direction: column;
                margin-left: 30px;
               
                h6{
                    margin: 0px;
                    color: #F1F1F1;
                    font-weight: bold;
                    font-size: 20px;
                    font-family: "Roboto";
                }
                p{
                    margin: 0px;
                    color: #F1F1F1;
                    font-weight: 400;
                    font-size: 14px;
                    font-family: "Roboto";
                    margin-bottom: 100px;
                }
        
            }
        }
    }
}  
</style>