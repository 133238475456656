<template>
    <div class="container">
        <div class="info-somos">
      
            <p>Nuestra herramienta más poderosa: La ciudadanía.</p>
            <p>Tu, yo, todos juntos.</p>

        </div>
        <div class="video-content">
            <iframe  src="https://www.youtube.com/embed/3Ca9ytEZ6-k" title="Somos Reacciona" frameBorder="0"   allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"  allowFullScreen></iframe>
        </div>

    </div>
</template>

<script>
    export default {
        name:"como-section"
    }
</script>

<style lang="scss" scoped>
@media only screen and (min-width: 601px) {
  .container{
          display: flex;
          width: 100%;
          max-width: 1150px;
          margin: 0px auto 0px auto;
          height: 100%;
          .info-somos{
            flex-basis: 30%;
            background-color: #00FF98;
            height: 315px;
            width: 372px;
            border-radius: 28px;
            margin-top: -150px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            h2{
                font-size: 45px;
                font-weight: 700;
                font-family: "Bebas Neue";
                line-break: 45px;
                text-transform: uppercase;
                text-align: left;
                margin-left: 45px;
                width: 490px;
                color: #481EFD;
            }
            p:first-of-type {
                margin-left: 45px;
                text-align: left;
                font-size: 24px;
                font-weight: 700;
                color: #313131;
                font-family: "Roboto";
                width: 241px;
            }
            p{
                margin-left: 45px;
                color: #481EFD;
                width: 241px;
                text-align: left;
                font-size: 24px;
                font-weight: 700;
                margin-top: 20px;
                font-family: "Roboto";
                width: 542px;
            }
          }
          .video-content{
            flex-basis: 70%;
            max-width: 790px;
            margin-top: 120px;
            margin-left: -30px;
            iframe{
                width: 100%;        
                height: 487px;
                border-radius: 32px;
            }
          }
    }
}
@media only screen and (max-width: 600px) {
    .container{
          display: flex;
          flex-direction: column;
          width: 100%;
          max-width: 600px;
          margin: 0px auto 0px auto;
          height: 100%;
          .info-somos{
            flex-basis: 100%;
            background-color: #00FF98;
            height: 351px;
            width: 95%;
            padding-bottom: 40px;
            border-top-left-radius: 28px;
            border-top-right-radius: 28px;
            margin-top: 0px;
            h2{
                font-size: 45px;
                font-weight: 700;
                font-family: "Bebas Neue";
                line-break: 45px;
                text-transform: uppercase;
                text-align: left;
                margin-left: 15px;
                width: 100%;
                color: #481EFD;
            }
            p:first-of-type {
                margin-left: 15px;
                text-align: left;
                font-size: 20px;
                font-weight: 700;
                color: #313131;
                font-family: "Roboto";
                width: 100%;
                max-width: 241px;
            }
            p{
                margin-left: 15px;
                color: #481EFD;
                width: 100%;
                text-align: left;
                font-size: 20px;
                font-weight: 700;
                font-family: "Roboto";
                max-width: 241px;
              
            }
          }
          .video-content{
            flex-basis: 100%;
            width: 100%;
            margin-top: -25px;
            iframe{
                width:95%;        
                height: 254px;
                border-radius: 32px;
            }
          }
    }
}
</style>