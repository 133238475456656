<template>
    <div class="nav">
       <div class="nav-inferior">
            <div class="container">
                <div class="brand">
                        <div class="logo">
                            <router-link to="/">
                                <img src="../../assets/Logo.svg" alt="logo-ferre">
                            </router-link>
                        </div>
                </div>
            </div>
       </div>
    </div>
</template>
<script>
import store from "../../store"

import { mapState } from "vuex";
export default {
  name: "NavbarComponemtInterno",
  data() {
    return {
        dropdown_items: [
            {url:null, name:'Cerrar Sessión', type:"action", method:"logout()", icon:`<svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="M200-120q-33 0-56.5-23.5T120-200v-560q0-33 23.5-56.5T200-840h280v80H200v560h280v80H200Zm440-160-55-58 102-102H360v-80h327L585-622l55-58 200 200-200 200Z"/></svg>`},
        ],
    }
  },
  computed: {
        ...mapState({
            user: (state) => state.AUTH.user,
        }),
  },
  methods: {
    sidebarOpened() {
        store.dispatch("SIDEBAR_STATUS",true)
    },
    gotoScroll(value){
      const el = document.getElementById(value);
      el.scrollIntoView({behavior: "smooth"});
    }
  },
  components: {
  },
};
</script>
 <!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@media only screen and (min-width: 601px) {
    .nav{
    position: fixed!important;
    top: 0%;
    background-color: #ffffff;
    width: 100%;
    z-index: 2;
    .nav-inferior{
        height: 102px;
      
        display: flex;
        align-items: center;
        width: 100%;
        .container{
            display: flex;
            align-items: center;
            justify-content: center;
            width: 1150px;
            margin: 0px auto;
            .brand{
            flex-basis: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            .logo{
               
                a{
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    color: #FFF;
                    font-family: Inter;
                    font-size: 24px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: normal;
                    text-decoration: none;
                 
                }   
            }
            }
         
        
        }
      
    }
}
}





@media only screen and (max-width: 600px) {
    .nav{
    position: fixed!important;
    top: 0%;
    background-color: #ffffff;
    width: 100%;
    z-index: 2;
    .nav-inferior{
        height: 102px;
      
        display: flex;
        align-items: center;
        width: 100%;
        .container{
            display: flex;
            align-items: center;
            justify-content: center;
            width: 600px;
            margin: 0px auto;
            .brand{
            flex-basis: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            .logo{
               
                a{
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    color: #FFF;
                    font-family: Inter;
                    font-size: 24px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: normal;
                    text-decoration: none;
                 
                }   
            }
            }
         
        
        }
      
    }
}
}






</style>
  
