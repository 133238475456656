<template>
           <div class="carusel__chica">
                            <div class="info">
                                <h2>
                                    {{ slidem.title }}
                                </h2>
                                <h2 class="title_2">
                                    {{ slidem.title_2 }}
                                </h2>
                                <h2 class="title_3">
                                    {{ slidem.title_3 }}
                                </h2>
                                <h2 class="title_4">
                                    {{ slidem.title_4 }}
                                </h2>
                                <h2 class="title_5">
                                    {{ slidem.title_5 }}
                                </h2>
                                <div class="img-movil">
                                <img src="@/assets/img/chica.png" alt="slider-reacciona-img">
                                </div>
                        
                            </div>
                            <div class="img">
                                <img src="@/assets/img/chica.png" alt="slider-reacciona-img">
                            </div>
            </div>
</template>

<script>
    export default {
        name:"chica-card",
        props:['slidem']
    }
</script>

<style lang="scss" scoped>
@media only screen and (min-width: 601px) {
    .carusel__chica{
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: 100%;
    .info{
        flex-basis: 60%;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        flex-direction: column;
        width: 567px;
        .img-movil{
            display: none;
        }
        h2{
            font-size: 45px;
            line-height: 45px;
            color: #313131;
            text-transform: uppercase;
            text-align: left;
            font-weight: 700;
           
            font-family: "Bebas Neue";
            margin:90px 0px 0px 0px;
        }
        .title_2{
            margin: 0px;
            font-size: 45px;
            line-height: 45px;
            color: #481EFD;
            text-transform: uppercase;
            text-align: left;
            font-weight: 700;
            font-family: "Bebas Neue";
            margin:0px 0px 0px 0px;
            .strong_1{
                font-size: 45px;
                line-height: 45px;
                color: #481EFD;
            }
            .strong_2{
                font-size: 45px;
                line-height: 45px;
                color: #313131;
            }
            .strong_3{
                font-size: 45px;
                line-height: 45px;
                color: #481EFD;
            }
            .strong_4{
                font-size: 45px;
                line-height: 45px;
                color: #FF0069;
            }
        }
        .title_3{
            margin: 0px;
            font-size: 45px;
            line-height: 45px;
            color: #313131;
            text-transform: uppercase;
            text-align: left;
            font-weight: 700;
            font-family: "Bebas Neue";
            margin:0px 0px 0px 0px;
            .strong_1{
                font-size: 45px;
                line-height: 45px;
                color: #481EFD;
            }
            .strong_2{
                font-size: 45px;
                line-height: 45px;
                color: #313131;
            }
            .strong_3{
                font-size: 45px;
                line-height: 45px;
                color: #481EFD;
            }
            .strong_4{
                font-size: 45px;
                line-height: 45px;
                color: #FF0069;
            }
        }
        .title_4{
            margin: 0px;
            font-size: 45px;
            line-height: 45px;
            color: #481EFD;
            text-transform: uppercase;
            text-align: left;
            font-weight: 700;
            font-family: "Bebas Neue";
            margin:0px 0px 0px 0px;
            .strong_1{
                font-size: 45px;
                line-height: 45px;
                color: #481EFD;
            }
            .strong_2{
                font-size: 45px;
                line-height: 45px;
                color: #313131;
            }
            .strong_3{
                font-size: 45px;
                line-height: 45px;
                color: #481EFD;
            }
            .strong_4{
                font-size: 45px;
                line-height: 45px;
                color: #FF0069;
            }
        }
        .title_5{
            margin: 0px;
            font-size: 45px;
            line-height: 45px;
            color: #FF0069;
            text-transform: uppercase;
            text-align: left;
            font-weight: 700;
            font-family: "Bebas Neue";
            margin:0px 0px 0px 0px;
            .strong_1{
                font-size: 45px;
                line-height: 45px;
                color: #481EFD;
            }
            .strong_2{
                font-size: 45px;
                line-height: 45px;
                color: #313131;
            }
            .strong_3{
                font-size: 45px;
                line-height: 45px;
                color: #481EFD;
            }
            .strong_4{
                font-size: 45px;
                line-height: 45px;
                color: #FF0069;
            }
        }
            
  
        }
    .img{
        flex-basis: 40%;
        display: flex;
        justify-content: center;
        align-items: flex-end;
        width: 100%;
        height: 100%;
        margin-top: 20px;
        img{
            margin-bottom: 0px;
            height: 500px;
        }
    }
    }

}
@media only screen and (max-width: 600px) {
    .carusel__chica{
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    width: 100%;
    height: 100%;
    .info{
        flex-basis: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        width: 323px;
        h2{
            font-size: 24px;
            line-height: 28.13px;
            color: #313131;
            text-transform: uppercase;
            text-align: center;
            font-weight: 700;
           
            font-family: "Roboto";
            margin:40px 0px 0px 0px;
        }
        .title_2{
            margin: 0px;
            font-size: 24px;
            line-height: 28.13px;
            color: #481EFD;
            text-transform: uppercase;
            text-align: center;
            font-weight: 700;
            font-family: "Roboto";
            margin:10px 0px 0px 0px;
            .strong_1{
                font-size: 24px;
                line-height: 28.13px;
                color: #481EFD;
            }
            .strong_2{
                font-size: 24px;
                line-height: 28.13px;
                color: #313131;
            }
            .strong_3{
                font-size: 24px;
             line-height: 28.13px;
                color: #481EFD;
            }
            .strong_4{
                font-size: 24px;
                line-height: 28.13px;
                color: #FF0069;
            }
        }
        .title_3{
            margin: 0px;
            font-size: 24px;
            line-height: 28.13px;
            color: #313131;
            text-transform: uppercase;
            text-align: center;
            font-weight: 700;
            font-family: "Roboto";
            margin:10px 0px 0px 0px;
            .strong_1{
                font-size: 24px;
                line-height: 28.13px;
                color: #481EFD;
            }
            .strong_2{
                font-size: 24px;
                line-height: 28.13px;
                color: #313131;
            }
            .strong_3{
                font-size: 24px;
             line-height: 28.13px;
                color: #481EFD;
            }
            .strong_4{
                font-size: 24px;
                line-height: 28.13px;
                color: #FF0069;
            }
        }
        .title_4{
            margin: 0px;
            font-size: 24px;
            line-height: 28.13px;
            color: #481EFD;
            text-transform: uppercase;
            text-align: center;
            font-weight: 700;
            font-family: "Roboto";
            margin:10px 0px 0px 0px;
            .strong_1{
                font-size: 24px;
                line-height: 28.13px;
                color: #481EFD;
            }
            .strong_2{
                font-size: 24px;
                line-height: 28.13px;
                color: #313131;
            }
            .strong_3{
                font-size: 24px;
             line-height: 28.13px;
                color: #481EFD;
            }
            .strong_4{
                font-size: 24px;
                line-height: 28.13px;
                color: #FF0069;
            }
        }
        .title_5{
            margin: 0px;
            font-size: 24px;
            line-height: 28.13px;
            color: #FF0069;
            text-transform: uppercase;
            text-align: center;
            font-weight: 700;
            font-family: "Roboto";
            margin:10px 0px 0px 0px;
            .strong_1{
                font-size: 24px;
                line-height: 28.13px;
                color: #481EFD;
            }
            .strong_2{
                font-size: 24px;
                line-height: 28.13px;
                color: #313131;
            }
            .strong_3{
                font-size: 24px;
             line-height: 28.13px;
                color: #481EFD;
            }
            .strong_4{
                font-size: 24px;
                line-height: 28.13px;
                color: #FF0069;
            }
        }
        .img-movil{
            img{
            width: 219px;
            height: 263px;
            }    
        }
  
        }
        .img{
        display: none;
        }
    }
}
  
</style>