/*eslint-disable */
import axios from 'axios'
import store from '@/store'
import { getToken } from '@/utils/auth'
// create an axios instance
const service = axios.create({
    baseURL: process.env.VUE_APP_RUTA_API, // api 的 base_url
    timeout: 120000 // request timeout
})
// request interceptor
service.interceptors.request.use(
    config => {
        config.headers['Content-type'] = 'application/json;'
        config.headers['Authorization'] = 'Bearer ' + getToken()
        return config
    },
    error => {
        // Do something with request error
        Promise.reject(error)
    }
)
service.interceptors.response.use(
    response => response,   
    error => {
        if (error.response.status===419) {
            store.dispatch('showToast', {
                message: error.response.data,
                type: "error"
            })
        }else{
            store.dispatch('showToast', {
                message: error.response.data.message,
                type: "error"
            })
        }
        
        return Promise.reject(error)
    }
)
export default service
