<template>
       <div class="carretera_card">
                        <div class="info">
                            <img src="@/assets/img/lupamin.png" alt="slider-reacciona-img-min">
                            <h2>
                                <strong>{{ slidem.strong_1 }}</strong>
                                <strong>{{ slidem.strong_2 }}</strong>
                                {{ slidem.title }}
                            </h2>
                            <div class="img-movil">
                                <img src="@/assets/img/carretera.png" alt="slider-reacciona-img">
                            </div>
                            <p v-if="slidem.description">{{ slidem.description }}</p>
                            <div v-else>
                                <h2>{{ slidem.title_2 }}</h2>
                                <div class="text-content">
                                    <strong class="text-1">{{ slidem.strong_3 }}</strong>
                                <strong class="text-2">{{ slidem.strong_4 }}</strong>
                                <strong class="text-3">{{ slidem.strong_5 }}</strong>
                                </div>
                           
                            </div>
                        </div>
                        <div class="img">
                            <img src="@/assets/img/carretera.png" alt="slider-reacciona-img">
                        </div>
      </div>
</template>

<script>
    export default {
        name:"carretera-card",
        props:['slidem']
    }
</script>

<style lang="scss" scoped>
@media only screen and (min-width: 601px) {
    .text-content{
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        .text-1{
        font-size: 45px;
            line-height: 45px;
            margin: 0px;
            color: #481EFD;
            text-transform: uppercase;
            text-align: left;
            font-weight: 700;
            font-family: "Bebas Neue";
    }
    .text-2{
        font-size: 45px;
            line-height: 45px;
            margin: 0px;
            color: #FF0069;
            text-transform: uppercase;
            text-align: left;
            font-weight: 700;
            font-family: "Bebas Neue";
    }
    .text-3{
        font-size: 45px;
            line-height: 45px;
            margin: 0px;
            color: #481EFD;
            text-transform: uppercase;
            text-align: left!important;
            font-weight: 700;
            font-family: "Bebas Neue";
    }
    }
 
    .carretera_card{
        display: flex;
        justify-content: space-between;
        width: 100%;
        height: 100%;
    .info{
        flex-basis: 70%;
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        flex-direction: column;
        img{
            margin-top: 50px;
            img{
                height: 96px;
                width: 92px;
            }
        }
        .img-movil{
            display: none;
        }
        h2{
            font-size: 45px;
            line-height: 45px;
            margin: 0px;
            color: #313131;
            text-transform: uppercase;
            text-align: left;
            font-weight: 700;
            margin-top: 40px;
            width: 618px;
            font-family: "Bebas Neue";
            strong{
                font-size: 45px;
                line-height: 45px;
                color: #481EFD;
            }
        }
        p{
            font-family: "Roboto";
            margin-top: 40px;
            color: #313131;
            font-size: 24px;
            font-weight: 500;
            text-align: left;
            width: 646px;
        }
    }   
    .img{
        flex-basis: 30%;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
        margin-top: 30px;
        img{
            width: 439px;
            height: 387px;
        }
      
    }
    
  }

}
@media only screen and (max-width: 600px) {
    .carretera_card{
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        width: 100%;
        height: 100%;
    .info{
        flex-basis: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        img{
            margin-top: 50px;
            img{
                height: 96px;
                width: 92px;
            }
        }
        .img-movil{
            img{
            width: 219px;
            height: 193px;
        }   
        }
        h2{
            font-size: 24px;
            line-height: 28.13px;
            margin: 0px;
            color: #313131;
            text-transform: uppercase;
            text-align: center;
            font-weight: 700;
            margin-top: 40px;
            width: 323px;
            font-family: "Roboto";
            strong{
                font-size: 24px;
                line-height: 28.13px;
                color: #481EFD;
            }
        }
        p{
            font-family: "Roboto";
            margin-top: 40px;
            color: #313131;
            font-size: 14px;
            font-weight: 700;
            text-align: left;
            width: 314px;
        }
    }   
    .img{
        display: none;
    }
    
  }
}
   
</style>