<template>
    <div class="hero-background">
        <div id="inicio" class="container">
            <hero></hero>

        </div>
        <div class="sub-hero">
            <subhero></subhero>
        </div>
        <div id="problema" class="problema-background">
            <div class="radio-background">
                <problema></problema>
            </div>
        </div>
        <div id="involucra" class="involucra-content">
            <involucra></involucra>
        </div>
        <div id="somos" class="somos-content">
            <somos></somos>
        </div>
        <div id="como" class="como-content">
            <como></como>
        </div>
        <div id="aporte" class="aporte-content">
            <aporte></aporte>
        </div>
        <div id="tableros" class="tableros-content">
            <tableros></tableros>
        </div>
        <div id="participa" class="participa-content">
            <participa></participa>
        </div>

        <div id="nosotros" class="nosotros-content">
            <nosotros></nosotros>
        </div>
        <div id="equipo" class="equipo-content">
            <equipo></equipo>
        </div>
        <div id="consultivo" class="consultor-content">
            <consultivo></consultivo>
        </div>
        <div id="aliados" class="aliado-content">
            <aliados></aliados>
        </div>
        
        <div id="publicaciones" class="publicaciones-content">
            <!-- <publicaciones></publicaciones> -->
            <vigilante id="vigilante"></vigilante>
        </div>
        <div class="footer-content">
            <footerComp></footerComp>
        </div>
    </div>
  
  
</template>

<script>
import hero from './section/hero/index.vue'
import subhero from './section/subhero/index.vue'
import problema from './section/problema/index.vue'
import involucra from './section/involucrarnos/index.vue'
import somos from './section/somos/index.vue'
import como from './section/como/index.vue'
import aporte from './section/aporte/index.vue'
import tableros from './section/tableros/index.vue'
import participa from './section/participa/index.vue'
import nosotros from './section/nosotros/index.vue'
import equipo from './section/equipo/index.vue'
import consultivo from './section/consultivo/index.vue'
import aliados from './section/aliados/index.vue'
import publicaciones from './section/publicaciones/index.vue'
import vigilante from './section/vigilante/index.vue'
import footerComp from '@/components/Layouts/footer/footer.vue'
    export default {
        name:"reacciona-landing",
        components: {
            hero,
            subhero,
            problema,
            involucra,
            somos,
            como,
            aporte,
            tableros,
            participa,
            nosotros,
            equipo,
            consultivo,
            aliados,
            publicaciones,
            vigilante,
            footerComp
        },
    }
</script>

<style lang="scss" scoped>
@media only screen and (min-width: 601px) {
    .container{
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            max-width: 1150px;
            margin: 75px auto 0px auto;
      }
    .hero-background{
        position: absolute;
        top: 0%;
        left: 0%;
        background-image: url(../../assets/img/fondo.png);
        background-repeat: no-repeat;
        background-size: cover;
        height: 70vh;
        width: 100%;
 
        .sub-hero{
         
            background-color:#F1F1F1;
            height: 255px;
        }
    }
    .problema-background{
        background: radial-gradient(circle, rgba(255,215,0,1) 0%, rgba(9,9,121,0) 70%);

        width: 100%;
        .radio-background{
            background-image: url(../../assets/img/circulos.png);
            background-repeat: no-repeat;
            background-size: cover;
            background-position: center center;
          
        }
    }
    .tableros-content{
        background-image: url(@/assets/img/fondo3.png);
        background-repeat: no-repeat;
        background-size: 100% 100%;
        width: 100%;
        height: 449px;
        background-color: #FF0069;
        margin-top: 140px;
    }
    .participa-content{
        margin-top: 140px;
    }
    .nosotros-content{
        margin-top: 140px;
    }
    .equipo-content{
        margin-top: 140px;
    }
    .consultor-content{
        margin-top: 140px;
    }
    .aliados-content{
        margin-top: 140px;
    }
    .publicaciones-content{
        margin-top: 0px;
    }
    .footer-content{
        margin-top: 140px;
    }
}
@media only screen and (max-width: 600px) {
    .container{
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            max-width: 600px;
            margin: 75px auto 0px auto;
      }
    .hero-background{
        position: absolute;
        top: 0%;
        left: 0%;
        background-image: url(../../assets/img/fondo.png);
        background-repeat: no-repeat;
        background-size: cover;
        height: 70vh;
        width: 100%;
 
        .sub-hero{
         
            background-color:#F1F1F1;
            height: 65px;
        }
    }
    .problema-background{
        background: radial-gradient(circle, rgba(255,215,0,1) 0%, rgba(9,9,121,0) 70%);

        width: 100%;
        .radio-background{
            background-image: url(../../assets/img/circulos.png);
            background-repeat: no-repeat;
            background-size: cover;
            background-position: right center;
          
        }
    }
    .tableros-content{
        background-image: url(@/assets/img/fondo3.png);
        background-repeat: no-repeat;
        background-size: 100% 100%;
        width: 100%;
        height: 356px;
        background-color: #FF0069;
        margin-top: 100px;
        
    }
    .participa-content{
        margin-top: 140px;
    }
    .nosotros-content{
        margin-top: 140px;
    }
    .equipo-content{
        margin-top: 70px;
    }
    .aliados-content{
        margin-top: 70px;
    }
    .publicaciones-content{
        margin-top: 70px;
    }
    .footer-content{
        margin-top: 70px;
    }
}


 
</style>