<template>
    <div class="container">     
        <h2>Entonces,<br> ¿Por qué no nos involucramos?</h2>
        <div class="invo-content">
            <div class="card-1">
                <div class="entrella">
                    <strong>1</strong>
                </div>
                <p>Bases de datos dispersas y poco amigables para la ciudadanía en general.</p>
            </div>
            <div class="card-2">
                <div class="entrella">
                    <strong>2</strong>
                </div>
                <p>El lenguaje es complejo y confuso si no somos expertos.</p>
            </div>
            <div class="card-3">
                <div class="entrella">
                    <strong>3</strong>
                </div>
                <p>Todo esto,
                    <strong> nos aleja de cumplir con nuestro derecho y deber de vigilancia ciudadana. </strong>
                </p>
            </div>
        </div>
    
    </div>
    
</template>

<script>

    export default {
        name:"problema-section",
        components: {
        },
    }
</script>

<style lang="scss" scoped>
@media only screen and (min-width: 601px) {
  
    .container{
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            width: 100%;
            max-width: 1150px;
            margin: 0px auto 0px auto;
            
            h2{
                font-size: 45px;
                font-weight: 700;
                font-family: "Bebas Neue";
                line-break: 45px;
                text-transform: uppercase;
                text-align: center;
                width: 490px;
            }
            .invo-content{
                display: flex;
                flex-wrap: wrap;
                justify-content: center;
                .card-1{
                    margin-right: 15px;
                    margin-bottom: 40px;
                    flex-basis: 50%;
                    background-color: #481EFD;
                    background-image: url(@/assets/img/chica2.png);
                    background-repeat: no-repeat;
                    background-position: right 66px;
                    border-radius: 30px;
                    height: 237px;
                    width: 100%;
                    max-width: 538px;
                    .entrella{
                        background-image: url(@/assets/img/estrella1.png);
                        background-repeat: no-repeat;
                        height: 97px;
                        width: 99px;
                        margin-top: -45px;
                        margin-left: 35px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        strong{
                            font-size: 45px;
                            font-weight: 700;
                            font-family: "Bebas Neue";
                        }
                    }
                    p{
                        text-align: left;
                        font-size: 20px;
                        font-weight: 700;
                        color: #ffffff;
                        font-family: "Roboto";
                        width: 323px;
                        margin: 30px 0px 0px 55px;
                        strong{
                            color: #00FF98;
                            font-family: "Roboto";
                            font-weight: 700;
                    
                        }
                    }
                }
                .card-2{
                    margin-left: 15px;
                    margin-bottom: 40px;
                    flex-basis: 50%;
                    background-color: #F400C8;
                    background-image: url(@/assets/img/chica3.png);
                    background-repeat: no-repeat;
                    background-position: right 10px;
                    border-radius: 30px;
                    height: 237px;
                    width: 100%;
                    max-width: 538px;
                    .entrella{
                        background-image: url(@/assets/img/estrella2.png);
                        background-repeat: no-repeat;
                        height: 97px;
                        width: 99px;
                        margin-top: -45px;
                        margin-left: 35px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        strong{
                            font-size: 45px;
                            font-weight: 700;
                            font-family: "Bebas Neue";
                        }
                    }
                    p{
                        text-align: left;
                        font-size: 20px;
                        font-weight: 700;
                        padding-top: 30px;
                        color: #ffffff;
                        font-family: "Roboto";
                        width: 272px;
                        margin: 0px 0px 0px 55px;
                        strong{
                            color: #00FF98;
                            font-family: "Roboto";
                            font-weight: 700;
                    
                        }
                    }
                }
                .card-3{
                margin-top: 30px;
                margin-bottom: 40px;
                flex-basis: 50%;
                background-color: #00FF98;
                background-image: url(@/assets/img/chico.png);
                background-repeat: no-repeat;
                background-position: right 10px;
                border-radius: 30px;
                height: 237px;
                width: 100%;
                max-width: 538px;
                .entrella{
                    background-image: url(@/assets/img/estrella3.png);
                    background-repeat: no-repeat;
                    height: 97px;
                    width: 99px;
                    margin-top: -45px;
                    margin-left: 35px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    strong{
                        font-size: 45px;
                        font-weight: 700;
                        font-family: "Bebas Neue";
                        color: #ffffff;
                    }
                }
                p{
                    text-align: left;
                    font-size: 20px;
                    font-weight: 700;
                    padding-top: 20px;
                    color: #313131;
                    font-family: "Roboto";
                    width: 324px;
                    margin: 30px 0px 0px 55px;
                    strong{
                        color: #481EFD;
                        font-family: "Roboto";
                        font-weight: 700;
                
                    }
                }
            }
            

            }
      }
}
@media only screen and (max-width: 600px) {

    .container{
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            width: 100%;
            max-width: 600px;
            margin: 0px auto 0px auto;
            
            h2{
                font-size: 45px;
                font-weight: 700;
                font-family: "Bebas Neue";
                line-break: 45px;
                text-transform: uppercase;
                text-align: center;
                width: 274px;
            }
            .invo-content{
                display: flex;
                flex-wrap: wrap;
                justify-content: center;
                align-items: center;
                width: 95%;
                .card-1{
                    margin-bottom: 60px;
                    flex-basis: 100%;
                    background-color: #481EFD;
                    background-image: url(@/assets/img/chica2.png);
                    background-repeat: no-repeat;
                    background-position: center 218px;
                    border-radius: 30px;
                    height: 389px;
                    width: 100%;
                    max-width: 538px;
                    .entrella{
                        background-image: url(@/assets/img/estrella1.png);
                        background-repeat: no-repeat;
                        height: 97px;
                        width: 99px;
                        margin-top: -45px;
                        margin-left: 35px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        strong{
                            font-size: 45px;
                            font-weight: 700;
                            font-family: "Bebas Neue";
                        }
                    }
                    p{
                        text-align: center;
                        font-size: 20px;
                        font-weight: 700;
                        color: #ffffff;
                        font-family: "Roboto";
                        width: 100%;
                        padding: 40px 20px;
                        margin: 0px 0px 0px 0px;
                        strong{
                            color: #00FF98;
                            font-family: "Roboto";
                            font-weight: 700;
                    
                        }
                    }
                }
                .card-2{
                    margin-bottom: 60px;
                    flex-basis: 100%;
                    background-color: #F400C8;
                    background-image: url(@/assets/img/chica3.png);
                    background-repeat: no-repeat;
                    background-position: right 160px;
                    border-radius: 30px;
                    height: 389px;
                    width: 100%;
                    max-width: 538px;
                    .entrella{
                        background-image: url(@/assets/img/estrella2.png);
                        background-repeat: no-repeat;
                        height: 97px;
                        width: 99px;
                        margin-top: -45px;
                        margin-left: 35px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        strong{
                            font-size: 45px;
                            font-weight: 700;
                            font-family: "Bebas Neue";
                        }
                    }
                    p{
                        text-align: center;
                        font-size: 20px;
                        font-weight: 700;
                        padding: 40px 20px;
                        color: #ffffff;
                        font-family: "Roboto";
                        width: 100%;
                        margin: 0px 0px 0px 0px;
                        strong{
                            color: #00FF98;
                            font-family: "Roboto";
                            font-weight: 700;
                    
                        }
                    }
                }
                .card-3{
                margin-bottom: 60px;
                flex-basis: 100%;
                background-color: #00FF98;
                background-image: url(@/assets/img/chico.png);
                background-repeat: no-repeat;
                background-position: right 160px;
                border-radius: 30px;
                height: 389px;
                width: 100%;
                max-width: 538px;
                .entrella{
                    background-image: url(@/assets/img/estrella3.png);
                    background-repeat: no-repeat;
                    height: 97px;
                    width: 99px;
                    margin-top: -45px;
                    margin-left: 35px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    strong{
                        font-size: 45px;
                        font-weight: 700;
                        font-family: "Bebas Neue";
                        color: #ffffff;
                    }
                }
                p{
                    text-align: center;
                    font-size: 20px;
                    font-weight: 700;
                    padding: 40px 20px;
                    color: #313131;
                    font-family: "Roboto";
                    width: 95%;
                    margin: 0px 0px 0px 0px;
                    strong{
                        color: #481EFD;
                        font-family: "Roboto";
                        font-weight: 700;
                
                    }
                }
            }
            

            }
      }
}

</style>