<template>
    <div class="particia-view">
        <div class="hero-background-participa">
            <div class="container">
                    <hero></hero>
            </div>
            <div class="red-background">
                <div class="radio-background">
                    <div class="container">
                        <red></red>
                    </div>
                </div>
                
            </div>
            <div class="container">
                <duracion></duracion>
            </div>
            <div class="container">
                <aprenderas></aprenderas>
            </div>
        </div>
     
    </div>
</template>

<script>
import hero from './section/hero/index.vue'
import red from './section/red/index.vue'
import duracion from './section/duracion/index.vue'
import aprenderas from './section/aprenderas/index.vue'
    export default {
        name:"reacciona-participa",
        components: {
            hero,
            red,
            duracion,
            aprenderas
        },
    }
</script>

<style lang="scss">
@media only screen and (min-width: 601px) {
    .hero-background-participa{
            background-color: #FF0069;
            height: 100%;
            max-height: 646px;
            width: 100%;
            position: absolute;
            top: 0%;
            left: 0%;
    }
    .container{
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            max-width: 1150px;
            margin: 75px auto 0px auto;
      }
      .red-background{
        background: radial-gradient(circle, rgba(255,215,0,1) 0%, rgba(9,9,121,0) 70%);
        width: 100%;
        .radio-background{
            background-image: url(../../assets/img/circulos.png);
            background-repeat: no-repeat;
            background-size: cover;
            background-position: center center;
        }
      }
}
@media only screen and (max-width: 600px) {
    .hero-background-participa{
            background-color: #FF0069;
            height: 100%;
            min-height: 950px;
            width: 100%;
            position: absolute;
            top: 0%;
            left: 0%;
    }
    .container{
            display: flex;
            align-items: center;
            justify-content: center;
            height: 100%;
            width: 100%;
            max-width: 600px;
            margin: 75px auto 0px auto;
      }
      .red-background{
        background: radial-gradient(circle, rgba(255,215,0,1) 0%, rgba(9,9,121,0) 70%);
        width: 100%;
        .radio-background{
            background-image: url(../../assets/img/circulos.png);
            background-repeat: no-repeat;
            background-size: cover;
            background-position: center center;
        }
      }
}


 
</style>