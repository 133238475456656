<template>
        <div class="participa-content">
            <div class="img-content">
                <img src="@/assets/img/reacciona-red-background.png" alt="participa-img">
            </div>
            <div class="info-content">
                <div class="heading">
                    <h2>¿QUÉ ES LA RED VIGILANTE DE LA INVERSIÓN PÚBLICA?</h2>
                    <img src="@/assets/img/chico-participa-reacciona.svg" alt="reacciona-particia-app">
                </div>
                <div class="body">
                    <div class="grupo">
                        <p>El programa de vigilancia ciudadana de la inversión pública es fundamental para poder formar parte oficial de la Red Vigilante. </p>
                    </div>
                </div>
                <div class="btn-content">
      
                    <button @click.prevent="gotonav('https://bit.ly/quiero-beca')">¡Quiero una Beca!</button>
                </div>
            </div>
        </div>
</template>

<script>
    export default {
        name:"participa-section",
        methods: {
            gotonav(value) {
                window.location=value;
            },
        },
    }
</script>

<style lang="scss" scoped>

@media only screen and (min-width: 601px) {

          .participa-content{
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 100%;
            margin-top: 70px;
            .img-content{
                flex-basis: 35%;
              
                height: 100%;
                width: 100%;
                img{
                    height: 556px;
                    border-top-left-radius: 30px;
                    border-bottom-left-radius: 30px;
                }
            }
            .info-content{
                flex-basis: 65%;
                display: flex;
                justify-content: center;
                align-items: flex-start;
                flex-direction: column;
                background-color: #00FF98;
                border-radius: 30px;
                height: 558px;
                margin-left: -50px;
                .heading{
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    margin-top: 60px;
                    padding-left: 30px;
                    padding-right: 30px;
                    h2{
                        font-size: 45px;
                        font-weight: 700;
                        font-family: "Bebas Neue";
                        line-height: 45px;
                        text-transform: uppercase;
                        text-align: left;
                        color: #481EFD;
                        max-width: 379px;
                    }
                    img{
                        height: 152px;
                        width: 146px;
                    }
                 }
                 .body{
                    padding-left: 30px;
                    display: flex;
                    justify-content: flex-start;
                    align-items: flex-start;
                    flex-direction: column;
                    .grupo{
                        display: flex;
                        p{
                            margin-left: 5px;
                            text-align: left;
                            font-size: 20px;
                            line-height: 23.44px;
                            font-weight: 700;
                            font-family: "Roboto";
                            max-width: 441px;
                        }
                    }
                 }
                 .footer{
                    padding-left: 30px;
                    display: flex;
                    justify-content: flex-start;
                    margin-top: 70px;
                    padding-bottom: 50px;
                    p{
                        text-align: left;
                        font-size: 14px;
                        line-height: 16.41px;
                        font-weight: 700;
                        font-family: "Roboto";
                        margin-right: 5px;
                        a{
                            margin-right: 10px;    
                        }                    
                    }
                 }
                 .btn-content{
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 100%;
                    p{
                        text-align: left;
                        font-size: 18px;
                        font-weight: 700;
                        color: #481EFD;
                        font-family: "Roboto";
                        margin-left: 25px;
                        line-height: 21.09px;
                    }
                    button{
                        width: 215px;
                        background-color: #1C18F2;
                        border-radius: 16px;
                        padding: 16px, 44px, 16px, 44px;
                        color: #ffffff;
                        font-size: 16px;
                        font-weight: 500;
                        border: none;
                        height: 51px;
                  
                        cursor: pointer;
                    }
                }
            }
          }
        
  
}
@media only screen and (max-width: 600px) {
 
          .participa-content{
            display: flex;
            justify-content: space-between;
            align-items: center;
            flex-direction: column;
            width: 100%;
            margin-top: 0px;
            .img-content{
                flex-basis: 100%;
               
                height: 100%;
                width: 100%;
                img{
                    width: 90%;
                    height: 358px;
                    border-top-left-radius: 30px;
                    border-bottom-left-radius: 30px;
                }
            }
            .info-content{
                flex-basis: 100%;
                background-color: #00FF98;
                border-radius: 30px;
                width: 90%;
                margin-top: -170px;
                height: 558px;
                .heading{
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    margin-top: 60px;
                    padding-left: 30px;
                    padding-right: 30px;
                    h2{
                        font-size: 45px;
                        font-weight: 700;
                        font-family: "Bebas Neue";
                        line-height: 45px;
                        text-transform: uppercase;
                        text-align: left;
                        color: #481EFD;
                    }
                    img{
                       display: none;
                    }
                 }
                 .body{
                    padding-left: 30px;
                    display: flex;
                    justify-content: flex-start;
                    align-items: flex-start;
                    flex-direction: column;
                    .grupo{
                        display: flex;
                        p{
                            text-align: left;
                            font-size: 14px;
                            line-height: 16.41px;
                            font-weight: 700;
                            font-family: "Roboto";
                        }
                    }
                 }
                 .footer{
                    padding-left: 30px;
                    display: flex;
                    justify-content: flex-start;
                    margin-top: 70px;
                    padding-bottom: 50px;
                    p{
                        text-align: left;
                            font-size: 14px;
                            line-height: 16.41px;
                            font-weight: 700;
                            font-family: "Roboto";
                    }
                 }
                 .btn-content{
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 100%;
                    p{
                        text-align: left;
                        font-size: 18px;
                        font-weight: 700;
                        color: #481EFD;
                        font-family: "Roboto";
                        margin-left: 25px;
                        line-height: 21.09px;
                    }
                    button{
                        width: 215px;
                        background-color: #1C18F2;
                        border-radius: 16px;
                        padding: 16px, 44px, 16px, 44px;
                        color: #ffffff;
                        font-size: 16px;
                        font-weight: 500;
                        border: none;
                        height: 51px;
                  
                        cursor: pointer;
                    }
                }
            }
          }
        }

</style>