<template>
    <div class="container">
        <h2>publicaciones</h2>
        <div class="publicaciones-content">
                <div class="publicacion">
                    <publicacionCard></publicacionCard>
                </div>
                <div class="publicacion">
                    <publicacionCard></publicacionCard>
                </div>
                <div class="publicacion">
                    <publicacionCard></publicacionCard>
                </div>
                <div class="publicacion">
                    <publicacionCard></publicacionCard>
                </div>
        </div>
    </div>
</template>

<script>
import publicacionCard from '@/components/cards/publicacionCard.vue'
    export default {
        name:"publicaciones-section",
        components: {
            publicacionCard,
        },
    }
</script>

<style lang="scss" scoped>
@media only screen and (min-width: 601px) {
    .container{
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
          width: 100%;
          max-width: 1150px;
          margin: 0px auto 0px auto;
          h2{
            font-size: 45px;
            font-weight: 700;
            font-family: "Bebas Neue";
            line-height: 45px;
            text-transform: uppercase;
            text-align: center;
            margin: 0px;
            color: #313131;
            z-index: 1;
          }
          .publicaciones-content{
            margin-top: 90px;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            .publicacion{
                flex-basis: 25%;
            }
          }
          
    }
}
@media only screen and (max-width: 600px) {
    .container{
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
          width: 100%;
          max-width: 600px;
          margin: 0px auto 0px auto;
          h2{
            font-size: 45px;
            font-weight: 700;
            font-family: "Bebas Neue";
            line-height: 45px;
            text-transform: uppercase;
            text-align: center;
            margin: 0px;
            color: #313131;
            z-index: 1;
          }
          .publicaciones-content{
            margin-top: 90px;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-wrap: wrap;
            width: 100%;
            .publicacion{
                flex-basis: 50%;
            }
          }
          
    }
}
</style>