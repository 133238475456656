<template>
    <div>
    
    </div>
</template>

<script>
    export default {
        mounted () {
          
            window.location="https://bit.ly/reacciona-iniciativa-peruana";
        },
    }
</script>

<style lang="scss" scoped>

</style>