<template>
    <div class="duration-section">
        <h2>¿Cuánto dura el programa?</h2>
        <div class="btn-content">
      
      <button @click.prevent="gotonav('https://bit.ly/quiero-beca')">¡Quiero una Beca!</button>
  </div>
        <div class="card-duration">
            <div class="info">
                <p>El programa dura dos meses, se desarrolla de manera virtual y cierra con un full day presencial.</p>
                <ul>
                    <li>Virtual: 15 sesiones de clases que se realizaran los días lunes y miércoles.</li>
                    <li>Presencial:1 taller de comunicación multiplataforma y graduación.</li>
                </ul>
            </div>
            <div class="img-content">
                <img src="@/assets/img/chica-duracion-participa.svg" alt="chica-duracion-reacciona">
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name:'duracion-section',
        methods: {
            gotonav(value) {
                window.location=value;
            },
        },
    }
</script>

<style lang="scss" scoped>
 .btn-content{
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 100%;
                    padding-bottom: 20px;
                    p{
                        text-align: left;
                        font-size: 18px;
                        font-weight: 700;
                        color: #481EFD;
                        font-family: "Roboto";
                        margin-left: 25px;
                        line-height: 21.09px;
                    }
                    button{
                        width: 215px;
                        background-color: #1C18F2;
                        border-radius: 16px;
                        padding: 16px, 44px, 16px, 44px;
                        color: #ffffff;
                        font-size: 16px;
                        font-weight: 500;
                        border: none;
                        height: 51px;
                  
                        cursor: pointer;
                    }
                }
@media only screen and (min-width: 601px) {
  .duration-section{
    width: 100%;
    h2{
        font-size: 45px;
        font-weight: 700;
        font-family: "Bebas Neue";
        line-height: 45px;
        text-transform: uppercase;
        text-align: center;
        color: #313131;
    }
    .card-duration{
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        background-color: #FFD700;
        border-radius: 30px;
        height: 267px;
        .info{
            flex-basis: 80%;
            p{
            text-align: left;
            font-size: 24px;
            line-height: 28.13px;
            font-weight: 500;
            font-family: "Roboto";
            color: #481EFD;
            max-width: 715.26px;
            margin-left: 45px;
        }
        ul{
            margin-left: 0px;
            padding-left: 55px;
            li{
                margin-left: 0px;
                text-align: left;
                font-size: 20px;
                line-height: 23.44px;
                font-weight: 700;
                font-family: "Roboto";
                color: #313131;
            }
        }
        }
        .img-content{
            flex-basis: 20%;
            img{
                margin-bottom: 16px;
                margin-right: 15px;
            }
        }
      
    }

  }


}
@media only screen and (max-width: 600px) {
    .duration-section{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    h2{
        font-size: 45px;
        font-weight: 700;
        font-family: "Bebas Neue";
        line-height: 45px;
        text-transform: uppercase;
        text-align: center;
        color: #313131;
    }
    .card-duration{
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        width: 90%;
        background-color: #FFD700;
        border-radius: 30px;
        height: 100%;
        .info{
            flex-basis: 100%;
            p{
            text-align: center;
            font-size: 24px;
            line-height: 28.13px;
            font-weight: 500;
            font-family: "Roboto";
            color: #481EFD;
            max-width: 715.26px;
        
        }
        ul{
            flex-basis: 100%;
            margin-left: 0px;
            padding-left: 0px;
            list-style: none;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
  
            li{
                margin-left: 0px;
                text-align: center;
                font-size: 20px;
                line-height: 23.44px;
                font-weight: 700;
                font-family: "Roboto";
                color: #313131;
                width: 90%;
                margin-bottom: 10px;
            }
        }
        }
        .img-content{
            flex-basis: 100%;
            img{
                margin-bottom: -5px;
            }
        }
      
    }

  }
}
</style>