<template>
    <div class="container">
        <div class="somos">
            <h2>somos</h2>
            <img src="@/assets/img/reacciona-app.png" alt="reacciona-app-somos">
        </div>
        <div class="info">
            <div class="header">
                <p>Nuestro propósito es claro: <strong>Identificar problemas para buscar soluciones que permitan cerrar brechas.</strong></p>
                <img src="@/assets/img/reacciona-app-logo.png" alt="reacciona-app-logo">
            </div>
            
            <p class="p1">Estamos firmemente comprometidos con el desarrollo del país, impulsando el uso adecuado de los recursos públicos mediante la activa participación de la ciudadanía.</p>
            <p class="p2">Te invitamos a liderar esta era de cambio y marcar la diferencia en tu comunidad a través de información relevante, directa y clara.</p>
            <img class="movile" src="@/assets/img/lupamin2.png" alt="reacciona-app-logo-movile">
            <p class="p3">
                Únete a la <strong>red vigilante de ReAcciona</strong> y sé parte de una generación comprometida con el futuro de nuestro país.
            </p>
            <p class="p4">
                <strong>¡Tu voz importa y juntos podemos lograr un impacto positivo!</strong>
            </p>
        </div>

    </div>
</template>

<script>
    export default {
        name:"somos-section"
    }
</script>

<style lang="scss" scoped>
@media only screen and (min-width: 601px) {
  
  .container{
          display: flex;
          align-items: center;
          justify-content: center;
          width: 100%;
          max-width: 1150px;
          
          margin: 0px auto 0px auto;
          .somos{
            flex-basis: 40%;
            background-color: #481EFD;
            border-radius: 29px;
            height: 100%;
            max-width:372px;
            height: 781px;
            z-index: 1!important;
            h2{
                font-size: 45px;
                font-weight: 700;
                font-family: "Bebas Neue";
                line-break: 45px;
                text-transform: uppercase;
                text-align: left;
                margin-left: 140px;
                width: 490px;
                color: #F1F1F1;
            }
            img{
                
                margin-top: 160px;
            }
          }
          .info{
            flex-basis: 60%;
            display: flex;
            flex-direction: column;
            align-items: flex-end;
            margin-top: 0px;
            background-color: #FFD700;
            height: 781px;
            padding-right: 30px;
            margin-left: -30px;
            border-top-right-radius: 29px;
            border-bottom-right-radius: 29px;
            .header{
                display: flex;
                justify-content: space-between;
                align-items: center;
                width: 684px;
                p{
                    width: 528px;
                    font-size: 22px;
                    line-height: 25,78px;
                    font-weight: 400;
                    text-align: left;
                }
                img{
                    height: 60px;
                    width: 63px;
                }
            }
            .p1{
                margin-left: 60px;
                text-align: justify;
                font-size: 45px;
                font-weight: 700;
                text-transform: uppercase;
                color: #313131;
                font-family: "Bebas Neue";
                width: 684px;
                margin-top: 10px;
            }
            .p2{
                        padding: 40px 25px;
                        margin-top: 0px;
                        text-align: justify;
                        font-size: 20px;
                        font-weight: 700;
                        background-color:#481EFD;
                        color: #ffffff;
                        font-family: "Roboto";
                        width: 493px;
                        border-radius: 10px;
                       
                 
            }
            .movile{
                display: none;
            }
            .p3{
                margin-top: 50px;
                        text-align: left;
                        font-size: 20px;
                        font-weight: 700;
                        color: #313131;
                        font-family: "Roboto";
                        width: 684px;
                        strong:first-of-type{
                            color: #481EFD;
                        }
                        strong{
                            color: #481EFD;
                        }
                       
                 
            }
            .p4{
    
                        text-align: left;
                        font-size: 20px;
                        font-weight: 700;
                        color: #313131;
                        font-family: "Roboto";
                        width: 684px;
                        strong:first-of-type{
                            color: #481EFD;
                        }
                        strong{
                            color: #481EFD;
                        }
                       
                 
            }
          }
    }
}
@media only screen and (max-width: 600px) {
    .container{
          display: flex;
          align-items: center;
          justify-content: flex-start;
          flex-direction: column;
          width: 100%;
          max-width: 600px;
      
          margin: 0px auto 0px auto;
          .somos{
            flex-basis: 100%;
            background-color: #481EFD;
            border-radius: 29px;
            height: 100%;
            width: 95%;
     
            height: 299px;
            display: flex;
            flex-direction: column;
            align-items: center;
            h2{
                font-size: 45px;
                font-weight: 700;
                font-family: "Bebas Neue";
                line-break: 45px;
                text-align: center;
                text-transform: uppercase;
                width: 100%;
                color: #F1F1F1;
            }
            img{
            
            
                height: 141px;
                width: 275px;
            }
          }
          .info{
            flex-basis: 100%;
            display: flex;
            flex-direction: column;
            align-items: flex-end;
            width: 100%;
            img{
                display: none;
            }
            .movile{
                display: block;
                margin: 20px auto;
                width: 100%;
                max-width: 63px;
                height: 66px;
            }
            .p1{
                        text-align: center;
                        font-size: 20px;
                        font-weight: 700;
                        color: #313131;
                        font-family: "Roboto";
                        width: 95%;
                   
                        margin-top: 50px;
                        line-height: 23.44px;
                        strong{
                            color: #FF0069;
                        }
                       
                 
            }
            .p2{
                        margin-top: 50px;
                        text-align: center;
                        font-size: 20px;
                        font-weight: 700;
                        color: #481EFD;
                        font-family: "Roboto";
                        width: 95%;
                      
                        line-height: 23.44px;
                        strong:first-of-type{
                            color: #FF0069;
                        }
                        strong{
                            color: #FF0069;
                        }
                       
                 
            }
            .p3{
             
                        text-align: center;
                        font-size: 20px;
                        font-weight: 700;
                        color: #313131;
                        font-family: "Roboto";
                        width: 95%;
                   
                        line-height: 23.44px;
                        strong:first-of-type{
                            color: #481EFD;
                        }
                        strong{
                            color: #481EFD;
                        }
                       
                 
            }
          }
    }
}

</style>