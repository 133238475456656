<template>
    <div :class="sidebar ?'sidebar slide-in' : 'min-sidebar slide-out'">
            <div class="header">
                <div class="brand">
                        <div class="logo">
                            <router-link to="/">
                                <img src="../../../assets/Logo.svg" alt="logo-ferre">
                            </router-link>
                        </div>
                        <button v-if="sidebar"  @click.prevent="sidebarOpened()" class="burguer-menu">
                            <svg width="92" height="92" viewBox="0 0 92 92" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g filter="url(#filter0_d_1923_2186)">
                            <rect x="24" y="20" width="44" height="44" rx="14" fill="#F1F1F1" shape-rendering="crispEdges"/>
                            <path d="M38.9287 49.0713L53.0708 34.9292" stroke="#481EFD" stroke-width="2" stroke-linecap="round"/>
                            <path d="M38.9287 34.9287L53.0708 49.0708" stroke="#481EFD" stroke-width="2" stroke-linecap="round"/>
                            </g>
                            <defs>
                            <filter id="filter0_d_1923_2186" x="0" y="0" width="92" height="92" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                            <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                            <feOffset dy="4"/>
                            <feGaussianBlur stdDeviation="12"/>
                            <feComposite in2="hardAlpha" operator="out"/>
                            <feColorMatrix type="matrix" values="0 0 0 0 0.894118 0 0 0 0 0.894118 0 0 0 0 0.894118 0 0 0 0.25 0"/>
                            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1923_2186"/>
                            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1923_2186" result="shape"/>
                            </filter>
                            </defs>
                            </svg>
                        </button>
                </div>
            </div>
            <div class="body">
                <nav>
                    <a @click.prevent="gotoScroll('inicio')" to="" class="link-group">
                        
                        <p v-if="sidebar" >Inicio</p>
                    </a>
                    <a @click.prevent="gotoScroll('problema')" to="" class="link-group">
                        
                        <p v-if="sidebar" >Problema</p>
                    </a>
                    <a @click.prevent="gotoScroll('como')" to="" class="link-group">
                        
                        <p v-if="sidebar" >ReAcciona</p>
                    </a>
                  <router-link  to="/participa" class="link-group">
                        
                        <p v-if="sidebar" >Participa</p>
                    </router-link>
                    <a @click.prevent="gotoScroll('publicaciones')" to="" class="link-group">
                        
                        <p v-if="sidebar" >Publicaciones</p>
                    </a>
                    <a @click.prevent="gotoScroll('vigilante')" to="" class="link-group">
                        
                        <p v-if="sidebar" >#ReAccionaApp</p>
                    </a>
    
                    <!-- <router-link to="productos" class="link-group">
                       <img src="@/assets/icons/services.svg" alt="productos-ferrefast">
                       <p v-if="sidebar" >Servicios</p>
                   </router-link> -->
                </nav>
            </div>
            <div class="footer">
                <nav>
                    <p v-if="sidebar" >Síguenos en nuestras redes:</p>
                    <div class="redes-content">
                        <a href="https://www.linkedin.com/company/reacciona-pe/" target="_blank" rel="noopener noreferrer"><img src="@/assets/img/linkedin.svg" alt="twiter-rede"></a>
                                        <a href="https://twitter.com/ReAcciona_pe" target="_blank" rel="noopener noreferrer"><img src="@/assets/img/twiter.png" alt="twiter-rede"></a>
                                        <a href="https://www.facebook.com/share/W2EgWnjwhWtn9NNV/?mibextid=WC7FNe" target="_blank" rel="noopener noreferrer"><img src="@/assets/img/facebook.png" alt="twiter-rede"></a>
                                        <a href="https://www.instagram.com/re.accionape?igsh=eGppamhyeGxqa2Z1" target="_blank" rel="noopener noreferrer"><img src="@/assets/img/instagram.png" alt="twiter-rede"></a>
                    </div>
                </nav>
            </div>
    </div>
</template>

<script>
import store from "@/store/index"
import { mapState } from "vuex";
    export default {
        name:"sidebar-component",
        methods: {
            sidebarOpened() {
                store.dispatch("SIDEBAR_STATUS",!this.sidebar)
            },
            gotoScroll(value){
                const el = document.getElementById(value);
                el.scrollIntoView({behavior: "smooth"});
                this.sidebarOpened()
            },
            logout() {
                store
                .dispatch("LOGOUT")
                .then((response) => {
                    this.$router.push("/");
                    store.dispatch("showToast", {
                        message: "Sesion Eliminada",
                        type: "success",
                    });
                })
                .catch((error) => {
                console.log("error", error);
            });
        }
        },
        computed: {
            ...mapState({
                sidebar: (state) => state.sideBarStatus,
                user: (state) => state.AUTH.user,
            }),
        },
    }
</script>

<style lang="scss" scoped>
    .sidebar{
        position: fixed;
        top: 0%;
        z-index: 0;
        left: 0%;
        height: 100vh;
        width: 100%;
        z-index: 2;
        background-color: rgb(255, 255, 255);
        box-shadow: 2px 2px 5px 0px rgba(0,0,0,0.75);
        -webkit-box-shadow: 2px 2px 5px 0px rgba(0,0,0,0.75);
        -moz-box-shadow: 2px 2px 5px 0px rgba(0,0,0,0.75);
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        .header{
            flex-basis: 5%;
            display: flex;
            padding: 5px 0px 0px 27px;
            .brand{
            display: flex;
            width: 100%;
            justify-content: space-between;
            align-items: center;
            .burguer-menu{
                background: transparent;
                border: none;
                cursor: pointer;
                margin-left: 17px;
            }
            .logo{
                a{
              
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    color: #000000;
                    font-family: Inter;
                    font-size: 24px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: normal;
                    text-decoration: none;
                    strong{
                        animation: opacityslidein 1s 1;
                        margin-left: 16px;
                        margin-right: 50px;
                    }
                }   
            }
            }
        }
        .body{
            flex-basis: 65%;
            display: flex;
            flex-direction: column;
            justify-content: center;
           nav{
            display: flex;
            width: 100%;
            flex-direction: column;
            .link-group{
                height: 40px;
                width: 100%;
                display: flex;
                justify-content: space-between;
                align-items: center;
                text-decoration: none;
                margin-left: 27px;
                p{
                    animation: opacityslidein 1s 1;
                    text-align: start;
                    font-size: 24px;
                    width: 100%;
                    font-weight: bold;
                    color: #481EFD;
              
                }

            }
           
           }
        }
        .footer{
           flex-basis: 20%;
           nav{
            display: flex;
            width: 100%;
            flex-direction: column;
                p{
                    animation: opacityslidein 1s 1;
                    text-align: start;
                    font-size: 18px;
                    width: 100%;
                    font-weight: bold;
                    color: #481EFD;
                    margin-left: 27px;
                }
                .redes-content{
                    display: flex;
                    justify-content: flex-start;
                    width: 100%;
                    margin-left: 27px;
                    img{
                        margin-right: 15px;
                    }
                }

            
           
           }
        }
        
    }
    .min-sidebar{
        display: none!important;
        position: fixed;
        top: 0%;
        left: 0%;
        height: 100vh;
        width: 0px;
        z-index: 1;
        background-color: rgb(255, 255, 255);
        box-shadow: 2px 2px 5px 0px rgba(0,0,0,0.75);
        -webkit-box-shadow: 2px 2px 5px 0px rgba(0,0,0,0.75);
        -moz-box-shadow: 2px 2px 5px 0px rgba(0,0,0,0.75);
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        .header{
            flex-basis: 8%;
            display: flex;
            padding: 49px 0px;
            .brand{
            
         display: none;
            .burguer-menu{
                background: transparent;
                border: none;
                cursor: pointer;
                margin-left: 17px;
            }
            .logo{
                display: none;
                a{
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    color: #000000;
                    font-family: Inter;
                    font-size: 24px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: normal;
                    text-decoration: none;
                    strong{
                        margin-left: 16px;
                        margin-right: 50px;
                    }
                    img{
                        margin-left: 5px;
                    }
                }   
            }
            }
        }
        .body{
            flex-basis: 85%;
            display: none;
           nav{
            display: flex;
            width: 100%;
            flex-direction: column;
            .link-group{
                height: 80px;
                width: 100%;
                display: flex;
                justify-content: space-between;
                align-items: center;
                text-decoration: none;
                border-bottom: 1px solid #00000077;
                p{
                    text-align: start;
                    font-size: 18px;
                    width: 100%;
                    font-weight: bold;
                    color: #000000;
                    margin-left: 10px;
                }
                img{
                    margin-left: 15px;
                    height: 35px;
                    width: 29;
                }

            }
           
           }
        }
        .footer{
            flex-basis: 7%;
            display: none;
           nav{
            display: flex;
            width: 100%;
            flex-direction: column;
            .link-group{
                height: 80px;
                width: 100%;
                display: flex;
                justify-content: space-between;
                align-items: center;
                text-decoration: none;
                border-bottom: 1px solid #00000077;
                p{
                    text-align: start;
                    font-size: 18px;
                    width: 100%;
                    font-weight: bold;
                    color: #000000;
                    margin-left: 10px;
                }
                img{
                    margin-left: 15px;
                    height: 35px;
                    width: 29;
                }

            }
           
           }
        }
    }
    .slide-in{
        animation: slidein 1s 1;
    }
    .slide-out{
        animation: slideout 1s 1;
    }
    @keyframes slidein {
        from {
            width: 0%;
        }
        to {
            width: 100%;
        }
    }
    @keyframes slideout {
        from {
            width: 100%;
        }
        to {
            width:  0%;
        }
    }

    @keyframes opacityslidein {
        from {
            opacity: 0;
        }
        to {
            opacity: 1; 
        }
    }
    @keyframes opacityslideout {
        from {
            opacity: 1;
        }
        to {
            opacity: 0;
        }
    }
</style>