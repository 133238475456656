<template>
    <div class="nav">
       <div class="nav-inferior">
            <div class="container">
                <div class="brand">
                        <div class="logo">
                            <router-link to="/">
                                <img src="../../assets/Logo.svg" alt="logo-ferre">
                            </router-link>
                        </div>
                </div>
                <ul class="menu">
                        <li @click.prevent="gotoScroll('inicio')">Inicio</li>
                        <li @click.prevent="gotoScroll('problema')">Problema</li>
                        <li @click.prevent="gotoScroll('como')">ReAcciona</li>
                        <li @click.prevent="gotoNav('/participa')">Participa</li>
                        <!-- <li @click.prevent="gotoScroll('publicaciones')">Publicaciones</li> -->
                        <li @click.prevent="gotoScroll('vigilante')">#ReAccionaApp</li>

                </ul>
                <div class="burguer-menu" @click.prevent="sidebarOpened()">
                    <svg width="92" height="92" viewBox="0 0 92 92" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g filter="url(#filter0_d_1901_6702)">
                        <rect x="24" y="20" width="44" height="44" rx="14" fill="white" shape-rendering="crispEdges"/>
                        <path d="M36 36H56" stroke="black" stroke-width="2" stroke-linecap="round"/>
                        <path d="M36 42H56" stroke="black" stroke-width="2" stroke-linecap="round"/>
                        <path d="M36 48H56" stroke="black" stroke-width="2" stroke-linecap="round"/>
                        </g>
                        <defs>
                        <filter id="filter0_d_1901_6702" x="0" y="0" width="92" height="92" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                        <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                        <feOffset dy="4"/>
                        <feGaussianBlur stdDeviation="12"/>
                        <feComposite in2="hardAlpha" operator="out"/>
                        <feColorMatrix type="matrix" values="0 0 0 0 0.894118 0 0 0 0 0.894118 0 0 0 0 0.894118 0 0 0 0.25 0"/>
                        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1901_6702"/>
                        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1901_6702" result="shape"/>
                        </filter>
                        </defs>
                        </svg>
                </div>
           
            </div>
       </div>
    </div>
</template>
<script>
import store from "../../store"

import { mapState } from "vuex";
export default {
  name: "NavbarComponemt",
  data() {
    return {
        dropdown_items: [
            {url:null, name:'Cerrar Sessión', type:"action", method:"logout()", icon:`<svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="M200-120q-33 0-56.5-23.5T120-200v-560q0-33 23.5-56.5T200-840h280v80H200v560h280v80H200Zm440-160-55-58 102-102H360v-80h327L585-622l55-58 200 200-200 200Z"/></svg>`},
        ],
    }
  },
  computed: {
        ...mapState({
            user: (state) => state.AUTH.user,
        }),
  },
  methods: {
    sidebarOpened() {
        store.dispatch("SIDEBAR_STATUS",true)
    },
    gotoScroll(value){
      const el = document.getElementById(value);
      el.scrollIntoView({behavior: "smooth"});
    },
    gotoNav(value){
        this.$router.push(value);
    }
  },
  components: {
  },
};
</script>
 <!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@media only screen and (min-width: 601px) {
    .nav{
    position: fixed!important;
    top: 0%;
    background-color: #ffffff8b;
    width: 100%;
    z-index: 2;
    .nav-inferior{
        height: 102px;
      
        display: flex;
        align-items: center;
        .container{
            display: flex;
            align-items: center;
            justify-content: center;
            width: 1150px;
            margin: 0px auto;
            .brand{
            flex-basis: calc(30% - 10px);
            display: flex;
            justify-content: space-between;
            align-items: center;
            .logo{
               
                a{
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    color: #FFF;
                    font-family: Inter;
                    font-size: 24px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: normal;
                    text-decoration: none;
                    strong{
                        margin-left: 16px;
                        margin-right: 50px;
                    }
                }   
            }
            }
            .burguer-menu{
                display: none;
            }
            .menu{
                flex-basis: calc(70% - 10px);
                width: 100%;
                display: flex;
                justify-content: space-between;

                list-style: none;
                li{
                    font-weight: bold;
                    color:#000000;
                    font-size: 16px;
                    cursor: pointer;
                }
                
            }
        }
      
    }
}
}





@media only screen and (max-width: 600px) {
    .nav{
    z-index: 1;
    position: fixed!important;
    background-color: #ffffff8b;
    top: 0%;
    padding-left: 27px;
    width: 100%;
    .nav-inferior{
        height: 100%;
        display: flex;
        align-items: center;
        .container{
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;
            margin: 0px auto;
            .brand{
            flex-basis: calc(70% - 10px);
            display: flex;
            justify-content: space-between;
            align-items: center;
            }
            .burguer-menu{
                flex-basis: calc(30% - 10px);
                background: transparent;
                border: none;
                cursor: pointer;
                
            }
            .menu{
                display: none;
            }
            }
        }
      
    }
}






</style>
  
