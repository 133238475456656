<template>
    <div class="container">
        <h2>patrocinado por</h2>
        <div class="aliados-content">
           
            <div class="aliado">
                <img width="300" height="125" src="@/assets/img/bicentenario.png" alt="uni-patricinador">
            </div>
        </div>
        <h2>nos respaldan</h2>
        <div class="aliados-content">
            <div class="aliado">
                <img src="@/assets/img/redes.png" alt="uni-apoyo">
            </div>
            <div class="aliado">
                <img src="@/assets/img/senaju.png" alt="uni-apoyo">
            </div>
            <div class="aliado">
                <img src="@/assets/img/mosqoy.png" alt="uni-apoyo">
            </div>
            <div class="aliado">
                <img src="@/assets/img/transparencia.png" alt="uni-apoyo">
            </div>
            <div class="aliado">
                <img src="@/assets/img/uni.svg" alt="uni-apoyo">
            </div>
            <div class="aliado">
                <img src="@/assets/img/impacta.svg" alt="impacta">
            </div>
            <div class="aliado">
                <img width="175" height="75" src="@/assets/img/gestion.png" alt="compite">
            </div>
            <div class="aliado">
                <img width="175" height="75" src="@/assets/img/camaraArequipa.png" alt="compite">
            </div>
            <div class="aliado">
                <img width="175" height="75" src="@/assets/img/INCUSE.png" alt="compite">
            </div>
            <div class="aliado">
                <img width="175" height="75" src="@/assets/img/IPE.jpeg" alt="compite">
            </div>
        </div>
        <h2>Una Iiniciavita de:</h2>
        <div class="aliados-content">
            <div class="aliado">
                <img src="@/assets/img/apoyo.svg" alt="instituto-apoyo">
            </div>
            <div class="aliado">
                <img src="@/assets/img/compite.svg" alt="compite">
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name:"aliados-section"
    }
</script>

<style lang="scss" scoped>
@media only screen and (min-width: 601px) {
    .container{
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
          width: 100%;
          max-width: 1150px;
          margin: 0px auto 0px auto;
          h2:first-child{
            font-size: 45px;
            font-weight: 700;
            font-family: "Bebas Neue";
            line-height: 45px;
            text-transform: uppercase;
            text-align: center;
            margin: 0px;
            z-index: 1;
    
          }
          h2{
            font-size: 45px;
            font-weight: 700;
            font-family: "Bebas Neue";
            line-height: 45px;
            text-transform: uppercase;
            text-align: center;
            margin: 0px;
            z-index: 1;
            margin-top: 100px;
          }
          .aliados-content{
            display: flex;
            justify-content: center;
            align-items: center;
            flex-wrap: wrap;
            width: 100%;
            margin-top: 60px;
            .aliado{
                flex-basis: 25%;
                display: flex;
                justify-content: center;
                margin-bottom: 20px;
            }
          }
    }
}
@media only screen and (max-width: 600px) {
    .container{
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
          width: 100%;
          max-width: 600px;
          margin: 0px auto 0px auto;
          h2:first-child{
            font-size: 45px;
            font-weight: 700;
            font-family: "Bebas Neue";
            line-height: 45px;
            text-transform: uppercase;
            text-align: center;
            margin: 0px;
            z-index: 1;
          }
          h2{
            font-size: 45px;
            font-weight: 700;
            font-family: "Bebas Neue";
            line-height: 45px;
            text-transform: uppercase;
            text-align: center;
            margin: 0px;
            z-index: 1;
            margin-top: 70px;
          }
          .aliados-content{
            display: flex;
            justify-content: center;
            align-items: center;
            flex-wrap: wrap;
            width: 100%;
            margin-top: 60px;
            .aliado{
                flex-basis: 50%;
                display: flex;
                justify-content: center;
            }
          }
    }
}
</style>