import request from '@/intersetion/request'
const obj = {
    login(values) {
        return request({
          url: '/login',
          method: 'post',
          data: values,
        })
    },
    register(values) {
        return request({
          url: '/register',
          method: 'post',
          data: values,
        })
    },
    refresh() {
        return request({
          url: '/user',
          method: 'get',
        })
    },
    logout(){
        return request({
            url: '/logout',
            method: 'post',
        })
    },
    deleteUser(value) {
      return request({
        url: '/users-config/delete-user',
        method: 'post',
        data: value
      })
    },
}
export default obj