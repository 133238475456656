<template>
    <div class="manos-content">
        <img src="@/assets/img/manos.png" alt="reacciona-manos">
    </div>
</template>

<script>
    export default {
        name:"sub-hero"
    }
</script>

<style lang="scss" scoped>
@media only screen and (min-width: 601px) {
    .manos-content{
        img{
          
            margin-top: 0px;
        }
     
    }

}
@media only screen and (max-width: 600px) {
    .manos-content{
        img{
            width: 100%;
            max-width: 362px;
            margin-top: 0px;
        }
     
    }
}
   
</style>