<template>
    <div class="container">
        <h2>Solicitud de Cierre de Cuenta</h2>
        <form @submit.prevent="enviarSolicitud(user)">
            <div class="form-control">
                    <input type="email" v-model="user.email" placeholder="email">
            </div>
            <div class="form-control">
                    <input type="password" v-model="user.password" placeholder="Contraseña">
            </div>
            <div class="button-content">
                <input type="submit" value="Enviar Solicitud">
            </div>
            <span>
                <strong>Nota:</strong> Una vez eliminada la cuenta no podrá tener acceso a nuestro aplicativo
            </span>
        </form>
    </div>
</template>

<script>
import store from '@/store'
    export default {
        name:"solicitud-cierre",
        data() {
            return {
                user: {
                    email:null,
                    password:null,
                }
            }
        },
        methods: {
            enviarSolicitud(value) {
                store.dispatch("DELETE_USER",value)
                .then((response) => { 
                    console.log(response)
                })
                .catch((error) => {
                    console.log("error", error);
                });
            }
        },
    }
</script>

<style lang="scss" scoped>
@media only screen and (min-width: 601px) {
    .container{
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            width: 100%;
            height: calc(100vh - 75px);
            max-width: 1150px;
            margin: 75px auto 0px auto;
            form{
                background-color: #481EFD;
                border-radius: 30px;
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;
                height: 400px;
                width: 400px;
                .form-control{
                    input{
                        width: 240px;
                        border: none;
                        height: 31px;
                        border-radius: 5px;
                        margin-bottom: 20px;
                    }
                }
                .button-content{
                    input{
                        background-color: #FF0069;
                        width: 240px;
                        color: #ffffff;
                        padding: 10px 40px;
                        border-radius: 10px;
                        border: none;
                        cursor: pointer;
                    }
                }
                span{
                    color: #ffffff;
                    margin-top: 60px;
                }
                
            }
      }
}
@media only screen and (max-width: 600px) {
    .container{
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            width: 100%;
            height: calc(100vh - 75px);
            max-width: 600px;
            margin: 75px auto 0px auto;
            form{
                background-color: #481EFD;
                border-radius: 30px;
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;
                height: 400px;
                width: 90%;
                .form-control{
                    input{
                        width: 240px;
                        border: none;
                        height: 31px;
                        border-radius: 5px;
                        margin-bottom: 20px;
                    }
                }
                .button-content{
                    input{
                        background-color: #FF0069;
                        width: 240px;
                        color: #ffffff;
                        padding: 10px 40px;
                        border-radius: 10px;
                        border: none;
                        cursor: pointer;
                    }
                }
                span{
                    color: #ffffff;
                    margin-top: 60px;
                }
                
            }
      }
}
</style>