<template>
    <div class="container">
        <div class="html-content">
            <p align="center" style="margin-bottom: 0.28cm; line-height: 108%">
<font  style="font-size: 24PX; text-align: center;"><b >Términos y Condiciones
ReAcciona</b></font></p><p style="margin-bottom: 0.28cm; line-height: 108%"><b>Instituto
APOYO</b> es una persona jurídica sin fines de lucro, constituida en
el Perú bajo la forma de una asociación, que forma parte del Grupo
Apoyo, para destinar talento, conocimiento e innovación al
desarrollo de programas educativos innovadores y de difusión social.
Como parte de las actividades desarrolladas para alcanzar sus fines,
Instituto APOYO ha diseñado el proyecto "ReAcciona", un
proyecto que busca promover la vigilancia ciudadana.</p><p style="margin-bottom: 0.28cm; line-height: 108%">Los siguientes
Términos y Condiciones de ReAcciona, proyecto de Instituto APOYO,
(en adelante “Términos”) describen las reglas en que los
usuarios se encuentran sujetos al uso de la aplicación móvil y
plataforma web de ReAcciona (en adelante “Aplicativo”).</p><p style="margin-bottom: 0.28cm; line-height: 108%">Al momento de
registrarse en el Aplicativo, y dar click en la casilla
correspondiente, el usuario tiene como obligación aceptar los
Términos para poder navegar o utilizar nuestro Aplicativo, y sus
servicios asociados. Estos Términos constituyen un acuerdo entre su
persona y el proyecto ReAcciona. Si no se encuentra de acuerdo con
los Términos, por favor, no utilizar nuestro Aplicativo.</p><p style="margin-bottom: 0.28cm; line-height: 108%">Antes de
registrarse o hacer uso de los Servicios, los Usuarios declaran
expresamente que son personas mayores de edad, capaces de discernir,
que han entrado al Aplicativo por su propia voluntad y que no ha sido
coaccionado para ello, y que la selección de los Servicios que los
Usuarios lleven a cabo la hacen por su sola y única cuenta y riesgo,
aceptando expresamente estos Términos, aquellos otros avisos e
instrucciones, comunicaciones, o condiciones particulares, puestos en
conocimiento de los Usuarios.</p><ol>
	<li><h1>Objetivo</h1>
</li></ol><p style="margin-bottom: 0.28cm; line-height: 108%">El Aplicativo,
son herramientas tecnológicas que fueron diseñadas y creadas por
Instituto APOYO en el marco del proyecto ReAcciona, con el objeto de
que el Aplicativo<font color="#ff0000"> </font>permita a la
ciudadanía acceder a información pública y fiable de manera clara
y amigable.</p><p style="margin-bottom: 0.28cm; line-height: 108%">Los usuarios
podrán hacer uso de una gama de Servicios: 
</p><ul>
	<li><p style="margin-bottom: 0.28cm; line-height: 108%">Tener el
	Aplicativo (herramientas) de tecnología gratuita.</p>
	</li><li><p style="margin-bottom: 0.28cm; line-height: 108%">Encontrar
	data pública del Ministerio de Economía y Finanzas presentada al
	Usuario de manera amigable.</p>
	</li><li><p style="margin-bottom: 0.28cm; line-height: 108%">Utilizar
	herramientas diseñadas para primar la experiencia del usuario.</p>
	</li><li><p style="margin-bottom: 0.28cm; line-height: 108%">Aprender las
	bases de la Administración Pública mediante videos <i>microlearnig</i>
	de forma gratuita.</p>
	</li><li><p style="margin-bottom: 0.28cm; line-height: 108%">Comentar
	sobre los proyectos de inversión pública que se encuentren en la
	aplicación móvil que bajo el criterio del Usuario tengan alguna
	observación.</p>
	</li><li><p style="margin-bottom: 0.28cm; line-height: 108%">Ver los
	proyectos de inversión pública de interés del Usuario</p>
	</li><li><p style="margin-bottom: 0.28cm; line-height: 108%">Recibir
	recomendaciones de una cartera de proyectos de inversión con mayor
	importancia sobre la región.</p>
	</li><li><p style="margin-bottom: 0.28cm; line-height: 108%">Encontrar
	los proyectos de inversión pública más cercanos a su localidad,
	sin perjuicio de que puedan buscar los proyectos en localidades
	distintas a las que ellos se encuentran cerca.</p>
	</li><li><p style="margin-bottom: 0.28cm; line-height: 108%">Tener
	información sobre los eventos, foros, mesas técnicas u otras
	actividades que se desarrollen por el proyecto ReAcciona.</p>
	</li><li><p style="margin-bottom: 0.28cm; line-height: 108%">Encontrar
	noticias, artículos, ensayos, columnas de opinión y otros
	documentos académicos desarrollados por el proyecto ReAcciona.</p>
	</li><li><p style="margin-bottom: 0.28cm; line-height: 108%">Encontrar
	información sobre el programa formativo que brinda ReAcciona. 
	</p>
	</li><li><p style="margin-bottom: 0.28cm; line-height: 108%">Recibir
	mediante correo electrónico, boletines, noticias, fechas de eventos
	y otros anuncios desarrollados por ReAcciona.</p>
	</li><li><p style="margin-bottom: 0.28cm; line-height: 108%"><a name="_Hlk165023169"></a>
	Para un uso adecuado del Aplicativo, se requerirá la compatibilidad
	con todos los navegadores existentes y las versiones de sistemas
	operativos móvil Android y iOS</p>
</li></ul><ol start="2">
	<li><h1>Definiciones y términos clave</h1>
</li></ol><p style="margin-bottom: 0.28cm; line-height: 108%">Se describe a
continuación, las definiciones y términos claves que se utilizan en
el presente documento de Términos y Condiciones.</p><ul>
	<li><p style="margin-bottom: 0.28cm; line-height: 108%"><b>Aplicación
	Móvil</b>: Se refiere a la aplicación móvil denominada
	ReAccionaApp, desarrollada por Instituto APOYO, que permite a los
	usuarios acceder a información más clara con respecto a los
	proyectos de inversión pública.</p>
	</li><li><p style="margin-bottom: 0.28cm; line-height: 108%"><b>Página
	Web</b>: Se refiere al sitio web <b>reacciona.pe</b> que es de
	propiedad y viene siendo operado por Instituto APOYO. Proporciona
	información, servicios y recursos relacionados con información
	relevante sobre los proyectos de inversión pública.</p>
	</li><li><p style="margin-bottom: 0.28cm; line-height: 108%"><b>Usuario</b>:
	Se refiere a cualquier persona que accede, navega o utiliza la
	Aplicación Móvil o la Página Web, ya sea como usuario registrado
	o no registrado.</p>
	</li><li><p style="margin-bottom: 0.28cm; line-height: 108%"><b>Cuenta de
	Usuario</b>: Se refiere a la cuenta creada por un Usuario para
	acceder y utilizar las funciones y características de la Aplicación
	Móvil o la Página Web.</p>
	</li><li><p style="margin-bottom: 0.28cm; line-height: 108%"><b>Contenido
	del Usuario</b>: Se refiere a cualquier comentario o reacción que
	un Usuario cargue en el Aplicativo respecto a un proyecto de
	inversión pública.</p>
	</li><li><p style="margin-bottom: 0.28cm; line-height: 108%"><b>Servicios</b>:
	Se refiere a los servicios proporcionados a través de la Aplicación
	Móvil o la Página Web, los cuales se describen a continuación:</p>
	<ul>
		<li><p style="margin-bottom: 0.28cm; line-height: 108%">Tener el
		Aplicativo (herramientas) de tecnología gratuita.</p>
		</li><li><p style="margin-bottom: 0.28cm; line-height: 108%">Encontrar
		data pública del Ministerio de Economía y Finanzas presentada al
		Usuario de manera amigable.</p>
		</li><li><p style="margin-bottom: 0.28cm; line-height: 108%">Utilizar
		herramientas diseñadas para primar la experiencia del usuario.</p>
		</li><li><p style="margin-bottom: 0.28cm; line-height: 108%">Aprender
		las bases de la Administración Pública mediante videos
		microlearnig de forma gratuita.</p>
		</li><li><p style="margin-bottom: 0.28cm; line-height: 108%">Comentar
		sobre los proyectos de inversión pública que se encuentren en la
		aplicación móvil que bajo el criterio del Usuario tengan alguna
		observación.</p>
		</li><li><p style="margin-bottom: 0.28cm; line-height: 108%">Ver los
		proyectos de inversión pública de interés del Usuario</p>
		</li><li><p style="margin-bottom: 0.28cm; line-height: 108%">Recibir
		información de una cartera de proyectos de inversión con mayor
		importancia sobre la región.</p>
		</li><li><p style="margin-bottom: 0.28cm; line-height: 108%">Encontrar
		los proyectos de inversión pública más cercanos a su localidad,
		sin perjuicio de que puedan buscar los proyectos en localidades
		distintas a las que ellos se encuentran cerca.</p>
		</li><li><p style="margin-bottom: 0.28cm; line-height: 108%">Tener
		información sobre los eventos, foros, mesas técnicas u otras
		actividades que se desarrollen por el proyecto ReAcciona.</p>
		</li><li><p style="margin-bottom: 0.28cm; line-height: 108%">Encontrar
		noticias, artículos, ensayos, columnas de opinión y otros
		documentos académicos desarrollados por el proyecto ReAcciona.</p>
		</li><li><p style="margin-bottom: 0.28cm; line-height: 108%">Encontrar
		información sobre el programa formativo que brinda ReAcciona. 
		</p>
		</li><li><p style="margin-bottom: 0.28cm; line-height: 108%">Recibir
		mediante correo electrónico, boletines, noticias, fechas de
		eventos y otros anuncios desarrollados por ReAcciona.</p></li></ul></li></ul><ul>
	<li><p style="margin-bottom: 0.28cm; line-height: 108%"><b>Política
	de Privacidad</b>: Se refiere a la política de privacidad de
	Instituto APOYO, que describe cómo recopilamos, utilizamos y
	protegemos la información personal de los Usuarios.</p>
	</li><li><p style="margin-bottom: 0.28cm; line-height: 108%"><b>Términos
	y Condiciones</b>: Se refiere a este documento, que establece los
	términos y condiciones de uso de la Aplicación Móvil y la Página
	Web, así como los derechos y responsabilidades de los Usuarios e
	Instituto APOYO.</p>
</li></ul><ol start="3">
	<li><h1>Alcance de la licencia</h1>
</li></ol><p style="margin-bottom: 0.28cm; line-height: 108%"><b>Sobre su uso
permitido</b>: Al descargar, instalar y utilizar nuestra aplicación
móvil, se te concede una licencia limitada, no exclusiva,
intransferible y revocable para acceder y utilizar la aplicación
únicamente para fines personales y no comerciales, salvo cuente con
el consentimiento previo por escrito de Instituto APOYO, en el marco
del proyecto ReAcciona.</p><p style="margin-bottom: 0.28cm; line-height: 108%">de acuerdo con
estos Términos y que el Usuario pueda: 
</p><ul>
	<li><p style="margin-bottom: 0.28cm; line-height: 108%">Acceder y
	utilizar todas las características y funcionalidades que
	proporcionan el Aplicativo en su dispositivo personal en relación
	con los Servicios.</p>
	</li><li><p style="margin-bottom: 0.28cm; line-height: 108%">Acceder y
	hacer uso de cualquier contenido, información y material
	relacionado que pueda ponerse a disposición a través de los
	Servicios, en cada caso solo para su uso personal, no comercial.</p>
</li></ul><ol start="4">
	<li><h1>Cuentas de usuario y seguridad</h1>
</li></ol><ul>
	<li><p style="margin-bottom: 0.28cm; line-height: 108%"><b>Registro
	de Cuenta</b>: Para utilizar las funciones del Aplicativo, es
	requerido crear una cuenta de usuario. Deberás proporcionar
	información precisa, actualizada y completa durante el proceso de
	registro. Te comprometes a mantener la confidencialidad de tu
	información de inicio de sesión y a no compartir tus credenciales
	de acceso con terceros. Eres responsable de todas las actividades
	que ocurran bajo tu cuenta de usuario.</p>
	</li><li><p style="margin-bottom: 0.28cm; line-height: 108%"><b>Gestión
	de la Cuenta</b>: Puedes actualizar, modificar o eliminar tu cuenta
	de usuario en cualquier momento a través de la configuración de la
	cuenta disponible en el Aplicativo. Si sospechas que la seguridad de
	tu cuenta ha sido comprometida o si tienes motivos para creer que
	tus credenciales de acceso han sido robadas, debes notificarlo de
	inmediato a ReAcciona. A su vez, usted solo puede poseer una sola
	Cuenta de Usuario. Es tu responsabilidad mantener la seguridad de tu cuenta de usuario y
no compartir tus credenciales de acceso con terceros. No puedes
acceder a la cuenta de otro usuario sin su permiso expreso</p></li></ul><ul>
	<li><p style="margin-bottom: 0.28cm; line-height: 108%"><b>Requisitos
	de Seguridad para Contraseñas</b>: Tu contraseña debe cumplir con
	ciertos requisitos de seguridad, que pueden incluir una combinación
	de letras mayúsculas y minúsculas, números y caracteres
	especiales. Se te recomienda elegir una contraseña única y segura,
	y cambiarla periódicamente para garantizar la seguridad de tu
	cuenta.</p>
	</li><li><p style="margin-bottom: 0.28cm; line-height: 108%"><b>Recopilación
	de Información</b>: La información que recopilamos durante el
	proceso de registro y la gestión de la cuenta se utiliza para
	proporcionarte acceso<span lang="es-419"><b> </b></span><span lang="es-419">seguro
	y personalizado a</span> las funciones del Aplicativo<span lang="es-419">,
	y para mejorar continuamente nuestros servicios</span>. Podemos
	recopilar información personal como nombres, apellidos, correo
	electrónico y ubicación, de acuerdo con nuestra Política de
	Privacidad. Esta información se utiliza para personalizar tu
	experiencia de usuario y proporcionarte un servicio más relevante y
	eficaz. Nos comprometemos a proteger tu privacidad y seguridad de
	acuerdo con nuestra Política de Privacidad y las leyes de
	protección de datos aplicables.</p></li></ul><p style="margin-left: 1.27cm; margin-bottom: 0.28cm; line-height: 108%">
<b>Permisos de la aplicación:</b></p><ul>
	<li><p style="margin-bottom: 0.28cm; line-height: 108%"><b>Ubicación
	del dispositivo</b>: (En adelante “Ubicación”) Mediante la
	geolocalización del dispositivo móvil la Aplicación Móvil
	ReAcciona identificará las coordenadas de posicionamiento global
	del dispositivo móvil, con lo cual mostrará al Usuario los
	proyectos de inversión pública que se encuentren cercanos a su
	Ubicación.</p></li></ul><ul>
	<li><p style="margin-bottom: 0.28cm; line-height: 108%"><b>Ubicación
	en Segundo Plano</b>: La Aplicación Móvil ReAcciona recopilará
	los datos de ubicación en segundo plano, esto permitirá actualizar
	los proyectos de inversión pública que se encuentren en el rango
	de la Ubicación del dispositivo móvil. 
	</p>
	</li><li><p style="margin-bottom: 0.28cm; line-height: 108%"><b>Finalidad</b>:
	Proporcionar al Usuario la siguiente información respecto a su
	Ubicación para poder mostrarle los proyectos de inversión pública
	más cercanos a su Ubicación, asimismo, podrá ver la información
	de los proyectos de inversión pública mediante la opción de abrir
	el estado del proyecto.</p>
</li></ul><ol start="5">
	<li><h1>Seguridad de la Información</h1>
</li></ol><ul>
	<li><p style="margin-bottom: 0.28cm; line-height: 108%"><b>Protección
	de la Información del Usuario</b>: Nos comprometemos a implementar
	medidas de seguridad razonables y adecuadas para proteger la
	información personal y otros datos sensibles proporcionados por los
	usuarios contra accesos no autorizados, pérdida, alteración o
	divulgación. Utilizamos tecnologías de cifrado, firewalls y otros
	controles de seguridad para proteger la información del usuario
	durante la transmisión y almacenamiento.</p>
	</li><li><p style="margin-bottom: 0.28cm; line-height: 108%"><b>Acceso a
	la Información</b>: Solo el personal autorizado de Instituto APOYO,
	en el marco del proyecto ReAcciona, tiene acceso a la información
	del usuario en la medida necesaria para llevar a cabo sus funciones
	y proporcionar los servicios relacionados con el Aplicativo. Todos
	los empleados están sujetos a obligaciones de confidencialidad y
	deben cumplir con las políticas de seguridad de la información
	establecidas por Instituto APOYO.</p>
	</li><li><p style="margin-bottom: 0.28cm; line-height: 108%"><b>Seguridad
	de la Transmisión de Datos</b>: Utilizamos protocolos seguros de
	transmisión de datos para garantizar que la información del
	usuario se transmita de manera segura a través del Aplicativo. Sin
	embargo, ten en cuenta que ninguna transmisión de datos a través
	de Internet o cualquier red inalámbrica puede garantizarse como
	100% segura. Por lo tanto, no podemos garantizar la seguridad
	absoluta de la información del usuario.</p>
	</li><li><p style="margin-bottom: 0.28cm; line-height: 108%"><b>Responsabilidad
	del Usuario</b>: Los usuarios son responsables de mantener la
	seguridad de sus propios dispositivos y cuentas de usuario,
	incluyendo la elección de contraseñas seguras y la protección
	contra el acceso no autorizado.</p>
	</li><li><p style="margin-bottom: 0.28cm; line-height: 108%"><b>Limitación
	de Responsabilidad</b>: No seremos responsables de ningún acceso no
	autorizado, pérdida, alteración o divulgación de información del
	usuario que no sea causado directamente por nuestra negligencia o
	incumplimiento de estas disposiciones de seguridad.</p>
</li></ul><ol start="6">
	<li><h1>Propiedad Intelectual 
	</h1>
</li></ol><ul>
	<li><p style="margin-bottom: 0.28cm; line-height: 108%"><b>Derechos
	de Propiedad Intelectual</b>: Todo el contenido disponible en el
	Aplicativo, incluyendo, pero no limitado a textos, gráficos,
	logotipos, imágenes, videos, sonidos, música, software y cualquier
	otro material, está protegido por leyes de propiedad intelectual,
	incluyendo derechos de autor, marcas registradas, patentes y otros
	derechos de propiedad. Instituto APOYO y sus licenciantes poseen y
	retienen todos los derechos de propiedad intelectual sobre el
	contenido del Aplicativo.</p>
	</li><li><p style="margin-bottom: 0.28cm; line-height: 108%"><b>Licencia
	sobre el contenido Generado por los Usuarios</b>: Al proporcionar
	cualquier tipo de contenido a través del Aplicativo (comentarios),
	otorgas a Instituto APOYO, en el marco del proyecto ReAcciona, una
	licencia no exclusiva, gratuita, mundial y transferible para usar,
	reproducir, modificar, adaptar, publicar, traducir, distribuir,
	realizar y mostrar dicho contenido en relación con la operación y
	promoción del Aplicativo, en todos los medios que considere
	pertinente Instituto APOYO. Reconoces y aceptas que eres el único
	responsable del contenido que proporcionas y garantizas que tienes
	todos los derechos necesarios para otorgar la licencia mencionada
	anteriormente.</p>
	</li><li><p style="margin-bottom: 0.28cm; line-height: 108%"><b>Uso
	Permitido</b>: Se te otorga una licencia limitada y no exclusiva
	para acceder y utilizar el Aplicativo, y para visualizar y descargar
	el contenido disponible solo para tu uso personal y no comercial. No
	tienes derecho a reproducir, modificar, distribuir o utilizar de
	cualquier otra manera el contenido del Aplicativo sin el
	consentimiento previo por escrito de Instituto APOYO, en el marco
	del proyecto ReAcciona, o el titular de los derechos de terceros de
	propiedad intelectual correspondientes.</p>
	</li><li><p style="margin-bottom: 0.28cm; line-height: 108%"><b>Marcas
	Comerciales</b>: Todas las marcas comerciales, logotipos y nombres
	comerciales utilizados en el Aplicativo son propiedad de Instituto
	APOYO o de terceros y no pueden ser utilizados sin autorización
	previa por escrito.</p>
</li></ul><ol start="7">
	<li><h1>Contenido Generado por el Usuario</h1>
</li></ol><p style="margin-bottom: 0.28cm; line-height: 108%">Los usuarios son
los únicos responsables del contenido que generan, incluidos
comentarios y mensajes. Al publicar contenido en nuestro Aplicativo,
usted garantiza que tiene los derechos necesarios para hacerlo y que
dicho contenido no infringe los derechos de terceros. 
</p><p style="margin-bottom: 0.28cm; line-height: 108%">Usted se
compromete a actuar siempre y en todo momento de buena fe y de manera
razonable (así se encuentre un error manifiesto en el Aplicativo),
acuerda cumplir con todas las leyes aplicables y solo podrá utilizar
los Servicios con fines legítimos.</p><ol start="8">
	<li><h1>Comportamiento del Usuario</h1>
</li></ol><ul>
	<li><p style="margin-bottom: 0.28cm; line-height: 108%"><b>Normas de
	Conducta</b>: Al utilizar el Aplicativo, te comprometes a
	comportarte de manera ética y respetuosa hacia otros usuarios y
	terceros. El Usuario no puede usar el Aplicativo para realizar,
	promover o apoyar actividades ilegales, difamatorias, fraudulentas,
	abusivas, amenazantes, obscenas, pornográficas, discriminatorias, o
	que inciten al odio o la violencia. Está prohibido publicar,
	transmitir o compartir cualquier contenido que viole los derechos de
	propiedad intelectual, la privacidad, la publicidad o cualquier otro
	derecho de terceros.</p>
	</li><li><p style="margin-bottom: 0.28cm; line-height: 108%"><b>Contenido
	Inapropiado</b>: No puedes cargar, publicar, transmitir o mostrar en
	el Aplicativo ningún contenido que sea ilegal, difamatorio,
	discriminatorio, obsceno, pornográfico, violento, amenazante,
	discriminatorio o que incite al odio o que viole cualquier ley o
	regulación aplicable. 
	</p>
	</li><li><p style="margin-bottom: 0.28cm; line-height: 108%">Nos
	reservamos el derecho de eliminar cualquier contenido que
	consideremos inapropiado o que viole estas normas de conducta, sin
	previo aviso y a nuestra entera discreción.</p>
	</li><li><p style="margin-bottom: 0.28cm; line-height: 108%"><b>Reporte
	de Contenido</b>: Si encuentras contenido que crees que viola estas
	normas de conducta, te pedimos que lo reportes de inmediato a través
	de los mecanismos de reporte proporcionados en el Aplicativo. Nos
	comprometemos a revisar todos los informes de contenido inapropiado
	de manera oportuna y a tomar las medidas necesarias para abordar la
	situación de acuerdo con nuestras políticas y procedimientos
	internos.</p>
	</li><li><p style="margin-bottom: 0.28cm; line-height: 108%"><b>Consecuencias
	por Incumplimiento</b>: El incumplimiento de estas normas de
	conducta puede resultar en la eliminación de contenido, la
	suspensión o terminación de tu Cuenta de Usuario, y la prohibición
	de acceder a el Aplicativo en el futuro. Nos reservamos el derecho
	de tomar todas las medidas necesarias, incluidas acciones legales,
	contra aquellos Usuarios que violen estas normas de conducta.</p>
</li></ul><ol start="9">
	<li><h1>Restricciones de uso</h1>
</li></ol><ul>
	<li><p style="margin-bottom: 0.28cm; line-height: 108%"><b>Uso
	Legal</b>: El Aplicativo solo pueden ser utilizadas con fines
	legales. No se permite el uso del Aplicativo para actividades
	ilegales o que violen los derechos de terceros.</p>
	</li><li><p style="margin-bottom: 0.28cm; line-height: 108%"><b>Prohibición
	de Ingeniería Inversa</b>: No puedes realizar ingeniería inversa,
	descompilar, desensamblar ni intentar derivar el código fuente del
	Aplicativo, excepto en la medida en que dicha actividad esté
	expresamente permitida por la ley aplicable.</p>
	</li><li><p style="margin-bottom: 0.28cm; line-height: 108%"><b>No
	Interferencia</b>: No puedes interferir ni interrumpir el
	funcionamiento normal del Aplicativo, ni de los servidores o redes
	conectadas a ellas. Esto incluye, entre otros, el uso de
	dispositivos, software o rutinas que puedan interferir con la
	funcionalidad del Aplicativo, o la transmisión de virus u otro
	código malicioso.</p>
	</li><li><p style="margin-bottom: 0.28cm; line-height: 108%"><b>Cumplimiento
	de las Políticas</b>: Debes cumplir con todas las políticas,
	directrices y procedimientos establecidos por Instituto APOYO, en el
	marco del proyecto ReAcciona, en relación con el uso del Aplicativo</p>
	</li><li><p style="margin-bottom: 0.28cm; line-height: 108%"><b>Rescisión
	del Acceso</b>: Nos reservamos el derecho de rescindir tu acceso al
	Aplicativo si violas alguna de estas restricciones de uso.</p>
</li></ul><ol start="10">
	<li><h1>Modificaciones y actualizaciones</h1>
</li></ol><p style="margin-bottom: 0.28cm; line-height: 108%">ReAcciona se
reserva el derecho a modificar, actualizar o ampliar en cualquier
momento los presentes Términos, para lo cual deberá publicar
inmediatamente en el Aplicativo la nueva versión de los Términos,
siendo responsabilidad de los Usuarios revisar los Términos vigentes
al momento de la navegación.</p><p style="margin-bottom: 0.28cm; line-height: 108%">Si los Usuarios
no estuvieran de acuerdo con las modificaciones realizadas, podrán
optar por no hacer uso de los servicios ofrecidos por ReAcciona a
través del Aplicativo y de considerarlo, iniciar la comunicación de
baja de la suscripción.</p><ol start="11">
	<li><h1>Suspensión y terminación de cuentas</h1>
</li></ol><p style="margin-left: 0.64cm; margin-bottom: 0.28cm; line-height: 108%">
<b>Circunstancias para la Suspensión o Terminación</b>: Nos
reservamos el derecho de suspender o terminar tu cuenta de usuario en
cualquier momento y por cualquier motivo, incluyendo, entre otros:</p><ul>
	<li><p style="margin-bottom: 0.28cm; line-height: 108%">Violación
	de estos términos y condiciones o cualquier otra política o
	normativa aplicable.</p>
	</li><li><p style="margin-bottom: 0.28cm; line-height: 108%">Actividades
	que consideremos fraudulentas, ilegales, abusivas o que infrinjan
	los derechos de terceros.</p>
	</li><li><p style="margin-bottom: 0.28cm; line-height: 108%">Incumplimiento
	de las normas de conducta establecidas en relación con el uso del
	Aplicativo.</p>
	</li><li><p style="margin-bottom: 0.28cm; line-height: 108%">Solicitud
	del usuario para cerrar la cuenta.</p>
</li></ul><p style="margin-left: 0.75cm; margin-bottom: 0.28cm; line-height: 108%">
Nos reservamos el derecho de tomar estas medidas a nuestra sola
discreción y sin previo aviso.</p><ol start="12">
	<li><h1>Limitación de Responsabilidad</h1>
</li></ol><ul>
	<li><p style="margin-bottom: 0.28cm; line-height: 108%"><b>Recopilación
	de Información</b>: El Aplicativo recopilan información de fuentes
	de Datos Abiertos proporcionadas por entidades gubernamentales, como
	el Ministerio de Economía y Finanzas (MEF) y otras fuentes de
	información pública. No modificamos la información recopilada,
	solo mostramos la información tal como es brindada por las
	entidades gubernamentales. La información presentada a través del
	Aplicativo se actualiza mensualmente y es brindada al público en
	general. La veracidad de la información recae en la información
	proporcionada por el MEF y otras fuentes de información pública
	gubernamentales.</p>
	</li><li><p style="margin-bottom: 0.28cm; line-height: 108%"><b>Exención
	de Responsabilidad</b>: En ningún caso, Instituto APOYO será
	responsable por cualquier daño, pérdida o perjuicio, ya sea
	directo, indirecto, incidental, especial, consecuente o ejemplar,
	que surja del uso de la aplicación móvil o la página web. Nos
	reservamos el derecho de realizar cambios en la aplicación móvil y
	la página web, incluidas sus funcionalidades, contenido o
	disponibilidad, en cualquier momento y sin previo aviso.</p>
</li></ul><ol start="13">
	<li><h1>Asimismo, Instituto APOYO
	no garantiza que las funciones desempeñadas por el Aplicativo serán
	suministradas de manera ininterrumpida, oportuna, segura o libre de
	error, o que se corregirán los defectos en el Aplicativo. El
	Aplicativo, los servicios y el Contenido se proporcionan en el
	estado en que se encuentran y según estén o no disponibles.</h1>
	</li><li><h1>Jurisdicción y Ley Aplicable</h1>
</li></ol><p>

















































</p><ul>
	<li><p style="margin-bottom: 0.28cm; line-height: 108%">Los Términos
	descritos se rigen por la ley peruana.</p>
	</li><li><p style="margin-bottom: 0.28cm; line-height: 108%">Los Términos
	serán aplicados e interpretados de acuerdo con la legislación
	peruana, y cualquier disputa que surja en su aplicación se verá
	únicamente ante los tribunales con jurisdicción en Lima, Perú. No
	obstante, esto no impedirá a Instituto APOYO el derecho a resolver
	cualquier litigio en otra jurisdicción competente.</p>
</li></ul>
        </div>
    </div>
</template>

<script>
    export default {
        name:"terminos-condiciones"
    }
</script>

<style lang="scss" scoped>
@media only screen and (min-width: 601px) {
    .container{
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
          width: 100%;
          max-width: 1150px;
          margin: 140px auto 0px auto;
        .html-content{
         
            p{
                text-align: left!important;
            }
            b:first-of-type{
                text-align: center!important;
            }
            b{
                text-align: left!important;
            }
            h1{
                text-align: left;
                font-size: 18px;
            }
            li{
                margin-bottom: 20px;
            }
        }
    }
}
@media only screen and (max-width: 600px) {
    .container{
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
          width: 100%;
          max-width: 600px;
          margin: 100px auto 0px auto;
          .html-content{
         display: flex;
         justify-content: center;
         align-items: center;
         flex-direction: column;
         p{
             text-align: left!important;
             width: 95%;
         }
         b:first-of-type{
             text-align: center!important;
         }
         b{
             text-align: left!important;
             width: 95%;
         }
         h1{
             text-align: left;
             font-size: 18px;
         }
         li{
          
             width: 95%;
         }
     }
    }
}
</style>